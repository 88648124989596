import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect, useCallback } from "react";
import { useAlert } from "../../../../../contexts/alert-context";
import { SocialActivityDTO } from "../social-activities-DTO";
import {
  addNew,
  getSocialActivityById,
} from "../social-activities-service";

import UploadFileOrImage from "../../../../../components/UploadFileAndImagesComponent/UploadFileOrImagesComponent";

interface ModalProps {
  onClose: () => void;
  contactId: string;
}

const SocialActivityForm: React.FC<ModalProps> = ({
  onClose,
  contactId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [socialActivity, setSocialActivity] =
    useState<SocialActivityDTO | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SocialActivityDTO>();

  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);

  const [removedFiles, setRemovedFiles] = useState<string[]>([]);

  const handleImageChange = useCallback(
    (files: File[], removedFiles: string[]) => {
      setSelectedImages(files);
      if (removedFiles.length > 0) {
        setRemovedFiles(removedFiles);
      }
    },
    []
  );

    
  useEffect(() => {
    if (contactId) {
      getSocialActivityById(contactId).then((data) => {
        if (data) {
          setSocialActivity(data);
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof SocialActivityDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof SocialActivityDTO, value as any);
            }
          });
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async (contact: SocialActivityDTO) => {
    try {
      if (id) {
        await addNew(contact, id, selectedImages,removedFiles);
        setAlert({ text: t("alert-successfully-registered"), type: "success" });
        onClose();
      }
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
    }
  };

  return (
    <div className="modal-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields flex-column">
          <h2>{t("label-social-activity")}</h2>

          <div className="flex-column">
            <label htmlFor="technology">{`${t("label-social-activity")}*`}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="technology"
              {...register("socialActivityInfo.socialActivityTitle", {
                required: true,
              })}
            />
            {errors.socialActivityInfo?.socialActivityTitle && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="description">{`${t("label-description")}*`}</label>
            <textarea
              placeholder={t("placeholder.generalEmpty")}
              id="description"
              {...register("socialActivityInfo.socialActivity", {
                required: true,
              })}
            />
            {errors.socialActivityInfo?.socialActivity && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

          <div className="form-fields flex-column">
            <div className="flex-column">
              <label htmlFor="observations">
                {t("label-general-observations")}
              </label>
              <textarea
                placeholder={t("placeholder.observations")}
                id="observations"
                {...register("observations.observations")}
              />
            </div>
          </div>

          <br />

          <div className="flex-column">
            
            <UploadFileOrImage
              onlyImages={false}
              onSave={(itens, removedFiles) =>
                handleImageChange(itens, removedFiles)
              }
              initialSavedFiles={socialActivity?.socialActivityInfo?.files || []}
              
            />
           
          </div>



          <hr></hr>

          <div className="flex-row buttons-section">
            <button className="neutral-white" onClick={onClose} type="button">
              {t("label-back")}
            </button>
            <button className="blue" type="submit">
              {t("label-save")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SocialActivityForm;
