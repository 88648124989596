import React, { useState, useEffect } from "react";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import { t } from "i18next";
import { extractFileNameFromUrl } from "../../../services/string-treatment";

interface UploadModalProps {
  onlyImages: boolean;
  initialFiles: File[];
  savedFiles: string[];
  onClose: () => void;
  onSave: (files: File[], removedSavedFiles: string[]) => void;
  fieldLabel?: string;
}

const UploadModal: React.FC<UploadModalProps> = ({
  onlyImages,
  initialFiles,
  savedFiles,
  onClose,
  onSave,
  fieldLabel,
}) => {
  const [tempSelectedFiles, setTempSelectedFiles] = useState<File[]>([]);
  const [localSavedFiles, setLocalSavedFiles] = useState<string[]>([]);
  const [removedSavedFiles, setRemovedSavedFiles] = useState<string[]>([]);
  useEffect(() => {
    setTempSelectedFiles(initialFiles);
    setLocalSavedFiles(savedFiles);
  }, [initialFiles, savedFiles]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      setTempSelectedFiles((prev) => [...prev, ...fileList]);
    }
  };

  const handleRemoveTempFile = (index: number) => {
    setTempSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRemoveSavedFile = (index: number) => {
    const removedFile = localSavedFiles[index];
    setLocalSavedFiles((prev) => prev.filter((_, i) => i !== index));
    setRemovedSavedFiles((prev) => [...prev, removedFile]);
  };

  const handleSave = () => {
    onSave(tempSelectedFiles, removedSavedFiles);
  };

  return (
    <div className="modal-overlay">
      <div className="big-modal-content">
        <h3>{fieldLabel ? fieldLabel : t("label-attachment")}</h3>
        <input
          style={{ width: "97.5%", color: "transparent" }}
          type="file"
          id="file-input"
          accept={onlyImages ? "image/*" : undefined}
          multiple
          onChange={handleFileChange}
        />

        <div style={{ marginTop: "10px" }}>
          {onlyImages ? (
            <div className="image-preview flex-row">
              {localSavedFiles.map((file, index) => (
                <div
                  key={`saved-${index}`}
                  style={{
                    marginBottom: "10px",
                    backgroundColor: "var(--box-shadow-color)",
                  }}
                >
                  <img
                    src={file}
                    alt={`saved-preview-${index}`}
                    className="image"
                  />
                  <SparkIcon
                    noPadding
                    icName="delete"
                    className="remove-image"
                    onClick={() => handleRemoveSavedFile(index)}
                  ></SparkIcon>
                </div>
              ))}

              {tempSelectedFiles.map((file, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "10px",
                    backgroundColor: "var(--box-shadow-color)",
                  }}
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`preview-${index}`}
                    className="image"
                  />
                  <SparkIcon
                    noPadding
                    icName="delete"
                    className="remove-image"
                    onClick={() => handleRemoveTempFile(index)}
                  ></SparkIcon>
                </div>
              ))}
            </div>
          ) : (
            <ul className="flex-column">
              {localSavedFiles.map((file, index) => (
                <li
                  className="flex-row align-center justify-between"
                  style={{
                    padding: 10,
                    backgroundColor: "transparent",
                    cursor: "default",
                    color: "var(--black-color)",
                  }}
                  key={`saved-${index}`}
                >
                  <p style={{ margin: "10px" }}>
                    {extractFileNameFromUrl(file)}
                  </p>
                  <div className="flex-row" style={{ gap: 10 }}>
                    <SparkIcon
                      noPadding
                      icName="delete"
                      className="button-list"
                      onClick={() => handleRemoveSavedFile(index)}
                    ></SparkIcon>
                  </div>
                </li>
              ))}

              {tempSelectedFiles.map((file, index) => (
                <li
                  className="flex-row align-center justify-between"
                  style={{
                    padding: 10,
                    backgroundColor: "transparent",
                    cursor: "default",
                    color: "var(--black-color)",
                  }}
                  key={index}
                >
                  <p style={{ margin: "10px" }}>{file.name}</p>
                  <div className="flex-row" style={{ gap: 10 }}>
                    <SparkIcon
                      noPadding
                      icName="delete"
                      className="button-list"
                      onClick={() => handleRemoveTempFile(index)}
                    ></SparkIcon>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>

        <div
          className="flex-row justify-end margin-topbottom-1"
          style={{ gap: 10 }}
        >
          <button type="button" style={{ margin: 0 }} onClick={onClose}>
            {t("label-back")}
          </button>
          <button
            type="button"
            className="blue"
            style={{ margin: 0 }}
            onClick={handleSave}
          >
            {t("label-save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadModal;
