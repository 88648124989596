import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import addIcon from "../../../assets/svgs/add.svg";
import list from "../../../assets/img/list-view.svg";
import card from "../../../assets/img/components.svg";
import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { auth, firestore } from "../../../utils/firebase-config";
import ImportExportCsv from "../../../components/ImportExportCsv/ImportExportCsv";
import EcommerceCard from "../../../components/EcommerceCard/EcommerceCard";
import Slider from "../../../components/Slider/Slider";
import NoData from "../../../components/NoData/NoData";
import { useAuth } from "../../../contexts/auth-context";
import FilterComponent from "../../../components/FIlter/filter";
import { ScrapInfoDTO } from "../scrap-admin-generator-view/components/scrap-form/models/scrap-DTO";
import CTAInfoComponent from "../../../components/CTAInfoComponent/CTAInfoComponent";
import GenericConfirmationModal from "../../../components/GenericConfirmationModal/GenericConfirmationModal";
import Tooltip from '../../../components/tooltip/tooltip';
import { profileENUM } from "../../../assets/enums/profiles-enum";
import { colorsCardStatus } from "../../../assets/enums/colors-card-status";
const ScrapGeneratorScraps: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const originId = userDoc?.originId;
  const [slideData, setSliderData] = useState<string>("");
  const { id, alertMessage } = useParams<{
    id: string;
    alertMessage: string;
  }>();
  let timer: NodeJS.Timeout;
  const data = { generatorId: id };
  const [infoModal, setInfoModal] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any>([]);
  const tableHeader = [
    `${t("label-material")}`,
    `${t("label-quantity")}`,
    `${t("label-scrap-dealer")}`,
    `${t("label-value")}`,
    `${t("label-status")}`,
  ];

  const colors: { [key: string]: string } = {
    TO_DEAL: "#9e2896",
    AWAITING_BID: "#9e2896",
    IN_BID: "#007bc0",
    BID_COMPLETED: "#00884a",
    CANCELED: "#ed0007",
    FINISHED: "#cd8d00",
    CONTRACTED: "#00884a",
  };

  const handleFilterClick = (filteredData: ScrapInfoDTO[]) => {
    setFilteredData(filteredData);
  };

  const sortByRegion = (data: ScrapInfoDTO[]) => {
    return [...data].sort((a, b) => {
      const regionA = a.scrapCategory.name?.toString().toLowerCase() || "";
      const regionB = b.scrapCategory.name?.toString().toLowerCase() || "";
      return regionA.localeCompare(regionB);
    });
  };

  const sortByLegalName = (data: ScrapInfoDTO[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.contractDealerName?.toString().toLowerCase() || "";
      const nameB = b.contractDealerName?.toString().toLowerCase() || "";
      return nameA.localeCompare(nameB);
    });
  };

  const sortOptions = [
    { label: t("label-material"), sortFunction: sortByRegion },
    { label: t("label-scrap-dealer"), sortFunction: sortByLegalName },
  ];

  const handleSortClick = (
    sortFunction: (data: ScrapInfoDTO[]) => ScrapInfoDTO[]
  ) => {
    const sortedData = sortFunction([...filteredData]);
    setFilteredData(sortedData);
  };

  const fetchTableData = async () => {
    if (!originId) {
      console.error("originId is undefined");
      return;
    }

    const q = query(
      collection(firestore, "ScrapByGenerator"),
      where("originId", "==", originId)
    );

    const querySnapshot = await getDocs(q);

    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      searchData: doc
        .data()
      ["scrapCategory"]["name"].concat(
        " ",
        doc.data()["scrapCategory"]["description"],
        " ",
        doc.data()["specialDescription"],
        " ",
        doc.data()["contractDealerName"],
        " ",
        doc.data()["operationAddress"]["city"],
        " ",
        doc.data()["operationAddress"]["state"]
      ),
    })) as unknown as ScrapInfoDTO[];
    setTableData(items);
    setFilteredData(items);
  };

  useEffect(() => {
    fetchTableData();
  }, [id, originId]);

  useEffect(() => {
    if (alertMessage) {
      setInfoModal(true);
    }
  }, [alertMessage]);

  const handleCloseModal = () => {
    fetchTableData();
  };
  const cellStyle = {
    padding: "5px",

    maxWidth: "180px",

    whiteSpace: "normal",
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div style={{ marginBottom: "120px" }}>
        {infoModal && (
          <div className="modal-background">
            <GenericConfirmationModal
              onClose={() => {
                setInfoModal(false);
                navigate("/generator-scraps/");
              }}
              onConfirm={() =>
                navigate(`/generator-scraps/${originId}/generator-scraps-form`)
              }
              title={t("label-searching-best-offer")}
              cancelButtonLabel={t(
                "notice-modal-generator.notice-button-confirm"
              )}
              confirmButtonLabel={t("notice-modal-generator.notice-button-new")}
            >
              <p style={{ fontSize: "18px", lineHeight: "1.3" }}>
                <Trans
                  i18nKey={t("notice-modal-generator.notice-message")}
                  components={{ bold: <strong /> }}
                />

              </p>
            </GenericConfirmationModal>
          </div>
        )}
        <div style={{ marginBottom: 20 }}>
          <CTAInfoComponent>
            <div className="flex-row align-center" style={{ gap: 10 }}>
              <SparkIcon noPadding icName="info-i" pallete="primary" />
              <p>{t("cta-info.generator-scrap-page")}</p>
            </div>
          </CTAInfoComponent>
        </div>
        <div className="flex-row" style={{ justifyContent: "space-between" }}>
          <div className="flex-row">
            <button
              className="blue-with-icon button"
              onClick={() =>
                navigate(`/generator-scraps/${originId}/generator-scraps-form`)
              }
            >
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
            {profile === profileENUM.ADMIN_BOSCH && (

              <ImportExportCsv
                exportApi="exportscrapbygeneratorscsv"
                importApi="importscrapbygeneratorscsv"
                data={data}
                onClose={handleCloseModal}
              />
            )}

          </div>
          <FilterComponent
            data={tableData}
            onFilterClick={handleFilterClick}
            sortOptions={sortOptions}
          />

          <Slider
            options={[
              { display: list, value: "list", viewType: "image" },
              { display: card, value: "card", viewType: "image" },
            ]}
            setState={setSliderData}
          ></Slider>
        </div>

        <br />
        <br />
        {tableData.length === 0 && <NoData />}

        {tableData.length > 0 &&
          (slideData === "list" ? (
            <>
              <div className="info-section">
                <SparkTable>
                  <SparkTableHead>
                    <SparkTableRow>
                      <SparkTableCell className="icon-column"></SparkTableCell>
                      {tableHeader.map((header, index) => (
                        <SparkTableCell key={index}>
                          <strong>{header.toUpperCase()}</strong>
                        </SparkTableCell>
                      ))}
                    </SparkTableRow>
                  </SparkTableHead>

                  <SparkTableBody>
                    {filteredData.map((rowItem, rowIndex) => (
                      <SparkTableRow
                        key={rowIndex}
                        onClick={() =>
                          navigate(
                            `/generator-scraps/${originId}/generator-scraps-form/${rowItem.id}`
                          )
                        }
                      >
                        <SparkTableCell style={{ ...cellStyle, }}>
                          <Tooltip
                            text={
                              rowItem.scrapCategory.isHazardousMaterial
                                ? t("label-hazardous-material")
                                : t("label-non-hazardous-material")
                            }
                          >
                            <div>
                              {rowItem.scrapCategory.isHazardousMaterial && (
                                <div >

                                  <SparkIcon

                                    className="icon-flag"
                                    no-padding={true}
                                    icName={"danger-hazards"}
                                  />
                                </div>
                              )}
                              <p style={{ marginLeft: "25px" }}>
                                {rowItem.scrapCategory.name}
                              </p>
                            </div>
                          </Tooltip>
                        </SparkTableCell>
                        <SparkTableCell>
                          {rowItem.quantity} {t(`unityENUM.${rowItem.unity}`)}/
                          {t(`periodENUM.${rowItem.period}`)}
                        </SparkTableCell>
                        <SparkTableCell>
                          {rowItem.contractDealerName}
                        </SparkTableCell>
                        <SparkTableCell>
                          {(rowItem.status == "CONTRACTED" || rowItem.status == "OPERATING") ? rowItem.contractValue : rowItem.targetPrice}/
                          {(rowItem.targetPrice != "" || rowItem.contractValue)
                            ? t(`unityENUM.${rowItem.unity}`)
                            : ""}
                        </SparkTableCell>
                        <SparkTableCell
                          style={{ color: "var(--bosch-accent-blue)" }}
                        >
                          {t(`statusEnum.${rowItem.status}`)}
                        </SparkTableCell>
                      </SparkTableRow>
                    ))}
                  </SparkTableBody>
                </SparkTable>
              </div>

            </>
          ) : (
            <div className="eccomerce-card-wrapper">
              {filteredData.map((rowItem: any, rowIndex: any) => (
                <EcommerceCard
                onClick={() =>
                  navigate(
                    `/generator-scraps/${originId}/generator-scraps-form/${rowItem.id}`
                  )
                }
                buttonAction={() => { }}
                buttontext="Visualizar"
                imageUrl={
                  rowItem.images?.length > 0
                    ? rowItem.images
                    : rowItem.scrapCategory.image || "Image not found!"
                }
                price={
                  (rowItem.status === "CONTRACTED" || rowItem.status === "OPERATING")
                    ? rowItem.contractValue
                    : rowItem.targetPrice ?? ""
                }
                unitPrice={
                  rowItem.targetPrice ? t(`unityENUM.${rowItem.unity}`) : ""
                }
                key={rowIndex}
                customBottom={
                  <div className="flex-row align-centercard">
                    <Tooltip
                      text={
                        rowItem.scrapCategory.isHazardousMaterial
                          ? t("label-hazardous-material")
                          : t("label-non-hazardous-material")
                      }
                    >
                      <SparkIcon
                        no-padding={true}
                        icName={
                          rowItem.scrapCategory.isHazardousMaterial
                            ? "danger-hazards"
                            : "less-minimize"
                        }
                      />
                    </Tooltip>
                    <span style={{ marginLeft: 15,  whiteSpace: "nowrap",  }}>
                      {rowItem.scrapCategory.isHazardousMaterial
                        ? t("label-hazardous-material")
                        : t("label-non-hazardous-material")}
                    </span>
                    <div
                      style={{
                        backgroundColor: colorsCardStatus[rowItem.status],
                        marginLeft: 20,
                        height: '100%',
                        padding: "0px 10px",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {t(`statusEnum.${rowItem.status}`)}
                    </div>
                  </div>
                }
              >
                <div>
                  <h3>{rowItem.scrapCategory.name}</h3>
                  <p style={{ marginTop: 7 }}>
                    {rowItem.quantity} {t(`unityENUM.${rowItem.unity}`)}/
                    {t(`periodENUM.${rowItem.period}`)}
                  </p>
                  <p style={{ marginTop: 7 }}>
                    {t(`withdrawal`)} {t(`periodENUM.${rowItem.pickUpFrequency}`)}
                  </p>
                  <p style={{ marginTop: 7 }}>
                    {rowItem.operationAddress.city} - {rowItem.operationAddress.countryName}
                  </p>
                  <strong>
                    <p style={{ marginTop: 7 }}>{rowItem.contractDealerName}</p>
                  </strong>
                </div>
              </EcommerceCard>
              ))}
            </div>
          ))}
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapGeneratorScraps;
