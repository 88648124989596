import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../../../contexts/alert-context";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import ScrapCategoryInputAutocomplete from "../../../../../components/ScrapCategoryInputAutocomplete/ScrapCategoryInputAutocomplete";
import { useState, useEffect } from "react";

import { unityENUM } from "../../../../../assets/enums/unit-enum";
import { periodENUM } from "../../../../../assets/enums/period-enum";
import {
  ContainersTypeDTO,
  getContainersByCountryName,
} from "../../../../../services/containers-service";
import {
  getAdrressByEntityId,
  getCountryConfigById,
} from "../../../../../services/address-service";
import genericImage from "./../../../../../assets/svgs/material-list.svg";
import { CurrencyInput } from "react-currency-mask";
import CollapsibleSection from "../../../../../components/ColapseSection/CollapsibleSection";
import Expiration from "../../../../../components/Expiration/Expiration";
import { ExpirationDTO } from "../../../../../components/Expiration/models/ExpirationDTO";
import AddressSectionFields from "../../../../../components/AddressSectionFields/AddressSectionFields";
import { StatusEnum } from "../../../../../assets/enums/status-enum";
import uploadIcon from "../../../../../assets/svgs/upload.svg";
import { DealersInvitedTableDTO } from "./models/dealers-invited-DTO";
import UploadCsvModal from "../../../../../components/ImportExportCsv/UploadCsvModal/UploadCsvModal";
import { useAuth } from "../../../../../contexts/auth-context";
import { applyDecimalMask } from "../../../../../utils/formatting";
import { getScrapGeneratorInfo } from "../../../../scrap-generators/scrap-generator-service";
import { ScrapGeneratorInfoDTO } from "../../../../scrap-generators/scrap-generators-DTO";
import ScrapDealersInvitedModalForm from "../../../scrap-admin-generator-view/components/scrap-form/components/scrap-dealers-invited-modal";
import {
  addNewScrapAdmin,
  cancelScrapAdmin,
  fetchPartnerRequirementsTotalByCountry,
  getPrivateBidList,
  getScrapAdminById,
} from "../../../scrap-admin-service";
import GenericConfirmationModal from "../../../../../components/GenericConfirmationModal/GenericConfirmationModal";
import {
  AddressInfoDTO,
  DealersInvitedDTO,
  ScrapDTO,
} from "../../../scrap-admin-generator-view/components/scrap-form/models/scrap-DTO";
import { getScrapAdminInvitedDelaersTable } from "../../../scrap-admin-service";
import ApproveModal from "../approve-modal/approve-modal";
import { getAllApprovers } from "../../../../scrap-generators/components/scrap-generator-details/components/scrap-generator-approve-roll/scrap-generator-approve-roll-service";
import { reciveDaysAndtransformInYearsOrMonths } from "../../../../../services/date-format";
import { ScrapsCategoryInfoDTO } from "../../../scrap-category/models/scrap-category-DTO";
import { PrivateBidDTO } from "../../../scrap-admin-generator-view/components/scrap-form/models/private-bid-DTO";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import {
  callFunction,
  updateOnlyFieldOfForm,
  uploadCsvFile,
} from "../../../../../services/persistence";
import { COLLECTIONS } from "../../../../../assets/enums/firebase-colections";
import ScrapDealerInputAutocomplete from "../../../../../components/ScrapDealersIInputAutocomplete/ScrapDealersIInputAutocomplete";
import { getScrapDealerById } from "../../../../scrap-dealers/scrap-dealers-service";
import CTAInfoComponent from "../../../../../components/CTAInfoComponent/CTAInfoComponent";
import MTRTreatmentInputAutocomplete from "../../../../../components/treatmentMTRCodeAutocomplete/treatmentMTRCodeAutocomplete";
import ScrapStorageInputAutocomplete from "../../../../../components/ScrapStorageInputAutocomplete/ScrapStorageInputAutocomplete";
import { CountryConfigDTO } from "../../../../settings/components/Country-config/country-config-DTO";

const ScrapGeneratorScrapsForm: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { id, scrapId } = useParams<{ id: string; scrapId: string }>();
  const { setAlert } = useAlert();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc, currentUser } = useAuth();
  const profile = userDoc?.profile;
  let data = { originId: scrapId };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
    trigger,
    watch,
    control,
  } = useForm<ScrapDTO>();
  const [currentPeriod, setCurrentPeriod] = useState("");
  const [containers, setContainers] = useState<ContainersTypeDTO[]>([]);
  const [countryConfigEntity, setCountryConfigEntity] =
    useState<CountryConfigDTO>();
  const [selectedCategoryDetails, setSelectedCategoryDetails] =
    useState<ScrapsCategoryInfoDTO | null>(null);
  const [scrapAdmin, setScrapAdmin] = useState<ScrapDTO | null>(null);
  const [dealersInvited, setDealersInvited] = useState<any[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previews, setPreviews] = useState<string[]>([]);
  const [privateBidList, setPrivateBidList] = useState<PrivateBidDTO[]>([]);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedContainerType, setSelectedContainerType] = useState<any>("");
  const [contractValue, setContractValue] = useState<string>("");
  const [targetPrice, setTargetPrice] = useState<string>("");
  const [bidValue, setBidValue] = useState<string>("");
  const [currentDate, setCurrentDate] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState("TO_DEAL");
  const [showContract, setShowContract] = useState(false);
  const [selectedUnity, setSelectedUnity] = useState("");
  let timer: NodeJS.Timeout;
  const [generator, setGenerator] = useState<ScrapGeneratorInfoDTO | null>(
    null
  );
  const [tableData, setTableData] = useState<DealersInvitedTableDTO[]>([]);
  const [editTableModalOpen, setEditTableModalOpen] = useState(false);
  const [editTableItem, setEditTableItem] =
    useState<DealersInvitedTableDTO | null>();
  const [invitedDealersCompleteData, setInvitedDealersCompleteData] =
    useState<DealersInvitedDTO>();
  const [approveModal, setApproveModal] = useState(false);
  const [maxApproveValue, setMaxApproveValue] = useState(0);
  const originId = userDoc?.originId;
  const [infoModal, setInfoModal] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");

  const privateBidTableHeader = [
    `${t("label-name")}`,
    `${t("label-email")}`,
    `${t("label-phone")}`,
  ];

  const handleOpenTableModal = (rowItem: DealersInvitedTableDTO) => {
    setEditTableItem(rowItem);
    setEditTableModalOpen(true);
  };

  const handleCloseTableModal = async (
    updatedData?: DealersInvitedTableDTO
  ) => {
    if (updatedData) {
      await setTableData((prevDealers) =>
        prevDealers.map((dealer) =>
          dealer.id === updatedData.id ? updatedData : dealer
        )
      );
    }
    setEditTableItem(null);
    setEditTableModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (scrapAdmin?.id) {
        const response = await getScrapAdminInvitedDelaersTable(scrapAdmin?.id);
        setInvitedDealersCompleteData(response);
      }
    };
    fetchData();

    const fetchApprovers = async () => {
      if (userDoc?.originId) {
        const data = await getAllApprovers(userDoc.originId);
        if (data && currentUser) {
          setMaxApproveValue(
            data.filter((obj) => obj.user.id === currentUser.uid)[0]
              ?.approveValue
          );
        }
      }
    };

    fetchApprovers();
  }, [scrapAdmin, currentUser]);

  useEffect(() => {
    if (tableData) {
      setValue("dealersInvitedTable.dealersInvitedList", tableData);
    }
  }, [tableData]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    const delay = 1500;
    timer = setTimeout(() => {
      fetchDealersPrivateBidList();

      const additionalDelay = 2000;
      setTimeout(() => {
        fetchDealersPrivateBidList();
      }, additionalDelay);
    }, delay);
  };

  const getMinDate = () => {
    if (!scrapId) {
      const today = new Date();
      today.setDate(today.getDate() + 2);
      return today.toISOString().split("T")[0];
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []) as File[];

    const validImageFiles = files.filter((file) => {
      if (!file.type.startsWith("image/")) {
        setAlert({ text: t("label-material-invalid-pic"), type: "error" });
        return false;
      }
      return true;
    });

    setSelectedImages(validImageFiles);

    const previewsArray = validImageFiles.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise<string>((resolve) => {
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            resolve(reader.result);
          }
        };
      });
    });

    Promise.all(previewsArray).then((previews) => {
      setPreviews(previews);
    });
  };

  const handleUnityChange = (event: any) => {
    const { value } = event.target;
    setSelectedUnity(t(`unityENUM.${value}`));
    setValue("scrapInfo.unity", value);
  };

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const cancelScrapForm = () => {
    cancelScrapAdmin(scrapAdmin).then(() => {
      setConfirmationModalOpen(false);
      setSelectedStatus(StatusEnum.CANCELED);
      setValue("scrapInfo.status", StatusEnum.CANCELED);
      setAlert({
        text: `${t("alert-canceled-success")}`,
        type: "success",
      });
      const delay = 1000;
      timer = setTimeout(() => {
        navigate(`/generator-scraps/`);
      }, delay);
    });
  };

  useEffect(() => {
    if (scrapId) {
      getScrapAdminById(scrapId).then((data) => {
        if (data) {
          if (data.scrapInfo) {
            setValue("scrapInfo.tempDealer", data.scrapInfo.tempDealer || "");
            setOperationTemporaryStorage(
              data.scrapInfo.operationTempStorageContractedCompany
            );
            setValue(
              "scrapInfo.operationTempStorageContractedCompany",
              data.scrapInfo?.operationTempStorageContractedCompany
            );
            setValue("scrapInfo.carrier", data.scrapInfo.carrier || "");
            setOperationCarrier(
              data.scrapInfo.operationCarrierContractedCompany
            );
            setValue(
              "scrapInfo.operationCarrierContractedCompany",
              data.scrapInfo?.operationCarrierContractedCompany
            );
            setValue(
              "scrapInfo.finalDestination",
              data.scrapInfo.finalDestination || ""
            );
            setOperationRecipient(
              data.scrapInfo?.operationRecipientContractedCompany
            );
            setValue(
              "scrapInfo.operationRecipientContractedCompany",
              data.scrapInfo?.operationRecipientContractedCompany
            );
            setValue(
              "scrapInfo.operationDate",
              data.scrapInfo.operationDate || ""
            );
            setValue("scrapInfo.status", data.scrapInfo.status || "");
            setStatus(data.scrapInfo.status);
            setValue(
              "scrapInfo.contractRecurrent",
              data.scrapInfo.contractRecurrent || true
            );
            setValue(
              "scrapInfo.contractPeriodDays",
              data.scrapInfo.contractPeriodDays || 0
            );
            setValue(
              "scrapInfo.contractPeriodDaysType",
              data.scrapInfo.contractPeriodDaysType
            );
            setUnit(data.scrapInfo.contractPeriodDaysType);

            if (data.scrapInfo.contractPeriodDays % 365 !== 0) {
              setValueRecurrent(
                parseFloat(
                  (data.scrapInfo.contractPeriodDays / 30).toFixed(2)
                ) || 0
              );
            } else {
              setValueRecurrent(
                parseFloat(
                  (data.scrapInfo.contractPeriodDays / 365).toFixed(2)
                ) || 0
              );
              setUnit(365);
            }
            setValue(
              "scrapInfo.scrapCategory",
              data.scrapInfo.scrapCategory || ""
            );
            handleCategoryChange(data.scrapInfo.scrapCategory);
            setValue("scrapInfo.codeNCM", data.scrapInfo.codeNCM || "");
            setValue(
              "scrapInfo.observationsStorage",
              data.scrapInfo.observationsStorage || ""
            );

            handleCategoryChange(data.scrapInfo.scrapCategory);
            setValue(
              "scrapInfo.specialDescription",
              data.scrapInfo.specialDescription || ""
            );
            setValue("scrapInfo.quantity", data.scrapInfo.quantity || "");
            setQuantityMaskedValue(data.scrapInfo.quantity || "");
            setValue("scrapInfo.unity", data.scrapInfo.unity || "");
            setSelectedUnity(t(`unityENUM.${data.scrapInfo.unity}`));
            setValue("scrapInfo.period", data.scrapInfo.period || "");
            setCurrentPeriod(data.scrapInfo.period);
            setValue(
              "scrapInfo.pickUpFrequency",
              data.scrapInfo.pickUpFrequency || ""
            );
            setValue(
              "scrapInfo.responsibleShipping",
              data.scrapInfo.responsibleShipping || ""
            );
            setValue(
              "scrapInfo.containerNeeded",
              data.scrapInfo.containerNeeded
            );
            setContainerNeeded(data.scrapInfo.containerNeeded);

            setValue(
              "scrapInfo.containerType",
              data.scrapInfo.containerType || ""
            );
            setSelectedContainerType(data.scrapInfo.containerType || "");

            setValue(
              "scrapInfo.saleableWaste",
              data.scrapInfo.saleableWaste ?? true
            );
            setSaleableWaste(data.scrapInfo.saleableWaste);
            setValue(
              "scrapInfo.containerType",
              data.scrapInfo.containerType || ""
            );
            setValue(
              "scrapInfo.containerSize",
              data.scrapInfo.containerSize || ""
            );
            setValue(
              "scrapInfo.containerQuantity",
              data.scrapInfo.containerQuantity || ""
            );
            setContainerQuantityMaskedValue(
              data.scrapInfo.containerQuantity || ""
            );
            setValue(
              "scrapInfo.operationAddress",
              data.scrapInfo.operationAddress || ""
            );
            setAddress(data.scrapInfo.operationAddress);
            setSelectedContainerType(data.scrapInfo.containerType);
            setValue("scrapInfo.containerType", data.scrapInfo.containerType);
            setPreviews(data.scrapInfo.images);

            needsToShowContract(data);
          }

          if (data.dealersInvitedTable) {
            setTableData(data.dealersInvitedTable.dealersInvitedList);
            setValue("dealersInvitedTable.dealersInvitedList", tableData);
          }

          if (data.scrapNegotiation) {
            setValue(
              "scrapNegotiation.targetPrice",
              data.scrapNegotiation.targetPrice || ""
            );
            setValue(
              "scrapNegotiation.salesDate",
              data.scrapNegotiation.salesDate || ""
            );
            setValue(
              "scrapNegotiation.pickUpFrequency",
              data.scrapNegotiation.pickUpFrequency || ""
            );
            setValue(
              "scrapNegotiation.bidNegotiationType",
              data.scrapNegotiation.bidNegotiationType
            );
          }

          if (data.scrapBid) {
            setValue("scrapBid.bidType", data.scrapBid.bidType || "");
            setValue("scrapBid.bidDate", data.scrapBid.bidDate || "");
            setValue("scrapBid.bidValue", data.scrapBid.bidValue || "");
            setValue(
              "scrapBid.dealersInvited",
              data.scrapBid.dealersInvited || ""
            );
            setDealersInvited(data.scrapBid.dealersInvited || "");
            setValue(
              "scrapBid.requirimentsApprovalBid",
              data.scrapBid.requirimentsApprovalBid || ""
            );
          }

          if (data.scrapContract) {
            setValue(
              "scrapInfo.contractDealer",
              data.scrapInfo.contractDealer || ""
            );
            setValue(
              "scrapContract.contractDate",
              data.scrapContract.contractDate?.split("T")?.[0] || ""
            );
            setValue(
              "scrapContract.expirationDocument.expirationDate",
              data.scrapContract.expirationDocument?.expirationDate || ""
            );
            setExpiration(data.scrapContract.expirationDocument);
            setValue(
              "scrapContract.contractValue",
              data.scrapContract.contractValue || ""
            );
            setValue(
              "scrapContract.contractIndex",
              data.scrapContract.contractIndex || ""
            );
          }

          if (data.observations) {
            setValue(
              "observations.observations",
              data.observations.observations || ""
            );
          }
          setScrapAdmin(data);
        }
      });
    }
  }, [scrapId, setValue, approveModal, setSelectedContainerType]);

  function needsToShowContract(data?: any | undefined) {
    if (
      data?.scrapInfo?.status === StatusEnum.CONTRACTED ||
      data?.scrapInfo?.status === StatusEnum.CANCELED ||
      data?.scrapInfo?.status === StatusEnum.FINISHED ||
      data?.scrapInfo?.status === StatusEnum.OPERATING
    ) {
      setShowContract(true);
    } else {
      setShowContract(false);
    }
  }

  function calculateFinishData() {
    const addMonths = (date: Date, months: number) => {
      const newDate = new Date(date);
      newDate.setMonth(newDate.getMonth() + months);
      if (newDate.getDate() < date.getDate()) {
        newDate.setDate(0);
      }
      return newDate;
    };
    const currentDate = new Date();

    const contractPeriodMonths =
      (scrapAdmin?.scrapInfo?.contractPeriodDays || 0) / 30;
    const futureDate = addMonths(currentDate, contractPeriodMonths);

    const formattedDate = `${futureDate.getFullYear()}-${String(
      futureDate.getMonth() + 1
    ).padStart(2, "0")}-${String(futureDate.getDate()).padStart(2, "0")}`;

    return formattedDate;
  }

  useEffect(() => {
    if (scrapAdmin) {
      setValue("scrapInfo.containerSize", scrapAdmin.scrapInfo.containerSize);
    }
  }, [scrapAdmin, selectedContainerType, setValue]);

  useEffect(() => {
    if (
      scrapAdmin &&
      scrapAdmin.scrapNegotiation &&
      scrapAdmin.scrapNegotiation.bidNegotiationType
    ) {
      setBidNegotiationType(scrapAdmin.scrapNegotiation.bidNegotiationType);
    }
  }, [scrapAdmin]);

  useEffect(() => {
    setValue("scrapContract.contractValue", "");
    needsToShowContract(data);
  }, [selectedStatus, setValue]);

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  useEffect(() => {
    if (scrapAdmin) {
      setTargetPrice(scrapAdmin?.scrapInfo?.targetPrice || "");
      setValue(
        "scrapNegotiation.targetPrice",
        scrapAdmin?.scrapInfo?.targetPrice || ""
      );
      setValue(
        "scrapInfo.targetPrice",
        scrapAdmin?.scrapInfo?.targetPrice || ""
      );
      setBidValue(scrapAdmin?.scrapBid?.bidValue || "");
      setValue("scrapBid.bidValue", scrapAdmin?.scrapBid?.bidValue || "");
    }
  }, [scrapAdmin, setValue]);

  const fetchEntityCountry = async () => {
    const data = await getAdrressByEntityId(id as string);
    const countryConfig = await getCountryConfigById(data.country);
    setCountryConfigEntity(countryConfig);
  };

  const fetchGenerator = async () => {
    const generatorData = await getScrapGeneratorInfo(id as string);
    setGenerator(generatorData);
  };

  const fetchContainers = async () => {
    try {
      if (countryConfigEntity && countryConfigEntity.country.length > 0) {
        const response = await getContainersByCountryName(
          countryConfigEntity.country[0]
        );
        setContainers(response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchAllPartnerRequirementsTotal = async () => {
    let total: string = "";
    if (countryConfigEntity?.id) {
      total = (
        await fetchPartnerRequirementsTotalByCountry(
          countryConfigEntity?.country[0]
        )
      ).length.toString();
    }
    return total;
  };

  useEffect(() => {
    fetchEntityCountry();
    fetchGenerator();
    fetchDealersPrivateBidList();
  }, []);

  useEffect(() => {
    if (generator) {
      setValue("scrapInfo.originName", generator?.tradingName);
    }
  }, [generator]);

  useEffect(() => {
    if (countryConfigEntity && countryConfigEntity.country.length > 0) {
      fetchContainers();
    }
  }, [countryConfigEntity]);

  const fetchData = async () => {
    if (dealersInvited) {
      const newDealers = await Promise.all(
        dealersInvited?.map(async (dealer) => ({
          id: dealer.id,
          dealerName: dealer.legalName,
          inviteAccepted: false,
          region: "",
          proposedValue: " ",
          proposedOriginalValue: 0,
          attachment: " ",
          status: " ",
          inviteSended: false,
        }))
      );

      setTableData((prevData) => {
        const updatedData = prevData.filter((oldDealer) =>
          newDealers.some((newDealer) => newDealer.id === oldDealer.id)
        );

        const newItems = newDealers.filter(
          (newDealer) =>
            !updatedData.some((oldDealer) => oldDealer.id === newDealer.id)
        );

        return [...updatedData, ...newItems];
      });
    } else {
      setTableData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dealersInvited]);

  const onSubmit = async (scrapAdmin: ScrapDTO) => {
    try {
      if (saleableWaste) {
        scrapAdmin.scrapInfo.saleableWaste = true;
      }
      if (scrapAdmin.scrapInfo.status == undefined) {
        scrapAdmin.scrapInfo.status = StatusEnum.TO_DEAL;
        scrapAdmin.scrapInfo.creation = {
          createdAt: new Date(),
          createdBy: "",
          createdUser: "",
        };
        scrapAdmin.scrapInfo.creation.createdBy = profile ?? "";
        scrapAdmin.scrapInfo.creation.createdAt = new Date();
        scrapAdmin.scrapInfo.creation.createdUser = userDoc?.email ?? "";
      }

      if (scrapAdmin.scrapInfo.containerNeeded == false) {
        scrapAdmin.scrapInfo.containerSize = "";
        scrapAdmin.scrapInfo.containerQuantity = "";
        scrapAdmin.scrapInfo.containerType = "";
      }

      if (
        (tableData && tableData.length === 0) ||
        scrapAdmin.dealersInvitedTable.dealersInvitedList.length === 0
      ) {
        await fetchData();
      }

      if (scrapId) {
        scrapAdmin.id = scrapId;
      }

      if (expiration && expiration?.hasExpirationDate) {
        if (expiration.expirationDate?.length === 0) {
          return setAlert({
            text: `${t("alert-error-date-empty")}`,
            type: "error",
          });
        } else {
          scrapAdmin.scrapContract.expirationDocument = expiration;
        }
      }
      const days = valueRecurrent * unit;
      if (days > 1825) {
        setAlert({
          text: `${t("label-contract-time-expectation-error-be-longer")}`,
          type: "error",
        });
      } else {
        setValue("scrapInfo.contractPeriodDays", days);
        setValue("scrapInfo.contractPeriodDaysType", unit);
        if (id) {
          await addNewScrapAdmin(
            scrapAdmin,
            id,
            false,
            selectedImages,
            []
          ).then(async (id) => {
            if (id) {
              data = { originId: id };
              if (selectedFile && bidNegotiationType === "closed") {
                const response = await uploadCsvFile(
                  selectedFile,
                  "importdealersprivatebid",
                  data,
                  callFunction
                );
              }
            }
            setAlert({
              text: scrapId
                ? t("alert-successfully-updated")
                : t("alert-successfully-registered"),
              type: "success",
            });
            if (!scrapId) {
              navigate(`/generator-scraps/${"alertMessage"}`);
            } else {
              navigate(`/generator-scraps/`);
            }
          });
        }
      }
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
    }
  };

  const handleCategoryChange = (category: ScrapsCategoryInfoDTO) => {
    setSelectedCategoryDetails(category);
    setValue("scrapInfo.scrapCategory", category, { shouldValidate: true });
    setValue("scrapInfo.unity", category?.unity?.name);
    setSelectedUnity(t(`unityENUM.${category?.unity?.name}`));
  };

  const [containerNeeded, setContainerNeeded] = useState(true);
  const handleRadioContainerChange = (targetValue: boolean) => {
    setContainerNeeded(targetValue);
    setValue("scrapInfo.containerNeeded", targetValue);
  };

  const [operationRecipient, setOperationRecipient] = useState(true);
  const [operationCarrier, setOperationCarrier] = useState(true);
  const [operationTemporaryStorage, setOperationTemporaryStorage] =
    useState(true);

  const finalDestination = watch("scrapInfo.finalDestination");
  const carrier = watch("scrapInfo.carrier");
  const tempDealer = watch("scrapInfo.tempDealer");

  const handleRadioOperationRecipientChange = (targetValue: boolean) => {
    if (targetValue) {
      setValue("scrapInfo.finalDestination", "");
    }
    setOperationRecipient(targetValue);
    setValue("scrapInfo.operationRecipientContractedCompany", targetValue);
  };

  const handleRadioOperationCarrierChange = (targetValue: boolean) => {
    if (targetValue) {
      setValue("scrapInfo.carrier", "");
    }
    setOperationCarrier(targetValue);
    setValue("scrapInfo.operationCarrierContractedCompany", targetValue);
  };

  const handleRadioOperationTempStorageChange = (targetValue: boolean) => {
    if (targetValue) {
      setValue("scrapInfo.tempDealer", "");
    }
    setOperationTemporaryStorage(targetValue);
    setValue("scrapInfo.operationTempStorageContractedCompany", targetValue);
  };

  const [recurrent, setRecurrent] = useState(false);

  const [saleableWaste, setSaleableWaste] = useState(
    scrapAdmin?.scrapInfo?.saleableWaste ?? true
  );

  const handleRadioContainerChangeSale = (targetValue: boolean) => {
    setSaleableWaste(targetValue);
    setValue("scrapInfo.saleableWaste", targetValue);
  };

  const [unit, setUnit] = useState(365);
  const [valueRecurrent, setValueRecurrent] = useState(1);

  useEffect(() => {
    setValue("scrapInfo.contractPeriodDays", valueRecurrent * unit);
  }, [valueRecurrent, unit]);

  const contractPeriodOptions = [
    { display: `${t("years")}`, value: 365 },
    { display: `${t("months")}`, value: 30 },
  ];

  const [bidNegotiationType, setBidNegotiationType] = useState<string>("open");
  const [responsibleShipping, setResponsibleShipping] = useState<string>("");

  const [today, setToday] = useState("");

  const [expiration, setExpiration] = useState<ExpirationDTO>({
    originId: "",
    docName: "Contrato",
    expirationDate: "",
    warningPeriod: 60,
    solved: false,
    hasExpirationDate: false,
    origin: "",
  });

  const defaultAddress: AddressInfoDTO = {
    address: "",
    state: "",
    country: "",
    countryName: "",
    city: "",
    postalCode: "",
    postalCodeLabel: "",
  };

  const [address, setAddress] = useState<AddressInfoDTO>(
    scrapAdmin?.scrapInfo?.operationAddress || defaultAddress
  );

  const handleDataChange = (key: string, value: any) => {
    setExpiration((contractExpirationDate: ExpirationDTO) => ({
      ...contractExpirationDate,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (expiration) {
      setValue("scrapContract.expirationDocument", expiration);
    }
  }, [expiration]);

  const getIconName = (rowItem: any) => {
    if (rowItem.inviteAccepted) return "checkmark-frame";
    if (rowItem.inviteSended) return "mail-forward";
    return "mail";
  };

  const [containerQuantityMaskedValue, setContainerQuantityMaskedValue] =
    useState("");
  const [quantityMaskedValue, setQuantityMaskedValue] = useState("");

  const handleInputChangeContainerQuantity = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const masked = applyDecimalMask(value);
    setContainerQuantityMaskedValue(masked);
  };

  const handleInputChangeQuantity = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const masked = applyDecimalMask(value);
    setQuantityMaskedValue(masked);
  };

  useEffect(() => {
    setValue("scrapInfo.quantity", containerQuantityMaskedValue);
  }, []);

  useEffect(() => {
    setValue("scrapInfo.containerQuantity", quantityMaskedValue);
  }, []);

  const handleSave = (newAddress: any) => {
    setAddress(newAddress);
    setValue("scrapInfo.operationAddress", newAddress);
  };

  const handleChangePicker = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setBidNegotiationType(value);
    setValue("scrapNegotiation.bidNegotiationType", value);
    if (scrapId) {
      updateOnlyFieldOfForm(
        scrapId,
        COLLECTIONS.SCRAP_BY_GENERATOR_NEGOTIATION,
        "bidNegotiationType",
        value
      ).then(() => {
        fetchDealersPrivateBidList();
      });
    }
  };

  const handleUploadCsvPrivateBid = () => {
    const delay = 2000;
    timer = setTimeout(() => {
      fetchDealersPrivateBidList();
    }, delay);
  };

  const fetchDealersPrivateBidList = async () => {
    if (scrapId) {
      const privateBidList = await getPrivateBidList(scrapId);
      setPrivateBidList(privateBidList);
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const contractedDealerId = e.target.value;
    const generatorId = scrapAdmin?.scrapInfo.originId;

    getScrapDealerById(contractedDealerId).then(async (dealer) => {
      const customers = dealer?.scrapDealerInfo?.customers || [];
      const updatedCustomers = [...customers, generatorId];
      await updateOnlyFieldOfForm(
        contractedDealerId,
        COLLECTIONS.SCRAP_DEALERS,
        "customers",
        updatedCustomers
      );
    });
  };

  const handleChangeResponsibleShipping = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.target.value;
    setValue("scrapInfo.responsibleShipping", value);
    setResponsibleShipping(value);
  };

  useEffect(() => {
    if (id && !scrapId) {
      getAdrressByEntityId(id).then((data) => {
        setAddress(data as AddressInfoDTO);
        setValue("scrapInfo.operationAddress", data);
      });
    }
  }, [id]);

  useEffect(() => {
    const todayDate = new Date().toISOString().split("T")[0];
    setToday(todayDate);
  }, []);

  useEffect(() => {
    if (scrapAdmin) {
      setValue("scrapInfo.scrapCategory", scrapAdmin?.scrapInfo?.scrapCategory);
    }
  }, [scrapAdmin, setValue]);

  useEffect(() => {
    if (!containerNeeded) {
      setValue("scrapInfo.containerType", "");
      setValue("scrapInfo.containerSize", "");
      setValue("scrapInfo.containerQuantity", "");
    }
  }, [containerNeeded, setValue]);

  const selectedContainer = containers.find(
    (container) => container.name === selectedContainerType
  );

  const getAttachmentText = (attachments: string[]) => {
    let attachmentString: string = "";

    if (attachments.length === 1 && attachments[0].trim() === "") {
      attachments = [];
    }

    if (attachments.length > 0) {
      const attachmentCount = attachments.length;
      attachmentString = `${t("label-attachment")} (${attachmentCount})`;
    } else {
      attachmentString = "-";
    }

    return attachmentString;
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page-centered">
        {confirmationModalOpen && (
          <div className="modal-background">
            <GenericConfirmationModal
              onClose={() => setConfirmationModalOpen(false)}
              onConfirm={() => cancelScrapForm()}
            >
              <p>{t("cancel-confirmation-message")}</p>
            </GenericConfirmationModal>
          </div>
        )}

        {infoModal && (
          <div className="modal-background">
            <GenericConfirmationModal
              onConfirm={() => {
                setInfoModal(false);
              }}
              title={t("label-wait-our-contact")}
              confirmButtonLabel={t(
                "notice-modal-generator.notice-button-confirm"
              )}
            >
              <p style={{ fontSize: "18px", lineHeight: "1.3" }}>
                <Trans
                  i18nKey={t(
                    "notice-modal-generator.wait-contact-after-contract"
                  )}
                  components={{ bold: <strong /> }}
                />
              </p>
            </GenericConfirmationModal>
          </div>
        )}

        {approveModal && scrapAdmin && userDoc?.email && (
          <div className="modal-background">
            <ApproveModal
              material={{
                name: scrapAdmin?.scrapInfo?.scrapCategory?.name || "",
                id: scrapAdmin?.scrapInfo?.scrapCategory?.id || "",
                unity: scrapAdmin?.scrapInfo?.scrapCategory?.unity.name || "",
                isHazardousMaterial:
                  scrapAdmin?.scrapInfo.scrapCategory.isHazardousMaterial ||
                  false,
              }}
              generatorName={generator?.legalName || ""}
              refID={scrapAdmin?.scrapInfo.id}
              email={userDoc?.email}
              onClose={() => {
                setApproveModal(false);
                setInfoModal(true);
              }}
              onReprove={() => {
                setApproveModal(false);
                setAlert({
                  text: t("label-reproved"),
                  type: "success",
                });
                navigate(`/generator-scraps/`);
                const delay = 500;
                timer = setTimeout(() => {
                  navigate(
                    `/generator-scraps/${id}/generator-scraps-form/${scrapId}`
                  );
                }, delay);
              }}
              updateData={{
                contractDealer:
                  invitedDealersCompleteData?.dealersInvitedList?.find(
                    (obj) => obj.status === "WINNER"
                  )?.id || "",
                contractDealerName:
                  invitedDealersCompleteData?.dealersInvitedList?.find(
                    (obj) => obj.status === "WINNER"
                  )?.dealerName || "",
                status: StatusEnum.CONTRACTED,
              }}
              updateContractData={{
                contractDate: new Date().toISOString(),
                contractValue:
                  invitedDealersCompleteData?.dealersInvitedList?.find(
                    (obj) => obj.status === "WINNER"
                  )?.proposedValue || "",
                contractTotalValue:
                  parseFloat(
                    scrapAdmin?.scrapInfo.quantity.replace(".", "") || "0"
                  ) *
                  parseFloat(
                    `${invitedDealersCompleteData?.dealersInvitedList
                      ?.find((obj) => obj.status === "WINNER")
                      ?.proposedValue?.match(/[\d.,]+/)?.[0]
                      .replace(",", ".")}`
                  ),
                contractValueUnity: parseFloat(
                  `${invitedDealersCompleteData?.dealersInvitedList
                    ?.find((obj) => obj.status === "WINNER")
                    ?.proposedValue?.match(/[\d.,]+/)?.[0]
                    .replace(",", ".")}`
                ),
                expirationDocument: {
                  expirationDate: calculateFinishData(),
                  hasExpirationDate: true,
                  warningPeriod:
                    currentPeriod == "SINGLE_SALE"
                      ? 60
                      : expiration?.warningPeriod || 0,
                },
              }}
            ></ApproveModal>
          </div>
        )}
        {editTableModalOpen && editTableItem && (
          <div className="modal-background">
            <ScrapDealersInvitedModalForm
              countryConfigEntity={countryConfigEntity}
              tableData={editTableItem}
              onClose={handleCloseTableModal}
              id={scrapId || ""}
              scrapData={scrapAdmin ? scrapAdmin : undefined}
            />
          </div>
        )}
        {isModalOpen && (
          <UploadCsvModal
            onClose={handleCloseModal}
            data={data}
            passFileToParent={scrapId ? false : true}
            onFileSelect={(file) => setSelectedFile(file)}
            apiData={"importdealersprivatebid"}
            templateUrl={`https://firebasestorage.googleapis.com/v0/b/rbprj-100132.appspot.com/o/templates%2F${"importdealersprivatebid"}.csv?alt=media`}
          />
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-fields">
            <CollapsibleSection title={t("material-about-material")}>
              <div className="flex-column">
                <div className="flex-row radio-types">
                  <div className="flex-row">
                    <input
                      type="radio"
                      id="saleDisposal"
                      value="true"
                      onChange={(e) => handleRadioContainerChangeSale(true)}
                      checked={saleableWaste === true}
                    />
                    <label
                      style={{ margin: "10px 0px" }}
                      htmlFor="saleDisposal"
                    >
                      {t("label-saleDisposal")}
                    </label>
                  </div>
                  <div className="flex-row">
                    <input
                      type="radio"
                      id="non-saleDisposal"
                      value="false"
                      onChange={() => handleRadioContainerChangeSale(false)}
                      checked={saleableWaste === false}
                    />
                    <label
                      style={{ margin: "10px" }}
                      htmlFor="non-saleDisposal"
                    >
                      {t("label-non-saleDisposal")}
                    </label>
                  </div>
                  {errors.scrapInfo?.saleableWaste === null &&
                    scrapAdmin?.scrapInfo?.saleableWaste && (
                      <span>{t("error-input-required")}</span>
                    )}
                </div>
              </div>

              <div className="flex-column margin-topbottom-1">
                <ScrapCategoryInputAutocomplete
                  label={`${t("label-scrap-category")}*`}
                  placeholder={t("label-scrap-category")}
                  onChange={handleCategoryChange}
                  initialValue={
                    scrapAdmin &&
                    scrapAdmin.scrapInfo &&
                    scrapAdmin.scrapInfo.scrapCategory
                  }
                />
                {!selectedCategoryDetails && isSubmitted && (
                  <span>{t("error-input-required")}</span>
                )}
                {selectedCategoryDetails && (
                  <div className="flex-row info-section">
                    <img
                      style={{
                        height: "6rem",
                        width: "8rem",
                        overflow: "hidden",
                        marginRight: "1rem",
                      }}
                      src={
                        selectedCategoryDetails.images
                          ? selectedCategoryDetails.images[
                              selectedCategoryDetails.images.length - 1
                            ]
                          : genericImage
                      }
                      alt="material image"
                    />
                    <div style={{ width: "100%" }} className="flex-column">
                      <h2>{selectedCategoryDetails.name}</h2>
                      <div className="custom-line-hr"></div>
                      <div className="flex-row justify-between card-scrap-basic-info">
                        <div
                          className="flex-column"
                          style={{ gap: "10px", maxWidth: "80%" }}
                        >
                          <p style={{ wordWrap: "break-word" }}>
                            {t("label-material-class")}:{" "}
                            <strong>
                              {selectedCategoryDetails.materialClass.name ||
                                "-"}
                            </strong>
                          </p>
                        </div>
                        <div
                          className="flex-column"
                          style={{ gap: "10px", maxWidth: "80%" }}
                        >
                          <p style={{ wordWrap: "break-word" }}>
                            {t("label-physical-state")}:{" "}
                            <strong>
                              {" "}
                              {t(
                                `physicalStateENUM.${selectedCategoryDetails.phisycalState.name}`
                              )}
                            </strong>
                          </p>
                        </div>{" "}
                        <div
                          className="flex-column"
                          style={{ gap: "10px", maxWidth: "80%" }}
                        >
                          <p style={{ wordWrap: "break-word" }}>
                            {t("label-ibama-code")}:{" "}
                            <strong>
                              {selectedCategoryDetails.ibamaCode || "-"}
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex-row double-fields">
                <div className="flex-column margin-topbottom-1">
                  <label htmlFor="material-code-NCM">
                    {t("material-code-NCM")}
                  </label>
                  <div
                    className="flex-row double-fields"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="flex-column">
                      <input
                        type="text"
                        id="material-code-NCM"
                        {...register("scrapInfo.codeNCM", {
                          required: false,
                        })}
                      />
                    </div>
                    <div className="flex-column">
                      <a
                        href="https://portalunico.siscomex.gov.br/classif/#/nomenclatura/tabela?perfil=publico"
                        target="_blank"
                      >
                        Consulte a lista do NCM aqui!
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-column margin-topbottom-1">
                <label htmlFor="material-special-description">
                  {t("material-special-description")}
                </label>
                <input
                  placeholder={t("material-placeholder-special-description")}
                  type="text"
                  id="material-special-description"
                  {...register("scrapInfo.specialDescription", {
                    required: false,
                  })}
                />
              </div>

              <div className="flex-column">
                <label
                  htmlFor="imageUpload"
                  className="custom-file-upload title"
                >
                  {t("label-material-pic")}
                </label>
                <div className="input-and-preview">
                  <input
                    style={{ color: "white" }}
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    multiple
                    onChange={handleImageChange}
                  />
                  <div className="image-previews">
                    {previews?.map((preview, index) => (
                      <div key={index} className="image-selected">
                        <img
                          className="preview-img"
                          src={preview}
                          alt={`Preview ${index}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </CollapsibleSection>

            <CollapsibleSection title={t("material-about-operation")}>
              <div className="flex-column margin-topbottom-1">
                <label htmlFor="material-dealers-invited"></label>
                <MTRTreatmentInputAutocomplete
                  label={t("treatment")}
                  placeholder={t("")}
                  control={control}
                  name="scrapInfo.treatmentMTRCode"
                  errors={errors}
                  onChange={(treatmentMTRCode) => {
                    if (treatmentMTRCode) {
                      setValue("scrapInfo.treatmentMTRCode", treatmentMTRCode);
                    }
                  }}
                  initialValue={
                    scrapAdmin &&
                    scrapAdmin.scrapInfo &&
                    scrapAdmin.scrapInfo.treatmentMTRCode
                  }
                  isDisabled={false}
                />
                <label htmlFor="material-quantity">
                  {`${t("material-quantity")}*`}
                </label>
                <div className="flex-row double-fields">
                  <div className="flex-column">
                    <input
                      placeholder={t("label-quantity")}
                      type="text"
                      value={quantityMaskedValue}
                      id="label-quantity"
                      {...register("scrapInfo.quantity", {
                        required: true,
                      })}
                      onChange={handleInputChangeQuantity}
                    />
                    {errors.scrapInfo?.quantity && isSubmitted && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>

                  <div className="flex-column">
                    <select
                      disabled
                      id="material-quantity-unity"
                      {...register("scrapInfo.unity", { required: true })}
                      onChange={handleUnityChange}
                    >
                      <option value=""></option>
                      {Object.entries(unityENUM).map(([key, value]) => (
                        <option key={key} value={key}>
                          {t(`unityENUM.${value}`)}
                        </option>
                      ))}
                    </select>
                    {errors.scrapInfo?.unity && isSubmitted && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>
                  <div className="flex-column">
                    <select
                      id="material-quantity-period"
                      {...register("scrapInfo.period", { required: true })}
                      onChange={(e) => {
                        setValue("scrapInfo.period", e.target.value);
                        setCurrentPeriod(e.target.value);
                        if (e.target.value === "SINGLE_SALE") {
                          setValue("scrapInfo.pickUpFrequency", e.target.value);
                        }
                      }}
                    >
                      <option value=""></option>
                      {Object.entries(periodENUM).map(([key, value]) => (
                        <option key={key} value={key}>
                          {t(`periodENUM.${value}`)}
                        </option>
                      ))}
                      <option value="SINGLE_SALE">
                        {t("label-single-sale")}
                      </option>
                    </select>
                    {errors.scrapInfo?.period && isSubmitted && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="double-fields">
                <div className="flex-column">
                  <label htmlFor="material-pick-up-frequency">
                    {`${t("material-pick-up-frequency")}*`}
                  </label>
                  {currentPeriod !== "SINGLE_SALE" ? (
                    <select
                      id="material-pick-up-frequency"
                      {...register("scrapInfo.pickUpFrequency", {
                        required: true,
                      })}
                    >
                      <option value=""></option>
                      {Object.entries(periodENUM).map(([key, value]) => (
                        <option key={key} value={key}>
                          {t(`periodENUM.${value}`)}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select disabled>
                      <option value="SINGLE_SALE">
                        {t("label-single-sale")}
                      </option>
                    </select>
                  )}
                  {errors.scrapInfo?.pickUpFrequency && (
                    <span>{t("error-input-required")}</span>
                  )}
                </div>

                <div className="flex-column">
                  <label htmlFor="responsible-shipping">
                    {`${t("label-responsible-shipping")}*`}
                  </label>
                  <select onChange={handleChangeResponsibleShipping}>
                    <option value={""}></option>
                    <option
                      selected={
                        scrapAdmin?.scrapInfo?.responsibleShipping ===
                        "generator"
                      }
                      value={"generator"}
                    >
                      {t("label-generator")}
                    </option>
                    <option
                      selected={
                        scrapAdmin?.scrapInfo?.responsibleShipping === "buyer"
                      }
                      value={"buyer"}
                    >
                      {t("label-buyer")}
                    </option>
                  </select>
                  {responsibleShipping === "" && isSubmitted && (
                    <span>{t("error-input-required")}</span>
                  )}
                </div>
              </div>

              <AddressSectionFields
                initialValues={address}
                onSave={handleSave}
              ></AddressSectionFields>

              <div className="flex-column">
                <h4>{t("material-does-container")}</h4>
                <div className="flex-row radio-types">
                  <div className="flex-row">
                    <label
                      style={{ margin: "10px 0px" }}
                      htmlFor="material-container"
                    >
                      <input
                        type="radio"
                        id="material-container"
                        value="true"
                        onChange={(e) => handleRadioContainerChange(true)}
                        checked={containerNeeded === true}
                      />
                      {t("label-need")}
                    </label>
                  </div>
                  <div className="flex-row">
                    <label style={{ margin: "10px" }} htmlFor="isBranch">
                      <input
                        type="radio"
                        id="isBranch"
                        value="false"
                        onChange={() => handleRadioContainerChange(false)}
                        checked={containerNeeded === false}
                      />
                      {t("label-non-need")}
                    </label>
                  </div>
                  {errors.scrapInfo?.containerNeeded === null &&
                    scrapAdmin?.scrapInfo?.containerNeeded && (
                      <span>{t("error-input-required")}</span>
                    )}
                </div>
              </div>

              {containerNeeded === true && countryConfigEntity && (
                <div className="flex-column">
                  <div className="flex-row double-fields">
                    <div
                      className="flex-column"
                      style={{ justifyContent: "center", marginTop: "-6px" }}
                    >
                      <ScrapStorageInputAutocomplete
                        countryConfigEntity={countryConfigEntity}
                        label={t("material-container-type")}
                        onChange={(item) => {
                          setSelectedContainerType(item);
                          setValue("scrapInfo.containerType", item);
                        }}
                        placeholder={t("material-container-type")}
                        initialValue={scrapAdmin?.scrapInfo?.containerType}
                      />
                      {errors.scrapInfo?.containerType && (
                        <span>{t("error-input-required")}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label htmlFor="material-container-size">
                        {t("material-container-size")}
                      </label>
                      <input
                        placeholder={t("placeholder.generalEmpty")}
                        type="text"
                        id="legalName"
                        {...register("scrapInfo.containerSize", {
                          required: false,
                        })}
                      />
                    </div>

                    <div className="flex-column">
                      <label htmlFor="material-container-quantity">
                        {`${t("material-container-quantity")}*`}
                      </label>
                      <input
                        type="text"
                        value={containerQuantityMaskedValue}
                        id="material-container-quantity"
                        {...register("scrapInfo.containerQuantity", {
                          required: true,
                        })}
                        onChange={handleInputChangeContainerQuantity}
                      />
                      {errors.scrapInfo?.containerQuantity && (
                        <span>{t("error-input-required")}</span>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="form-fields flex-column">
                <div className="flex-column">
                  <label htmlFor="observationsStorage">
                    {t("label-observations-storage")}
                  </label>
                  <textarea
                    id="observationsStorage"
                    {...register("scrapInfo.observationsStorage")}
                  />
                </div>
              </div>

              {currentPeriod !== "SINGLE_SALE" && (
                <div style={{ marginTop: "1rem" }}>
                  <h4>{t("label-contract-time-expectation")}</h4>
                  <div className="double-fields">
                    <div
                      className="double-fields"
                      style={{ marginTop: "1rem" }}
                    >
                      <div className="flex-row" style={{ flex: 1 }}>
                        <button
                          type="button"
                          style={{ margin: 0 }}
                          onClick={() =>
                            setValueRecurrent(
                              valueRecurrent <= 1 ? 1 : valueRecurrent - 1
                            )
                          }
                          className="number-input-button"
                        >
                          -
                        </button>
                        <input
                          className="expiration-input-qtt-days"
                          value={valueRecurrent}
                          onChange={(e) =>
                            setValueRecurrent(parseInt(e.target.value))
                          }
                          type="number"
                        />
                        <button
                          type="button"
                          style={{ margin: 0 }}
                          onClick={() => setValueRecurrent(valueRecurrent + 1)}
                          className="number-input-button"
                        >
                          +
                        </button>
                      </div>
                      <div className="flex-column" style={{ flex: 1 }}>
                        <select
                          onChange={(e) => {
                            setUnit(parseInt(e.target.value));
                            setValue(
                              "scrapInfo.contractPeriodDaysType",
                              parseInt(e.target.value)
                            );
                          }}
                          style={{ width: "100%" }}
                          value={unit}
                        >
                          {contractPeriodOptions.map((obj, index) => (
                            <option key={index} value={obj.value}>
                              {obj.display}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </CollapsibleSection>

            <CollapsibleSection title={t("material-about-negotiation")}>
              <div className="double-fields">
                <div className="flex-column">
                  <label htmlFor="material-price-expectancy">
                    {t("material-price-expectancy")}
                    <span
                      style={{ margin: 0, marginLeft: 5, color: "#9e9e9e" }}
                    >
                      {`(${countryConfigEntity?.currencyName}/${
                        selectedUnity || "unity"
                      })`}
                    </span>
                  </label>
                  <CurrencyInput
                    currency={countryConfigEntity?.currency}
                    locale={countryConfigEntity?.locale}
                    defaultValue={targetPrice}
                    onChangeValue={(event, originalValue, maskedValue) => {
                      setTargetPrice(maskedValue.toString());
                      setValue(
                        "scrapNegotiation.targetPrice",
                        maskedValue.toString(),
                        {
                          shouldValidate: true,
                        }
                      );
                    }}
                    InputElement={
                      <input
                        placeholder={t("material-price-expectancy")}
                        type="text"
                        id="targetPrice"
                        {...register("scrapNegotiation.targetPrice", {
                          required: false,
                        })}
                      />
                    }
                  />
                  {errors.scrapNegotiation?.targetPrice && (
                    <span>{t("error-input-required")}</span>
                  )}
                </div>

                <div className="flex-column">
                  <div className="flex-column">
                    <label htmlFor="material-sales-date-final">
                      {`${t("material-sales-date")}*`}
                    </label>
                    <input
                      type="date"
                      min={getMinDate()}
                      id="material-sales-date-final"
                      {...register("scrapNegotiation.salesDate", {
                        required: true,
                      })}
                    />
                    {errors.scrapNegotiation?.salesDate && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="double-fields margin-topbottom-1">
                <div style={{ marginTop: "1rem" }} className="flex-column">
                  <label htmlFor="material-negotiation-type">
                    {t("material-negotiation-type")}
                  </label>
                  <select
                    value={bidNegotiationType}
                    onChange={handleChangePicker}
                  >
                    <option value={"open"}>{t("label-open-bid")}</option>
                    <option value={"closed"}>{t("label-closed-bid")}</option>
                  </select>
                </div>
                {bidNegotiationType && bidNegotiationType === "closed" && (
                  <div className="flex-row align-end">
                    <button
                      id="material-add-prefered-suppliers"
                      style={{ margin: 0 }}
                      type="button"
                      title={t("label-upload-csv")}
                      onClick={handleOpenModal}
                      className="neutral-white white-with-icon button ml-10"
                    >
                      <img src={uploadIcon} alt={t("label-upload-csv")} />
                    </button>

                    {selectedFile &&
                    bidNegotiationType === "closed" &&
                    !scrapId ? (
                      <label
                        className="a-link-style"
                        htmlFor="material-add-prefered-suppliers"
                        style={{ padding: "0.2rem" }}
                      >
                        {t("label-attached-file")}
                      </label>
                    ) : (
                      <label htmlFor="material-add-prefered-suppliers">
                        {t("material-add-prefered-suppliers")}
                      </label>
                    )}
                  </div>
                )}
              </div>
              {privateBidList && bidNegotiationType === "closed" && scrapId && (
                <div>
                  <SparkTable>
                    <SparkTableHead
                      className="flex-row"
                      style={{ alignItens: "center" }}
                    >
                      <SparkTableRow>
                        {privateBidTableHeader?.map((header, index) => (
                          <SparkTableCell
                            style={{
                              alignItens: "center",
                              display: "flex",
                              flexDirection: "row",
                            }}
                            key={index}
                          >
                            <strong>{header.toUpperCase()}</strong>
                          </SparkTableCell>
                        ))}
                      </SparkTableRow>
                    </SparkTableHead>

                    <SparkTableBody>
                      {privateBidList?.map(
                        (rowItem: PrivateBidDTO, rowIndex: any) => (
                          <SparkTableRow key={rowIndex}>
                            <SparkTableCell>
                              {rowItem.nomeContato}
                            </SparkTableCell>
                            <SparkTableCell>{rowItem.email}</SparkTableCell>
                            <SparkTableCell>{rowItem.telefone}</SparkTableCell>
                          </SparkTableRow>
                        )
                      )}
                    </SparkTableBody>
                  </SparkTable>
                </div>
              )}
            </CollapsibleSection>

            {invitedDealersCompleteData?.dealersInvitedList?.find(
              (obj) => obj.status === "WINNER"
            )?.proposedValue &&
              !scrapAdmin?.scrapInfo.approveData &&
              status != "DISAPPROVED" &&
              status != "CONTRACTED" &&
              status != "OPERATING" && (
                <CollapsibleSection
                  title={t("material-best-price")}
                  leftColor="var(--bosch-accent-blue)"
                  leftSize="10px"
                >
                  <div
                    className="flex-column margin-topbottom-1"
                    style={{ gap: "1rem" }}
                  >
                    {t("best-price-we-found")}
                    <h2>
                      {
                        invitedDealersCompleteData?.dealersInvitedList?.find(
                          (obj) => obj.status === "WINNER"
                        )?.proposedValue
                      }{" "}
                      / {t(`unityENUM.${scrapAdmin?.scrapInfo.unity}`)}
                    </h2>
                    <p>
                      {t("best-price-location").replace(
                        "{VARIABLE}",
                        `${
                          invitedDealersCompleteData?.dealersInvitedList?.find(
                            (obj) => obj.status === "WINNER"
                          )?.region
                        }`
                      )}
                    </p>
                    {currentPeriod !== "SINGLE_SALE" && (
                      <p>
                        {t("best-price-contract-label")}{" "}
                        {reciveDaysAndtransformInYearsOrMonths(
                          scrapAdmin?.scrapInfo?.contractPeriodDays || 0
                        )}
                      </p>
                    )}
                  </div>

                  {invitedDealersCompleteData &&
                    invitedDealersCompleteData?.dealersInvitedList?.some(
                      (obj) => obj.status === "WINNER"
                    ) && (
                      <>
                        {parseFloat(
                          scrapAdmin?.scrapInfo.quantity.replace(".", "") || "0"
                        ) *
                          parseFloat(
                            `${invitedDealersCompleteData?.dealersInvitedList
                              ?.find((obj) => obj.status === "WINNER")
                              ?.proposedValue?.match(/[\d.,]+/)?.[0]
                              .replace(",", ".")}`
                          ) <=
                        maxApproveValue ? (
                          <button
                            className="blue"
                            onClick={(e) => {
                              e.preventDefault();
                              setApproveModal(true);
                            }}
                          >
                            {t("accept-terms-and-conditions")}
                          </button>
                        ) : (
                          <>
                            <br />
                            <CTAInfoComponent color="#fbec5d">
                              <div
                                className="flex-row align-center"
                                style={{ gap: 10 }}
                              >
                                <SparkIcon noPadding icName="alert-warning" />
                                <p style={{ color: "black" }}>
                                  {t("best-price-no-access")}
                                </p>
                              </div>
                            </CTAInfoComponent>
                            <button
                              className="buttonGray"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {t("accept-terms-and-conditions")}
                            </button>
                          </>
                        )}
                      </>
                    )}
                </CollapsibleSection>
              )}

            {status == StatusEnum.DISAPPROVED && (
              <div
                className="info-section"
                style={{ borderLeft: "5px solid var(--bosch-error-red)" }}
              >
                <div className="flex-row align-center" style={{ gap: 5 }}>
                  <SparkIcon
                    icName="alert-warning"
                    noPadding
                    className="margin-topbottom-1"
                  />
                  <h3 style={{ margin: 0 }}>{t("label-reproved-proposal")}</h3>
                </div>
                <div className="flex-column">
                  <label htmlFor="reproval-reason" style={{ marginBottom: 10 }}>
                    {t("label-reason")}
                  </label>
                  <textarea
                    disabled
                    value={scrapAdmin?.scrapInfo.reasonDisapproval}
                    style={{
                      maxWidth: "98.5%",
                      minWidth: "98.5%",
                      minHeight: "6rem",
                    }}
                    placeholder={t("label-reason")}
                    id="reproval-reason"
                  />
                </div>
              </div>
            )}
            {showContract && (
              <CollapsibleSection title={t("material-about-contract")}>
                <>
                  <div className="flex-column margin-topbottom-1">
                    <label htmlFor="material-dealer-contracted">
                      {t("material-dealer-contracted")}
                    </label>
                    <select
                      disabled
                      className="no-disabled-style"
                      id="material-dealer-contracted"
                      {...register("scrapInfo.contractDealer", {
                        required: false,
                      })}
                      onChange={(e) => handleSelectChange(e)}
                    >
                      <option value={""}></option>
                      {dealersInvited?.map((dealer: any) => (
                        <option key={dealer.id} value={dealer.id}>
                          {dealer.legalName} - {dealer.tradingName}
                        </option>
                      ))}
                    </select>
                    {errors.scrapInfo?.contractDealer && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>

                  <div className="flex-column margin-topbottom-1">
                    <label htmlFor="material-contract-date">
                      {t("material-contract-date")}
                    </label>
                    <input
                      value={
                        scrapAdmin?.scrapContract.contractDate.split("T")[0]
                      }
                      disabled
                      className="no-disabled-style"
                      placeholder={t("material-contract-date")}
                      type="date"
                      id="material-contract-date"
                      {...register("scrapContract.contractDate", {
                        required: false,
                      })}
                    />
                    {errors.scrapContract?.contractDate && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>
                </>

                <>
                  {showContract && expiration && (
                    <div className="flex-column">
                      <Expiration
                        isValidateDateEnableToChange={false}
                        dto={expiration}
                        dataHandler={handleDataChange}
                        expirationDate={"expirationDate"}
                        warningPeriod={"warningPeriod"}
                        hasExpirationDate={"hasExpirationDate"}
                        hasExpirationDateInit={true}
                        disableExpirationCheckbox
                        disablePreviousWarningFields
                        showPreviousWarning={true}
                        expirationDateInit={expiration.expirationDate || ""}
                        warningPeriodInit={expiration.warningPeriod || 60}
                      />
                    </div>
                  )}
                </>

                {showContract &&
                  scrapAdmin?.scrapInfo.approveData?.status === "approve" && (
                    <>
                      <div className="flex-column margin-topbottom-1">
                        <label htmlFor="material-contract-value">
                          {t("material-contract-value")}
                          <span style={{ marginLeft: 5, color: "#9e9e9e" }}>
                            {`(${countryConfigEntity?.currencyName}/${
                              selectedUnity || "unity"
                            })`}
                          </span>
                        </label>

                        <CurrencyInput
                          currency={countryConfigEntity?.currency}
                          locale={countryConfigEntity?.locale}
                          value={scrapAdmin?.scrapContract.contractValue}
                          defaultValue={
                            scrapAdmin?.scrapContract?.contractValue
                          }
                          onChangeValue={(
                            event,
                            originalValue,
                            maskedValue
                          ) => {
                            setContractValue(maskedValue.toString());
                            setValue(
                              "scrapContract.contractValue",
                              maskedValue.toString(),
                              {
                                shouldValidate: true,
                              }
                            );
                          }}
                          InputElement={
                            <input
                              disabled
                              className="no-disabled-style"
                              placeholder={t("material-contract-value")}
                              type="text"
                              id="material-contract-value"
                              {...register("scrapContract.contractValue", {
                                required: false,
                              })}
                            />
                          }
                        />
                        {errors.scrapContract?.contractValue && (
                          <span>{t("error-input-required")}</span>
                        )}
                      </div>

                      <div className="flex-column margin-topbottom-1">
                        <label htmlFor="material-contract-index">
                          {t("material-contract-index")}
                        </label>
                        <input
                          disabled
                          className="no-disabled-style"
                          placeholder={t("material-contract-index")}
                          type="text"
                          id="material-contract-index"
                          {...register("scrapContract.contractIndex", {
                            required: false,
                          })}
                        />
                        {errors.scrapContract?.contractIndex && (
                          <span>{t("error-input-required")}</span>
                        )}
                      </div>
                    </>
                  )}
              </CollapsibleSection>
            )}

            {(scrapAdmin?.scrapInfo.status == StatusEnum.CONTRACTED ||
              scrapAdmin?.scrapInfo.status == StatusEnum.OPERATING ||
              scrapAdmin?.scrapInfo.status == StatusEnum.FINISHED ||
              scrapAdmin?.scrapInfo.status == StatusEnum.CANCELED) &&
              scrapAdmin?.scrapInfo && (
                <CollapsibleSection title={t("material-about-operation-label")}>
                  <div className="flex-column">
                    {!scrapAdmin?.scrapInfo.operationDate && (
                      <CTAInfoComponent>
                        <div
                          className="flex-row align-center"
                          style={{ gap: 10 }}
                        >
                          <SparkIcon
                            noPadding
                            icName="lightbulb-attention"
                            pallete="primary"
                          />
                          <p>
                            {t(
                              "notice-modal-generator.wait-contact-after-contract"
                            )}
                          </p>
                        </div>
                      </CTAInfoComponent>
                    )}
                    <br></br>
                    <label htmlFor="material-operation-date">
                      {t("label-init-date")}
                    </label>
                    <input
                      type="date"
                      id="material-operation-date"
                      {...register("scrapInfo.operationDate", {
                        required: false,
                      })}
                      disabled
                    />
                    {errors.scrapInfo?.operationDate && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>
                  <div className="flex-column">
                    <h4>{t("label-type-isFinal")}</h4>
                    <div className="flex-row radio-types">
                      <div className="flex-row">
                        <label
                          style={{ margin: "10px 0px" }}
                          htmlFor="recipient-radio"
                        >
                          <input
                            disabled
                            type="radio"
                            id="recipient-radio"
                            value="true"
                            onChange={(e) =>
                              handleRadioOperationRecipientChange(true)
                            }
                            checked={operationRecipient === true}
                          />
                          {t("label-contracted-company")}
                        </label>
                      </div>
                      <div className="flex-row">
                        <label
                          style={{ margin: "10px" }}
                          htmlFor="other-recipient"
                        >
                          <input
                            disabled
                            type="radio"
                            id="other-recipient"
                            value="false"
                            onChange={() =>
                              handleRadioOperationRecipientChange(false)
                            }
                            checked={operationRecipient === false}
                          />
                          {t("label-other")}
                        </label>
                      </div>
                      {errors.scrapInfo?.operationRecipientContractedCompany ===
                        null &&
                        scrapAdmin?.scrapInfo
                          ?.operationRecipientContractedCompany && (
                          <span>{t("error-input-required")}</span>
                        )}
                    </div>
                  </div>
                  {!operationRecipient && (
                    <div>
                      <ScrapDealerInputAutocomplete
                        isDisabled
                        isSingleSelect
                        onChange={(data) => {
                          const scrapDealerInfo = {
                            id: data[0].id,
                            name: data[0].legalName,
                            MTRcode: data[0]?.mtrCode || "",
                            nationalRegister: data[0].nationalRegister,
                          };
                          setValue(
                            "scrapInfo.finalDestination",
                            scrapDealerInfo
                          );
                          trigger("scrapInfo.finalDestination");
                        }}
                        placeholder={t("label-type-isFinal")}
                        defaultValue={
                          finalDestination
                            ? [
                                {
                                  legalName: finalDestination.name,
                                  mtrCode: finalDestination.MTRcode,
                                },
                              ]
                            : []
                        }
                      />
                    </div>
                  )}

                  <div className="flex-column">
                    <h4>{t("label-type-isCarrier")}</h4>
                    <div className="flex-row radio-types">
                      <div className="flex-row">
                        <label
                          style={{ margin: "10px 0px" }}
                          htmlFor="carrier-radio"
                        >
                          <input
                            disabled
                            type="radio"
                            id="carrier-radio"
                            value="true"
                            onChange={(e) =>
                              handleRadioOperationCarrierChange(true)
                            }
                            checked={operationCarrier === true}
                          />
                          {t("label-contracted-company")}
                        </label>
                      </div>
                      <div className="flex-row">
                        <label
                          style={{ margin: "10px" }}
                          htmlFor="other-carrier"
                        >
                          <input
                            disabled
                            type="radio"
                            id="other-carrier"
                            value="false"
                            onChange={() =>
                              handleRadioOperationCarrierChange(false)
                            }
                            checked={operationCarrier === false}
                          />
                          {t("label-other")}
                        </label>
                      </div>
                    </div>
                  </div>

                  {!operationCarrier && (
                    <div>
                      <ScrapDealerInputAutocomplete
                        isDisabled
                        isSingleSelect
                        onChange={(data) => {
                          const scrapDealerInfo = {
                            id: data[0].id,
                            name: data[0].legalName,
                            MTRcode: data[0]?.mtrCode || "",
                            nationalRegister: data[0].nationalRegister,
                          };
                          setValue("scrapInfo.carrier", scrapDealerInfo);
                          trigger("scrapInfo.carrier");
                        }}
                        placeholder={t("label-type-isCarrier")}
                        defaultValue={
                          carrier
                            ? [
                                {
                                  legalName: carrier.name,
                                  mtrCode: carrier.MTRcode,
                                },
                              ]
                            : []
                        }
                      />
                    </div>
                  )}
                  <div className="flex-column">
                    <h4>{t("label-temporary-storage")}</h4>
                    <div className="flex-row radio-types">
                      <div className="flex-row">
                        <label
                          style={{ margin: "10px 0px" }}
                          htmlFor="temporary-storage-radio"
                        >
                          <input
                            disabled
                            type="radio"
                            id="temporary-storage-radio"
                            value="true"
                            onChange={(e) =>
                              handleRadioOperationTempStorageChange(true)
                            }
                            checked={operationTemporaryStorage === true}
                          />
                          {t("label-contracted-company")}
                        </label>
                      </div>
                      <div className="flex-row">
                        <label
                          style={{ margin: "10px" }}
                          htmlFor="other-temporary-storage"
                        >
                          <input
                            disabled
                            type="radio"
                            id="other-temporary-storage"
                            value="false"
                            onChange={() =>
                              handleRadioOperationTempStorageChange(false)
                            }
                            checked={operationTemporaryStorage === false}
                          />
                          {t("label-other")}
                        </label>
                      </div>
                    </div>
                  </div>

                  {!operationTemporaryStorage && (
                    <div>
                      <ScrapDealerInputAutocomplete
                        isDisabled
                        isSingleSelect
                        onChange={(data) => {
                          const scrapDealerInfo = {
                            id: data[0].id,
                            name: data[0].legalName,
                            MTRcode: data[0]?.mtrCode || "",
                            nationalRegister: data[0].nationalRegister,
                          };
                          setValue("scrapInfo.tempDealer", scrapDealerInfo);
                          trigger("scrapInfo.tempDealer");
                        }}
                        placeholder={t("label-temporary-storage")}
                        defaultValue={
                          tempDealer
                            ? [
                                {
                                  legalName: tempDealer.name,
                                  mtrCode: tempDealer.MTRcode,
                                },
                              ]
                            : []
                        }
                      />
                    </div>
                  )}
                </CollapsibleSection>
              )}

            <CollapsibleSection title={t("label-others")}>
              <div className="form-fields flex-column">
                <div className="flex-column">
                  <label htmlFor="observations">
                    {t("label-general-observations")}
                  </label>
                  <textarea
                    placeholder={t("placeholder.observations")}
                    id="observations"
                    {...register("observations.observations")}
                  />
                </div>
              </div>
            </CollapsibleSection>

            <div className="flex-row buttons-section">
              <button
                disabled={
                  !scrapId ||
                  scrapAdmin?.scrapInfo.status == StatusEnum.CONTRACTED ||
                  scrapAdmin?.scrapInfo.status == StatusEnum.OPERATING ||
                  scrapAdmin?.scrapInfo.status == StatusEnum.FINISHED ||
                  scrapAdmin?.scrapInfo.status == StatusEnum.CANCELED
                }
                className="neutral-white"
                onClick={() => setConfirmationModalOpen(true)}
                type="button"
              >
                {t("label-cancel")}
              </button>
              <button
                className="neutral-white"
                onClick={() => navigate(`/generator-scraps/`)}
                type="button"
              >
                {t("label-back")}
              </button>
              <button
                disabled={
                  scrapAdmin != undefined &&
                  scrapAdmin?.scrapInfo.status != StatusEnum.TO_DEAL
                }
                className="blue"
                type="submit"
              >
                {t("label-save")}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapGeneratorScrapsForm;
