import React, { useState, useEffect, useCallback } from "react";
import { CurrencyInput } from "react-currency-mask";
import { t } from "i18next";
import UserInputAutocomplete from "../../../../../../../components/UsersInputAutocomplete/ScrapDealersIInputAutocomplete";
import { addNewApprover, updateApprover } from "../scrap-generator-approve-roll-service";

import { UserInfoDTO } from "../../../../../../settings/components/users/models/users-DTO";
import { useAlert } from "../../../../../../../contexts/alert-context";
import { ApproveRollDTO } from "../scrap-generator-approve-rollDTO";
import { SparkIcon, SparkTableBody, SparkTableCell, SparkTableRow } from "@bosch-web-dds/spark-ui-react";
import { extractFileNameFromUrl } from "../../../../../../../services/string-treatment";
import { useForm } from "react-hook-form";
import { CountryConfigDTO } from "../../../../../../settings/components/Country-config/country-config-DTO";
import UploadFileOrImage from "../../../../../../../components/UploadFileAndImagesComponent/UploadFileOrImagesComponent";

const AddNewApproverModal: React.FC<{
  onClose: () => void;
  countryConfigEntity: CountryConfigDTO;
  id: string;
  filteredData: any[];
  edit?: ApproveRollDTO;
  clearEdit: () => void;
}> = ({ filteredData, onClose, countryConfigEntity, edit, id, clearEdit }) => {
  const [user, setUser] = useState<UserInfoDTO | undefined>();
  const { register, watch, setValue } = useForm();
  const [value, setInputValue] = useState<number | undefined>();
  const { setAlert } = useAlert();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [savedFileUrls, setSavedFileUrls] = useState<string[]>([]); 


 

    const [removedFiles, setRemovedFiles] = useState<string[]>([]);
  
    const handleImageChange = useCallback(
      (files: File[], removedFiles: string[]) => {
        setSelectedFiles(files);
        if (removedFiles.length > 0) {
          setRemovedFiles(removedFiles);
        }
      },
      []
    );

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files && files.length > 0) {
        const newFiles = Array.from(files);
    
        const nonDuplicateFiles = newFiles.filter(file => !savedFileUrls.some(url => extractFileNameFromUrl(url) === file.name));
  
        setSelectedFiles((prevFiles) => [...prevFiles, ...nonDuplicateFiles]);
      }
    };


  const uploadFilesToServer = async (files: File[]): Promise<string[]> => {
    const fileUrls: string[] = [];

    for (const file of files) {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch("/upload-endpoint", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        fileUrls.push(data.fileUrl);
      }
    }

    return fileUrls;
  };


  useEffect(() => {
    if (edit && edit.fileUrls) {
      setSavedFileUrls(edit.fileUrls); 
    }
  }, [edit]);

  const handleSave = async () => {
    if (user && value !== undefined) {
      try {
      
        const newFileUrls = await uploadFilesToServer(selectedFiles);

       
        const combinedFileUrls = Array.from(new Set([...savedFileUrls, ...newFileUrls])); 

        if (edit) {
          if (edit.id) {
            await updateApprover(
              { ...edit, approveValue: value, fileUrls: combinedFileUrls }, 
              edit.id,
              selectedFiles,
              removedFiles
            );
          } else {
            setAlert({
              text: `${t("alert-error-registered")}: ${t("error-missing-id")}`,
              type: "error",
            });
            return;
          }
        } else {
          await addNewApprover(
            {
              approveValue: value,
              currency: countryConfigEntity,
              user: user,
              originId: user.originId,
              fileUrls: newFileUrls,
            },
            selectedFiles, removedFiles
          );
        }

        setAlert({
          text: t("alert-successfully-registered"),
          type: "success",
        });
        setTimeout(() => {
          clearEdit();
          onClose();
        }, 1000);
      } catch {
        setAlert({
          text: `${t("alert-error-registered")}:`,
          type: "error",
        });
      }
    } else {
      setAlert({
        text: `${t("alert-error-registered")}:`,
        type: "error",
      });
    }
  };

  return (
    <div className="modal-content">
      {!edit ? (
        <>
          <div>
            <UserInputAutocomplete
              id={id}
              dontShow={filteredData}
              placeholder={t("label-users")}
              onChange={(user: UserInfoDTO) => {
                setUser(user);
              }}
            />
          </div>

          <div className="flex-column" style={{ gap: "6px", marginBottom: "1rem", marginTop: "1rem" }}>
            <label htmlFor="">{t("label-approver-value")}</label>
            <CurrencyInput
              locale={countryConfigEntity.locale}
              currency={countryConfigEntity.currency}
              onChangeValue={(event, originalValue, maskedValue) => {
                setInputValue(parseFloat(`${originalValue}`));
              }}
            />
          </div>

          <div className="flex-column margin-topbottom-1">
            <div className="flex-row justify-between">
              <label>{t("label-documents")}</label>
            </div>
            <input
              style={{ width: "96.5%" }}
              type="file"
              multiple
              onChange={handleFileChange}
            />

            {selectedFiles?.length > 0 && (
              <div>
                {selectedFiles.map((file: File, index: number) => (
                  <label key={index}>{file.name}</label>
                ))}
              </div>
            )}
          </div>

          <div className="flex-row buttons-section">
            <button
              className="neutral-white"
              onClick={onClose}
              type="button"
              style={{ marginRight: "1rem" }}
            >
              {t("label-back")}
            </button>

            <button
              className="blue"
              type="button"
              onClick={handleSave}
            >
              {t("label-save")}
            </button>
          </div>
        </>
      ) : (
        <div className="flex-column" style={{ width: "100%" }}>
          <div className="flex-column" style={{ gap: 6 }}>
            <h4 style={{ margin: 0 }}>{t("label-user")}</h4>
            <input disabled={true} type="text" value={edit.user.name} />
          </div>

          <div className="flex-column" style={{ marginTop: "1rem", gap: 6 }}>
            <h4 style={{ margin: 0 }}>{t("label-email")}</h4>
            <input disabled={true} type="text" value={edit.user.email} />
          </div>
          <div className="flex-column" style={{ gap: "6px", marginBottom: "1rem", marginTop: "1rem" }}>
            <label htmlFor="">{t("label-approver-value")}</label>
            <CurrencyInput
              value={edit.approveValue}
              defaultValue={edit.approveValue}
              locale={countryConfigEntity.locale}
              currency={countryConfigEntity.currency}
              onChangeValue={(event, originalValue, maskedValue) => {
                setInputValue(parseFloat(`${originalValue}`));
              }}
            />
          </div>

          <div className="flex-column margin-topbottom-1">

          <div className="flex-column">
            
            <UploadFileOrImage
              onlyImages={false}
              onSave={(itens, removedFiles) =>
                handleImageChange(itens, removedFiles)
              }
              initialSavedFiles={savedFileUrls || []}
              
            />
           
          </div>
         
          </div>

          <div className="flex-row buttons-section">
            <button
              className="neutral-white"
              onClick={() => {
                clearEdit();
                onClose();
              }}
              type="button"
              style={{ marginRight: "1rem" }}
            >
              {t("label-back")}
            </button>
   
            <button
            className="blue"
            type="button"
            onClick={async () => {
              try {
                const newFileUrls = await uploadFilesToServer(selectedFiles);
                const combinedFileUrls = Array.from(new Set([...savedFileUrls, ...newFileUrls]));

                if (edit.id) {
                  await updateApprover(
                    { ...edit, approveValue: value || edit.approveValue, fileUrls: combinedFileUrls },
                    edit.id,
                    selectedFiles,
                    removedFiles
                  );
                  setAlert({
                    text: t("alert-successfully-updated"),
                    type: "success",
                  });

                  setTimeout(() => {
                    clearEdit();
                    onClose();
                    
                    window.location.reload();
                  }, 2000); 
                } else {
                  setAlert({
                    text: t("alert-error-update"),
                    type: "error",
                  });
                }
              } catch {
                setAlert({
                  text: t("alert-error-update"),
                  type: "error",
                });
              }
            }}
          >
            {t("label-save")}
          </button>

          </div>
        </div>
      )}
    </div>
  );
};

export default AddNewApproverModal;
