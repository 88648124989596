import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../contexts/auth-context";
import { SparkHeader } from "@bosch-web-dds/spark-ui-react";
import navigation from "../../assets/config/navigation.json";
import { useTranslation } from "react-i18next";
import { onAuthStateChanged } from "firebase/auth";
import { auth, firestore } from "../../utils/firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import adminNavItems from "./../../assets/config/navigation-menu-admin.json";
import scrapDealerNavItems from "./../../assets/config/navigation-menu-scrap-dealer.json";
import scrapGeneratorNavItems from "./../../assets/config/navigation-menu-scrap-generator.json";
import { useNavigate, useLocation } from "react-router-dom";
import { signOutFirebase } from "../../services/auth";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import LanguagePicker from "../LanguagePicker/LanguagePicker";
import boschLogoImg from "./../../assets/img/Bosch_symbol_logo_black_red.svg";
import "./Header.css";
import { profileENUM } from "../../assets/enums/profiles-enum";

interface NavigationItem {
  label: string;
  icon: string;
  link: string;
  roles: string[];
}

function singOut(
  navigate: (route: string) => void,
  setState: (value: "login" | "logout") => void
) {
  signOutFirebase();
  setState("login");
  navigate("/");
}

function Header() {
  const { t } = useTranslation();
  const [userRole, setUserRole] = useState("public");
  const navigate = useNavigate();
  const location = useLocation();
  const [navigationMenuItems, setNavigationMenuItems] = useState<any>();
  const [filteredNavItems, setFilteredNavItems] = useState<any[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [actualSessionType, setActualSessionType] = useState<
    "login" | "logout"
  >("login");
  const sessionOptions = {
    logout: {
      label: "logout",
      icon: "login",
      action: () => singOut(navigate, setActualSessionType),
    },
    login: {
      label: "login",
      icon: "login",
      action: () => {
        navigate("/login");
      },
    },
  };

  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setActualSessionType("logout");
        try {
          const userRef = doc(firestore, COLLECTIONS.USERS, user.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            updateDoc(userRef, {
              lastAccess: new Date().toLocaleString(),
            });
            const userProfile = userDoc.data();
            setUserRole(userProfile.profile);
            if (userProfile.profile === profileENUM.ADMIN_BOSCH) {
              setNavigationMenuItems(adminNavItems);
            } else if (userProfile.profile === "scrap-dealer") {
              setNavigationMenuItems(scrapDealerNavItems);
            } else if (userProfile.profile === profileENUM.GENERATOR) {
              setNavigationMenuItems(scrapGeneratorNavItems);
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setNavigationMenuItems(null);
      }
    });
    setFilteredNavItems(
      navigation
        .filter((item: NavigationItem) => item.roles.includes(userRole))
        .map((item: NavigationItem) => {
          return {
            label: t(`navigation.${item.label}`),
            icon: item.icon as any,
            link: item.link,
          };
        })
    );
  }, [userRole]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  const [hovered, setHovered] = useState("")

  return (
    <div>
      <div className="header-heigth-limiter">
        <div style={{ width: "calc(100%)" }}>
          <SparkHeader nav={filteredNavItems} search-bar="false" />
        </div>
        <div className="header-first-custom-header-label ">
          <LanguagePicker></LanguagePicker>
        </div>
        <div
          style={{
            width: "auto",
            display: "flex",
            alignItems: "center",
            height: "6rem",
            marginTop: "-42px",
          }}
          onClick={sessionOptions[actualSessionType].action}
        >
          <div className="header-content-wrapper menu-option-to-hover"
            onMouseEnter={() => setHovered("login")}
            onMouseLeave={() => setHovered("")}
          >
            <SparkIcon icName="login"
              pallete={hovered == "login" ? "secondary" : undefined}
            />
            <div className="header-session-text-area">
              {t(`navigation.${sessionOptions[actualSessionType].label}`)}
            </div>
          </div>

        </div>
      </div>
      <div
        className={`overlay ${isMenuOpen ? "active" : ""}`}
        onClick={toggleMenu}
      ></div>
      <div
        style={{ margin: "0px 0px 35px 0px", padding: "0px 15px 0px 15px" }}
        className={`menu ${isMenuOpen ? "active" : ""}`}
      >
        <div className={`menu-items`} style={{ gap: 20 }} ref={menuRef}>
          {isMenuOpen && (
            <div className="logo">
              <img style={{ width: "60%", marginBottom: "1rem" }} src={boschLogoImg} alt="Logo" />
            </div>
          )}
          {navigationMenuItems &&
            navigationMenuItems.map((item: any, index: any) => (
              <div key={index}>
                <label
                  className={`nav-item-label ${location.pathname.includes(item.route)
                    ? "nav-item-label-active"
                    : ""
                    }`}
                  onClick={() => {
                    navigate(item.route);
                    setIsMenuOpen(false);
                  }}
                >
                  {t(`label-${item.label}`)}
                </label>
              </div>
            ))}
        </div>
        {navigationMenuItems && (
          <div className="menu-toggle" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
        <div></div>
        <strong style={{ fontWeight: "800" }}>
          Bosch Scrap Management Solution
        </strong>
      </div>
    </div>
  );
}

export default Header;
