import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect, useCallback } from "react";
import { useAlert } from "../../../contexts/alert-context";

import InputMask from "react-input-mask";
import { DocumentsDTO } from "../documents-DTO";
import { addNewDocument, getDocumentById } from "../documents-service";
import Expiration from "../../../components/Expiration/Expiration";
import { ExpirationDTO } from "../../../components/Expiration/models/ExpirationDTO";
import { SparkIcon, SparkTableBody, SparkTableCell, SparkTableRow } from "@bosch-web-dds/spark-ui-react";
import { extractSubstringFile } from "../../../services/string-treatment";
import { or } from "firebase/firestore";
import UploadFileOrImage from "../../../components/UploadFileAndImagesComponent/UploadFileOrImagesComponent";

interface ModalProps {
  onClose: () => void;
  documentId: string;
}

const DocumentForm: React.FC<ModalProps> = ({ onClose, documentId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [document, setDocument] = useState<DocumentsDTO | null>(null);
  const phoneRef = useRef(null);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const location = useLocation();
  const [expiration, setExpiration] = useState<ExpirationDTO>({
    originId: "",
    docName: "Documents",
    expirationDate: "",
    warningPeriod: 0,
    solved: false,
    hasExpirationDate: false,
    origin: ""
  });

  const handleDataChange = (key: string, value: any) => {
    setExpiration((prevData: ExpirationDTO) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<DocumentsDTO>();

  const [removedFiles, setRemovedFiles] = useState<string[]>([]);

  const handleImageChange = useCallback(
    (files: File[], removedFiles: string[]) => {
      setSelectedImages(files);
      if (removedFiles.length > 0) {
        setRemovedFiles(removedFiles);
      }
    },
    []
  );


  useEffect(() => {
    if (documentId) {
      getDocumentById(documentId).then((data) => {
        if (data) {
          setDocument(data);
          setExpiration({ ...data.documentsInfoDTO.expirationDocument})

          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof DocumentsDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof DocumentsDTO, value as any);
            }
          });
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async (document: DocumentsDTO) => {
  
    try {
      if (id) {
        if (expiration.hasExpirationDate) {
          if (expiration.expirationDate.length === 0) {
            return setAlert({ text: `${t("alert-error-date-empty")}`, type: "error" });
          } else {
            
            document.documentsInfoDTO.expirationDocument = expiration;
            document.documentsInfoDTO.expirationDocument.docName = document.documentsInfoDTO.name;
            document.documentsInfoDTO.expirationDocument.originId = id;
          }
        }
        await addNewDocument(document, id, selectedImages, location.pathname.includes("scrap-dealers-details"),removedFiles);
        setAlert({ text: t("alert-successfully-registered"), type: "success" });
        onClose();
      }
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
    }
  };
  

  return (
    <div className="modal-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields">
        <div className="flex-column">
          <h2>{t("label-documents")}</h2>

          <div className="flex-column">
            <label htmlFor="legalName">{`${t("label-name")}*`}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="legalName"
              {...register("documentsInfoDTO.name", { required: true })}
            />
            {errors.documentsInfoDTO?.name && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="legalName">{`${t("label-description")}*`}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="legalName"
              {...register("documentsInfoDTO.description", { required: true })}
            />
            {errors.documentsInfoDTO?.description && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

     


        </div>
        <br />

{
          (!documentId || expiration.originId != "") && <Expiration
            dto={expiration} 
            dataHandler={handleDataChange}
            expirationDate={"expirationDate"}
            warningPeriod={"warningPeriod"}
            hasExpirationDate={"hasExpirationDate"}
            hasExpirationDateInit={expiration.hasExpirationDate || false}
            expirationDateInit={expiration.expirationDate || ""}
            warningPeriodInit={expiration.warningPeriod || 60}
          />
        }

<br />
        <hr />
        <br />
          

            <div className="flex-column">
            
              <UploadFileOrImage
                onlyImages={false}
                onSave={(itens, removedFiles) =>
                  handleImageChange(itens, removedFiles)
                }
                initialSavedFiles={document?.documentsInfoDTO?.files || []}
                
              />
             
            </div>
      

        

        <div className="flex-column margin-topbottom-1">
          
            <label htmlFor="observations">
              {t("label-general-observations")}
            </label>
            <textarea
              placeholder={t("placeholder.observations")}
              id="observations"
              {...register("observations.observations")}
            />
          
        </div>

        <br />
        <hr />

        <div className="flex-row buttons-section">
          <button className="neutral-white" onClick={onClose} type="button">
            {t("label-back")}
          </button>
          <button className="blue" type="submit">
            {t("label-save")}
          </button>
        </div>
        </div>
      </form>
    </div>
  );
};

export default DocumentForm;
