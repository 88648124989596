import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import addIcon from "../../../assets/svgs/add.svg";
import list from "../../../assets/img/list-view.svg";
import card from "../../../assets/img/components.svg";
import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../utils/firebase-config";
import ImportExportCsv from "../../../components/ImportExportCsv/ImportExportCsv";
import EcommerceCard from "../../../components/EcommerceCard/EcommerceCard";
import Slider from "../../../components/Slider/Slider";
import NoData from "../../../components/NoData/NoData";
import { useAuth } from "../../../contexts/auth-context";
import FilterComponent from "../../../components/FIlter/filter";
import { ScrapInfoDTO } from "./components/scrap-form/models/scrap-DTO";
import { colorsCardStatus } from "../../../assets/enums/colors-card-status";
import { receiveNumberFormatWithMilhar } from "../../../services/number-format";
import Tooltip from "../../../components/tooltip/tooltip";
import { getScrapMaterialsByOriginId } from "../scraps-service";
const ScrapAdminn: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [slideData, setSliderData] = useState<string>("");
  const { id, r } = useParams<{ id: string; r: string }>();
  const data = { generatorId: id };
  const [filteredData, setFilteredData] = useState<any[]>([]);
  let timer: NodeJS.Timeout;
  const [tableData, setTableData] = useState<any[]>([]);
  const [sortField, setSortField] = useState<string | null>(null);
  const tableHeader = [
    `${t("label-material")}`,
    `${t("label-quantity")}`,
    `${t("label-scrap-dealer")}`,
    `${t("label-value")}`,
    `${t("label-status")}`,
  ];

  const fetchTableData = async () => {
    if (id) {
      const items = await getScrapMaterialsByOriginId(id);
      setTableData(items);
      setFilteredData(items);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, [id]);

  useEffect(() => {
    if (sortField) {
      handleSortClick(sortField);
    }
  }, [sortField]);

  const handleOpenModal = () => {
    // handle modal opening logic here
  };

  useEffect(() => {
    const delay = 1500;
    timer = setTimeout(() => {
      fetchTableData();
    }, delay);
  }, [r]);

  const handleCloseModal = () => {
    fetchTableData();
  };

  const sortByName = (data: any[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.scrapCategory.name?.toString().toLowerCase() || "";
      const nameB = b.scrapCategory.name?.toString().toLowerCase() || "";
      return nameA.localeCompare(nameB);
    });
  };

  const sortByScrapDealer = (data: any[]) => {
    return [...data].sort((a, b) => {
      const dealerA = a.contractDealerName?.toString().toLowerCase() || "";
      const dealerB = b.contractDealerName?.toString().toLowerCase() || "";
      return dealerA.localeCompare(dealerB);
    });
  };

  const sortByStatus = (data: any[]) => {
    return [...data].sort((a, b) => {
      const generatorA = a.status?.toString().toLowerCase() || "";
      const generatorB = b.status?.toString().toLowerCase() || "";
      return generatorA.localeCompare(generatorB);
    });
  };

  const sortOptions = [
    { label: t("label-material"), sortFunction: sortByName },
    { label: t("label-scrap-dealer"), sortFunction: sortByScrapDealer },
    { label: t("label-status"), sortFunction: sortByStatus },
  ];

  const handleFilterClick = (filteredData: any[]) => {
    setFilteredData(filteredData);
  };

  const handleSortClick = (sortField: string) => {
    const sortOption = sortOptions.find((option) => option.label === sortField);
    if (sortOption) {
      const sortedData = sortOption.sortFunction(filteredData);
      setFilteredData(sortedData);
      setSortField(sortField);
    }
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div style={{ marginBottom: "120px" }}>
        <div className="flex-row" style={{ justifyContent: "space-between" }}>
          <div className="flex-row">
            <button
              className="blue-with-icon button"
              onClick={() =>
                navigate(`/scrap-generators-details/${id}/scraps-form`)
              }
            >
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>

            <ImportExportCsv
              exportApi="exportscrapbygeneratorscsv"
              importApi="importscrapbygeneratorscsv"
              data={data}
              onClose={handleCloseModal}
            />
          </div>
          <FilterComponent
            data={tableData}
            onFilterClick={handleFilterClick}
            sortOptions={sortOptions}
          />

          <Slider
            options={[
              { display: list, value: "list", viewType: "image" },
              { display: card, value: "card", viewType: "image" },
            ]}
            setState={setSliderData}
          ></Slider>
        </div>
        <br />
        <br />
        {tableData.length === 0 && <NoData />}

        {tableData.length > 0 &&
          (slideData === "list" ? (
            <>
              <div className="info-section">
                <SparkTable>
                  <SparkTableHead>
                    <SparkTableRow>
                      <SparkTableCell className="icon-column"></SparkTableCell>
                      {tableHeader.map((header, index) => (
                        <SparkTableCell
                          key={index}
                          onClick={() => handleSortClick(header)}
                        >
                          <strong>{header.toUpperCase()}</strong>
                        </SparkTableCell>
                      ))}
                    </SparkTableRow>
                  </SparkTableHead>

                  <SparkTableBody>
                    {filteredData.map((rowItem: any, rowIndex: any) => (
                      <SparkTableRow
                        key={rowIndex}
                        onClick={() =>
                          navigate(
                            `/scrap-generators-details/${id}/scraps-form/${rowItem.id}`
                          )
                        }
                      >
                        <SparkTableCell className="icon-column"></SparkTableCell>
                        <SparkTableCell>
                          <Tooltip
                            text={
                              rowItem.scrapCategory.isHazardousMaterial
                                ? t("label-hazardous-material")
                                : t("label-non-hazardous-material")
                            }
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {rowItem.scrapCategory.isHazardousMaterial && (
                                <SparkIcon
                                  className="icon-flag"
                                  no-padding={true}
                                  icName={"danger-hazards"}
                                />
                              )}
                              <p
                                style={{
                                  marginLeft: rowItem.scrapCategory
                                    .isHazardousMaterial
                                    ? "24px"
                                    : "5px",
                                }}
                              >
                                {rowItem.scrapCategory.name}
                              </p>
                            </div>
                          </Tooltip>
                        </SparkTableCell>

                        <SparkTableCell>
                          {receiveNumberFormatWithMilhar(
                            rowItem.contractTotalQuantityPreview
                          )}{" "}
                          {t(`unityENUM.${rowItem.unity}`)}
                        </SparkTableCell>
                        <SparkTableCell>
                          {rowItem.contractDealerName}
                        </SparkTableCell>
                        <SparkTableCell>
                          {rowItem.status == "CONTRACTED" ||
                          rowItem.status == "OPERATING"
                            ? rowItem.contractValue
                            : rowItem.targetPrice != ""
                            ? rowItem.targetPrice
                            : ""}
                          /
                          {rowItem.targetPrice != "" || rowItem.contractValue
                            ? t(`unityENUM.${rowItem.unity}`)
                            : ""}
                        </SparkTableCell>
                        <SparkTableCell
                          style={{ color: "var(--bosch-accent-blue)" }}
                        >
                          {t(`statusEnum.${rowItem.status}`)}
                        </SparkTableCell>
                      </SparkTableRow>
                    ))}
                  </SparkTableBody>
                </SparkTable>
              </div>

            </>
          ) : (
            <div className="eccomerce-card-wrapper">
              {filteredData.map((rowItem: any, rowIndex: any) => (
                <EcommerceCard
                  onClick={() =>
                    navigate(`/scrap-generators-details/${id}/scraps-form/${rowItem.id}`)
                  }
                  buttonAction={() => {}}
                  buttontext="Vizualizar"
                  imageUrl={
                    rowItem.images?.length > 0
                      ? rowItem.images
                      : rowItem.scrapCategory.image || "Image not found!"
                  }
                  price={
                    rowItem.status == "CONTRACTED" ||
                    rowItem.status == "OPERATING"
                      ? rowItem.contractValue
                      : rowItem.targetPrice ?? ""
                  }
                  unitPrice={
                    rowItem.targetPrice ? t(`unityENUM.${rowItem.unity}`) : ""
                  }
                  key={rowIndex}
                  customBottom={
                    <div className="flex-row align-centercard" >
                      <Tooltip
                        text={
                          rowItem.scrapCategory.isHazardousMaterial
                            ? t("label-hazardous-material")
                            : t("label-non-hazardous-material")
                        }
                      >
                        <SparkIcon
                          no-padding={true}
                          icName={
                            rowItem.scrapCategory.isHazardousMaterial
                              ? "danger-hazards"
                              : "less-minimize"
                          }
                        />
                      </Tooltip>
                      <span style={{ marginLeft: 15,  whiteSpace: "nowrap",  }}>
                        {rowItem.scrapCategory.isHazardousMaterial
                          ? t("label-hazardous-material")
                          : t("label-non-hazardous-material")}
                      </span>
                      <div
                       style={{
                        backgroundColor: colorsCardStatus[rowItem.status],
                        marginLeft: 20,
                        height: '100%',
                        padding: "0px 10px",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        }}
                      >
                        {t(`statusEnum.${rowItem.status}`)}
                      </div>
                    </div>
                  }
                >
                  <div>
                    <h3>{rowItem.scrapCategory.name}</h3>
                    <p style={{ marginTop: 7 }}>
                      {rowItem.quantity} {t(`unityENUM.${rowItem.unity}`)}/
                      {t(`periodENUM.${rowItem.period}`)}
                    </p>
                    <p style={{ marginTop: 7 }}>
                      {t(`withdrawal`)} {t(`periodENUM.${rowItem.pickUpFrequency}`)}
                    </p>
                    <p style={{ marginTop: 7 }}>
                      {rowItem.operationAddress.city} - {rowItem.operationAddress.countryName}
                    </p>
                    <strong>
                      <p style={{ marginTop: 7 }}>{rowItem.contractDealerName}</p>
                    </strong>
                  </div>
                </EcommerceCard>



              ))}
            </div>
          ))}
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapAdminn;
