import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import { getScrapDealerById } from "../../scrap-dealers-service";
import { ScrapDealerDTO } from "../../scrap-dealer-DTO";
import { SparkTabNavigation } from "@bosch-web-dds/spark-ui-react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth-context";
import { profileENUM } from "../../../../assets/enums/profiles-enum";

const DropdownMenu = ({ show, onClose, options, position }: any) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose(null);
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div
      ref={menuRef}
      className="dropdown-menu"
      style={{ top: position.top, left: position.left }}
    >
      <ul>
        {options.map((option: any, index: any) => (
          <li key={index} onClick={() => onClose(option)}>
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ScrapDealerDetails: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc, loading } = useAuth();
  const profile = userDoc?.profile;
  const [scrapDealer, setScrapDealer] = useState<ScrapDealerDTO | null>(null);
  interface Tab {
    value: string;
    label: string;
  }
  const [tabs] = useState<Tab[]>(
    [
      { value: "register", label: t("label-register") },
      { value: "scraps", label: t("label-waste") },
      { value: "requirement-check", label: t("label-fullfilled-requirements") },
      profile !== profileENUM.GENERATOR && {
        value: "credit-analysis",
        label: t("label-credit-analysis"),
      },
      { value: "others", label: t("label-others") },
    ].filter(Boolean) as Tab[]
  );
  const [tempTabs, setTempTabs] = useState<Tab[]>(tabs);
  const [otherOptions, setOtherOptions] = useState<Tab[]>(
    [
      { value: "vehicles", label: t("label-vehicles") },
      { value: "drivers", label: t("label-drivers") },
      profile !== profileENUM.GENERATOR && {
        value: "collections-dealer",
        label: t("label-handling"),
      },
      profile !== profileENUM.GENERATOR && {
        value: "contacts",
        label: t("label-contacts"),
      },
      { value: "documents", label: t("label-documents") },
      { value: "specialTechnologies", label: t("label-special-technologies") },
      { value: "socialActivities", label: t("label-social-activities") },
      profile !== profileENUM.GENERATOR && {
        value: "branchs",
        label: t("label-branch"),
      },
      profile !== profileENUM.GENERATOR && {
        value: "users",
        label: t("label-users"),
      },
         // profile !== profileENUM.GENERATOR && {
      //   value: "aprovalInstances",
      //   label: t("label-approval-instances"),
      // },
    ].filter(Boolean) as Tab[]
  );
  
  const [selectedTab, setSelectedTab] = useState("register");
  const [previousTab, setPreviousTab] = useState("register");
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const fetchScrapDealer = async () => {
      if (!id) return;
      try {
        const data = await getScrapDealerById(id);
        if (data) {
          setScrapDealer(data);
        } else {
          console.error("Data is undefined");
        }
      } catch (error) {
        console.error("Error fetching scrap dealer:", error);
      }
    };

    fetchScrapDealer();
  }, [id]);

  useEffect(() => {
    const currentPath = location.pathname.split("/").pop();
    const allOptions = [...tempTabs, ...otherOptions];
    const matchedOption = allOptions.find(
      (option) => option.value === currentPath
    );
    if (matchedOption) {
      setSelectedTab(matchedOption.value);
      if (matchedOption.value === "others") {
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
      }
    }
  }, [location]);

  const handleTabChange = (event: Event, data: { value: string }) => {
    const newTab = data.value;

    if (newTab === "others") {
      setPreviousTab(selectedTab);
      const target = (event.target as HTMLElement).getBoundingClientRect();
      setDropdownPosition({
        top: target.bottom + window.scrollY,
        left: target.left + window.scrollX,
      });
      setShowDropdown(true);
    } else {
      setTempTabs([]);
      setTempTabs(tabs);
      setSelectedTab(newTab);
      setShowDropdown(false);
      navigate(newTab);
    }
  };

  const handleCloseDropdown = (
    option: { value: string; label: string } | null
  ) => {
    if (option && option.value != "others") {
      setTempTabs([]);
      setTempTabs(
        tabs
          .filter((tab) => tab.value !== option.value && tab.value !== "others")
          .concat(option, { value: "others", label: t("label-others") })
      );
      setSelectedTab(option.value);
      navigate(option.value);
    } else {
      setSelectedTab(previousTab);
    }
    setShowDropdown(false);
  };

  const filterOtherOptions = (otherOptions: any) => {
    if (scrapDealer?.scrapDealerInfo.isHeadquarter === false) {
      otherOptions = otherOptions.filter(
        (tab: { value: string }) => tab.value !== "branchs"
      );
    }
    return otherOptions;
  };

  if (loading) return null;

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page page-centered">
        <h2>{scrapDealer?.scrapDealerInfo.legalName}</h2>
        <SparkTabNavigation
          items={tempTabs}
          selectedTab={selectedTab}
          whenChange={handleTabChange}
        />
        <Outlet />
        <DropdownMenu
          show={showDropdown}
          onClose={handleCloseDropdown}
          options={filterOtherOptions(otherOptions)}
          position={dropdownPosition}
        />
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapDealerDetails;
