import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SparkIcon, SparkTabNavigation } from "@bosch-web-dds/spark-ui-react";
import CardListScrapDealer from "./components/cardlist-scrap-dealer/cardlist-scrap-dealer";
import ImportExportCsv from "../../components/ImportExportCsv/ImportExportCsv";
import FilterComponent from "../../components/FIlter/filter";
import { useAuth } from "../../contexts/auth-context";
import {
  getBranchsScrapDealersById,
  getScrapDealers,
} from "./scrap-dealers-service";
import { ScrapDealerInfoDTO } from "./scrap-dealer-DTO";
import addIcon from "../../assets/svgs/add.svg";
import CTAInfoComponent from "../../components/CTAInfoComponent/CTAInfoComponent";
import { profileENUM } from "../../assets/enums/profiles-enum";

const ScrapDealers: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const originId = userDoc?.originId;
  const { id } = useParams();
  const [filteredData, setFilteredData] = useState<ScrapDealerInfoDTO[]>([]);
  const [scrapDealers, setScrapDealers] = useState<ScrapDealerInfoDTO[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>("1");

  const handleFilterClick = (filteredData: ScrapDealerInfoDTO[]) => {
    setFilteredData(filteredData);
  };

  const handleSortClick = () => {};

  const sortByRegion = (data: ScrapDealerInfoDTO[]) => {
    return [...data].sort((a, b) => {
      if (a.region < b.region) return -1;
      if (a.region > b.region) return 1;
      return 0;
    });
  };

  const sortByLegalName = (data: ScrapDealerInfoDTO[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.legalName?.toString().toLowerCase() || "";
      const nameB = b.legalName?.toString().toLowerCase() || "";
      return nameA.localeCompare(nameB);
    });
  };

  const sortOptions = [
    { label: t("label-region"), sortFunction: sortByRegion },
    { label: t("label-legal-name"), sortFunction: sortByLegalName },
  ];

  const tabs = [
    { value: "1", label: t("label-list") },
    // { value: "2", label: t("label-map") },
  ];

  const fetchScrapDealers = async () => {
    try {
      let data;
      if(profile == profileENUM.GENERATOR){
      data =  await getScrapDealers(profile || "", originId || "", true);
       setScrapDealers(data);
            setFilteredData(data);
      } else if (profile == profileENUM.ADMIN_BOSCH && id != undefined){
      data =  await getScrapDealers(profile || "", id || "", true);
       setScrapDealers(data);
            setFilteredData(data);
      } else if (profile == profileENUM.ADMIN_BOSCH){
        data = await getScrapDealers(profile || "", originId || "", false);
         setScrapDealers(data);
              setFilteredData(data);
      }
    } catch (error) {
      console.error("Error fetching scrap dealers: ", error);
    }
  };

  useEffect(() => {
    fetchScrapDealers();
  }, []);

  const handleCloseModal = () => {
    fetchScrapDealers();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page">
        <div style={{ marginBottom: 20 }}>
          <CTAInfoComponent>
            <div className="flex-row align-center" style={{ gap: 10 }}>
              <SparkIcon noPadding icName="info-i" pallete="primary" />
              <p>{t("cta-info.dealers-page")}</p>
            </div>
          </CTAInfoComponent>
        </div>
        <div className="flex-row">
          {profile && profile == profileENUM.ADMIN_BOSCH && (
            <button
              className="blue-with-icon button"
              onClick={() => navigate("/scrap-dealers-new")}
            >
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
          )}

          {profile && profile == profileENUM.ADMIN_BOSCH && (
            <ImportExportCsv
              exportApi="exportscrapdealerscsv"
              importApi="importscrapdealerscsv"
              onClose={handleCloseModal}
            />
          )}

          <FilterComponent
            data={scrapDealers}
            onFilterClick={handleFilterClick}
            sortOptions={sortOptions}
          />
        </div>

        <SparkTabNavigation
          items={JSON.stringify(tabs)}
          selectedTab={selectedTab}
        ></SparkTabNavigation>
        
        <CardListScrapDealer scrapDealers={filteredData} />
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapDealers;
