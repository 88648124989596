import React, { useEffect, useRef, useState } from "react";
import "../../styles/privacy-notice.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/auth-context";
import {
  addOrEditProspectingGenerator,
  getProspectingGeneratorById,
} from "../scrap-generators/scrap-generator-service";
import {
  ProspectingStatusENUM,
  ScrapProspectingGeneratorsDTO,
} from "../scrap-generators/scrap-generator-prospecting-DTO";
import { useFieldArray, useForm } from "react-hook-form";
import { CurrencyInput } from "react-currency-mask";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import { getCRMSteps } from "../settings/components/CRM/crm-service";
import { CRMFunnelDTO } from "../settings/components/CRM/crm-dto";
import { getContrastColor } from "../../services/colors-service";
import { formatTimestampDate } from "../../services/date-format";
import { useAlert } from "../../contexts/alert-context";

const ProspectingGenerator: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [prospectingGeneratorDetails, setProspectingGeneratorDetails] =
    useState<ScrapProspectingGeneratorsDTO>();
  const [currencyProspection, setCurrencyProspection] = useState<string>();
  const [localeProspection, setLocaleProspection] = useState<string>();
  const [CRMSteps, setCRMSteps] = useState<CRMFunnelDTO[]>([]);

  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const stepsRef = useRef<HTMLDivElement>(null);
  const { setAlert } = useAlert();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm<ScrapProspectingGeneratorsDTO>({
    defaultValues: {
      name: "",
      segment: "",
      revenues: "",
      fee: "",
      percentualFee: 0,
      sale: "",
      lead: "",
      contacts: [{ name: "", email: "", role: "" }],
      CRMSteps: CRMSteps,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
  });

  const mergeCRMData = (
    crmSteps: CRMFunnelDTO[],
    prospectingDetails: ScrapProspectingGeneratorsDTO
  ) => {
    const funnelInfoMap: Record<string, CRMFunnelDTO["crmFunnelInfoDTO"]> =
      crmSteps.reduce((map: any, step: any) => {
        map[step.id] = step.crmFunnelInfoDTO;
        return map;
      }, {});

    const updatedSteps = crmSteps.map((step) => {
      const existingStep = prospectingDetails.CRMSteps.find(
        (prospectingStep) => prospectingStep.originId === step.id
      );
      return {
        originId: step.id,
        conclusionDate: existingStep?.conclusionDate || "",
        crmFunnelInfoDTO: funnelInfoMap[step.id],
      };
    });

    setProspectingGeneratorDetails({
      ...prospectingDetails,
      CRMSteps: updatedSteps,
    });
  };

  const onSubmit = async (
    prospectingDetails: ScrapProspectingGeneratorsDTO
  ) => {
    try {
      await addOrEditProspectingGenerator(prospectingDetails, id).then(() => {
        setAlert({ text: t("alert-successfully-registered"), type: "success" });
        navigate(`/scrap-generators`);
      });
    } catch {
      setAlert({ text: t("alert-error-registered"), type: "error" });
    }
  };

  const fetchDataCRMDetails = async () => {
    try {
      const [crmSteps, prospectingData] = await Promise.all([
        getCRMSteps(),
        id ? getProspectingGeneratorById(id) : null,
      ]);

      if (crmSteps) {
        setCRMSteps(crmSteps);
      }

      if (prospectingData) {
        setProspectingGeneratorDetails(prospectingData);
        setCurrencyProspection(prospectingData.currency);
        setLocaleProspection(prospectingData.locale);

        reset({
          name: prospectingData.name,
          segment: prospectingData.segment,
          revenues: prospectingData.revenues,
          fee: prospectingData.fee,
          sale: prospectingData.sale,
          percentualFee: prospectingData.percentualFee,
          lead: prospectingData.lead,
          contacts: prospectingData.contacts || [
            { name: "", email: "", role: "" },
          ],
          CRMSteps: prospectingData.CRMSteps,
          customerInteractions: prospectingData.customerInteractions,
          nextSteps: prospectingData.nextSteps,
        });
      }

      if (crmSteps && prospectingData) {
        mergeCRMData(crmSteps, prospectingData);
      }
    } catch (error) {
      console.error("Erro ao buscar dados iniciais:", error);
    }
  };

  useEffect(() => {
    fetchDataCRMDetails();
  }, [id]);

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        <div className="flex-row justify-between">
          <h3 style={{ margin: "0.5rem 0rem 0.5rem 0rem" }}>
            {t("label-details")}
          </h3>
          <SparkIcon
            icName={
              prospectingGeneratorDetails?.status ==
              ProspectingStatusENUM.CHECKED
                ? "checkmark-frame"
                : "alert-warning"
            }
            pallete={
              prospectingGeneratorDetails?.status ===
              ProspectingStatusENUM.CHECKED
                ? "tertiary"
                : "disabled"
            }
            noPadding
          ></SparkIcon>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-fields">
            <div className="double-fields">
              <div className="flex-column margin-topbottom-1">
                <label htmlFor="company-name">{t("label-company")}</label>
                <input
                  placeholder={t("label-name")}
                  type="text"
                  id="company-name"
                  {...register("name", {
                    required: true,
                  })}
                />
                {errors.name && <span>{t("error-input-required")}</span>}
              </div>

              <div className="flex-column margin-topbottom-1">
                <label htmlFor="segment">{t("label-segment")}</label>
                <input
                  placeholder={t("placeholder.segment")}
                  type="text"
                  id="segment"
                  {...register("segment", {
                    required: true,
                  })}
                />
                {errors.segment && <span>{t("error-input-required")}</span>}
              </div>
            </div>

            <div className="double-fields">
              <div className="flex-column margin-topbottom-1">
                <label htmlFor="revenues">{t("label-revenue")}</label>

                <CurrencyInput
                  currency={currencyProspection}
                  locale={localeProspection}
                  defaultValue={prospectingGeneratorDetails?.revenues || ""}
                  onChangeValue={(event, originalValue, maskedValue) => {
                    setValue("revenues", maskedValue.toString());
                    setValue("originRevenues", Number(originalValue));
                  }}
                  InputElement={
                    <input
                      placeholder={t("label-revenue")}
                      type="text"
                      id="targetPrice"
                      {...register("revenues", {
                        required: false,
                      })}
                    />
                  }
                />
                {errors.revenues && <span>{t("error-input-required")}</span>}
              </div>
              <div className="flex-column margin-topbottom-1">
                <label htmlFor="lead">{t("Lead")}</label>
                <input
                  placeholder={t("Lead")}
                  type="text"
                  id="lead"
                  {...register("lead", {
                    required: true,
                  })}
                />
                {errors.lead && <span>{t("error-input-required")}</span>}
              </div>
            </div>

            <div className="double-fields">
              <div className="flex-column margin-topbottom-1">
                <label htmlFor="fee">
                  {t("S4B FEE")} / {t("year")}
                </label>

                <CurrencyInput
                  currency={currencyProspection}
                  locale={localeProspection}
                  defaultValue={prospectingGeneratorDetails?.fee || ""}
                  onChangeValue={(event, originalValue, maskedValue) => {
                    setValue("fee", maskedValue.toString());
                    setValue("originFee", Number(originalValue));
                  }}
                  InputElement={
                    <input
                      placeholder={`FEE (${currencyProspection})`}
                      type="text"
                      id="fee"
                      {...register("fee", {
                        required: false,
                      })}
                    />
                  }
                />
                {errors.revenues && <span>{t("error-input-required")}</span>}
              </div>

              <div className="flex-column margin-topbottom-1">
                <label htmlFor="percentualFee">{"S4B FEE %"}</label>
                <input
                  placeholder={t("S4B FEE %")}
                  type="number"
                  id="percentualFee"
                  {...register("percentualFee", {
                    required: true,
                  })}
                />
                {errors.percentualFee && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>

              <div className="flex-column margin-topbottom-1">
                <label htmlFor="scrap-sale">
                  {t("label-saleDisposal")} / {t("year")}
                </label>

                <CurrencyInput
                  currency={currencyProspection}
                  locale={localeProspection}
                  defaultValue={prospectingGeneratorDetails?.sale || ""}
                  onChangeValue={(event, originalValue, maskedValue) => {
                    setValue("sale", maskedValue.toString());
                    setValue("originSale", Number(originalValue));
                  }}
                  InputElement={
                    <input
                      placeholder={`${t(
                        "label-sales"
                      )} (${currencyProspection})`}
                      type="text"
                      id="scrap-sale"
                      {...register("sale", {
                        required: false,
                      })}
                    />
                  }
                />
                {errors.revenues && <span>{t("error-input-required")}</span>}
              </div>
            </div>

            <div className="custom-line-hr"></div>

            <div className="flex-column">
              <h3 style={{ margin: "10px 0px 10px 0px" }}>
                {t("label-contact")}
              </h3>
              {fields.map((item, index) => (
                <div
                  className="contact-row double-fields margin-topbottom-1"
                  key={item.id}
                >
                  <div className="flex-column">
                    <label htmlFor={`contacts[${index}].name`}>
                      {t("label-name")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("label-name")}
                      id={`contacts[${index}].name`}
                      {...register(`contacts.${index}.name`, {
                        required: true,
                      })}
                    />
                    {errors.contacts?.[index]?.name && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>
                  <div className="flex-column">
                    <label htmlFor={`contacts[${index}].email`}>
                      {t("label-email")}
                    </label>
                    <input
                      type="email"
                      placeholder={t("placeholder.email")}
                      id={`contacts[${index}].email`}
                      {...register(`contacts.${index}.email`, {
                        required: true,
                      })}
                    />
                    {errors.contacts?.[index]?.email && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>
                  <div className="flex-column">
                    <label htmlFor={`contacts[${index}].role`}>
                      {t("label-function")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("label-function")}
                      id={`contacts[${index}].role`}
                      {...register(`contacts.${index}.role`, {
                        required: true,
                      })}
                    />
                    {errors.contacts?.[index]?.role && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>
                  <button
                    type="button"
                    style={{ margin: 0 }}
                    className="neutral-white"
                    disabled={index == 0}
                    onClick={() => remove(index)}
                  >
                    <SparkIcon icName="delete" noPadding></SparkIcon>
                  </button>
                </div>
              ))}
              <button
                type="button"
                style={{ margin: "5px 0px 5px", width: "100%" }}
                onClick={() => append({ name: "", email: "", role: "" })}
              >
                <SparkIcon icName="add"></SparkIcon>
              </button>
            </div>

            <div className="custom-line-hr"></div>

            <div className="flex-column margin-topbottom-1">
              <div className="flex-row justify-between">
                <h3 style={{ margin: "10px 0px 10px 0px" }}>
                  {t("Customer Journey")}
                </h3>
                <button
                  type="button"
                  className="neutral-white"
                  style={{ margin: 0 }}
                  onClick={() => {
                    navigate("/settings/bosch/CRMFunnel");
                  }}
                >
                  {t("label-add-new")}
                </button>
              </div>
              <div className="steps-prospection">
                <div
                  className="flex-row margin-topbottom-1"
                  style={{
                    backgroundColor: "var(--white-color-5)",
                    padding: "0.5rem 2rem 0.5rem 2rem",
                    gap: 10,
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                    scrollbarWidth: "unset",
                  }}
                  ref={stepsRef}
                >
                  {prospectingGeneratorDetails &&
                  prospectingGeneratorDetails?.CRMSteps?.length > 0 ? (
                    prospectingGeneratorDetails.CRMSteps.sort(
                      (a, b) =>
                        (a.crmFunnelInfoDTO?.order || 0) -
                        (b.crmFunnelInfoDTO?.order || 0)
                    ).map((step, index) => (
                      <div
                        key={index}
                        className="flex-column"
                        style={{
                          padding: "10px 20px",
                          backgroundColor: step?.crmFunnelInfoDTO?.color,
                          color: getContrastColor(
                            step?.crmFunnelInfoDTO?.color || "black"
                          ),
                          borderRadius: 5,
                          fontWeight: 600,
                          minWidth: "auto",
                          width: "auto",
                          display: "flex",
                          maxWidth: "100%",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                            margin: "5px 0px 10px 0px",
                          }}
                        >
                          {step?.crmFunnelInfoDTO?.stageName}
                        </p>
                        <input
                          type="hidden"
                          {...register(`CRMSteps.${index}.originId`, {
                            required: true,
                          })}
                          value={step.originId}
                        />
                        <input
                          type="date"
                          {...register(`CRMSteps.${index}.conclusionDate`, {
                            required: false,
                          })}
                          defaultValue={step.conclusionDate || ""}
                        />
                      </div>
                    ))
                  ) : (
                    <p>-</p>
                  )}
                </div>
              </div>
            </div>

            <div className="custom-line-hr"></div>

            <div className="flex-column">
              <div className="flex-row justify-between">
                <h3 style={{ margin: "10px 0px 10px 0px" }}>
                  {t("Customer Interactions")}
                </h3>
                <button
                  type="button"
                  className="blue"
                  style={{ margin: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log("variavel para exibir modal true");
                  }}
                >
                  {t("label-add-new")}
                </button>
              </div>
              {prospectingGeneratorDetails &&
                prospectingGeneratorDetails?.customerInteractions?.length > 0 &&
                prospectingGeneratorDetails?.customerInteractions.map(
                  (item, index) => (
                    <div
                      className="flex-row margin-topbottom-1"
                      style={{
                        backgroundColor: "var(--white-color-5)",
                        gap: 10,
                      }}
                    >
                      <p
                        className="flex-column justify-center align-center"
                        style={{
                          flex: 1,
                          border: "1px solid var(--extra-grey-medium)",
                          padding: "10px",
                        }}
                      >
                        {formatTimestampDate(item.date)}
                      </p>
                      <p
                        className="flex-column justify-center"
                        style={{
                          flex: 3,
                          border: "1px solid var(--extra-grey-medium)",
                          padding: "10px",
                        }}
                      >
                        {item.interaction}
                      </p>
                      <p
                        className="flex-column justify-center align-center"
                        style={{
                          flex: 1,
                          border: "1px solid var(--extra-grey-medium)",
                          padding: "10px",
                        }}
                      >
                        {item.person}
                      </p>
                    </div>
                  )
                )}
            </div>

            <div className="custom-line-hr"></div>

            <div className="flex-column">
              <div className="flex-row justify-between margin-topbottom-1">
                <h3 style={{ margin: "10px 0px 10px 0px" }}>
                  {t("label-next-steps")}
                </h3>
                <button
                  type="button"
                  className="blue"
                  style={{ margin: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log("variavel para exibir modal true");
                  }}
                >
                  {t("label-add-new")}
                </button>
              </div>
              <ul>
                {prospectingGeneratorDetails?.nextSteps.map((step, index) => (
                  <div key={index}>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <input
                        type="checkbox"
                        id={`nextSteps[${index}].checked`}
                        style={{ width: "2rem", marginLeft: 10 }}
                        checked={step.checked}
                        {...register(`nextSteps.${index}.checked`)}
                        onChange={(e) => {
                          const updatedSteps = [
                            ...prospectingGeneratorDetails.nextSteps,
                          ];
                          updatedSteps[index].checked = e.target.checked;
                          setProspectingGeneratorDetails((prev: any) => ({
                            ...prev,
                            nextSteps: updatedSteps,
                          }));
                        }}
                      />
                      <label
                        style={{ width: "100%", height: "100%", padding: 10 }}
                        htmlFor={`nextSteps[${index}].checked`}
                      >
                        {step.step}
                      </label>
                    </li>
                  </div>
                ))}
              </ul>
            </div>

            <div className="flex-row buttons-section">
              <button
                className="neutral-white"
                type="button"
                onClick={() => navigate(`/scrap-generators`)}
              >
                {t("label-back")}
              </button>
              <button className="blue" type="submit">
                {t("label-save")}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ProspectingGenerator;
