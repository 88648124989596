import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { SocialActivityDTO, SocialActivityInfoDTO } from "./social-activities-DTO";
import { saveFiles,removeItemFromArrayField, saveNewAsync, saveNewWithId, update } from "../../../../services/persistence";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { firestore } from "../../../../utils/firebase-config";
import { ObservationsDTO } from "../../scrap-dealer-DTO";
import { FOLDERS } from "../../../../assets/enums/storage-folders";


// add new
export async function addNew(data: SocialActivityDTO, id: string, files: File[],removedFiles: string[]) {
  if (
    data.socialActivityInfo &&
    Array.isArray(data.socialActivityInfo.files)
  ) {
    data.socialActivityInfo.files =
      data.socialActivityInfo.files.filter(
        (image) => !removedFiles.includes(image)
      );
  }
  if (data.id == undefined) {
    data.socialActivityInfo.originId = id;
    saveNewAsync(COLLECTIONS.SOCIAL_ACTIVITY, data.socialActivityInfo).then((docRef) => {
      saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
      if (files.length > 0) {
        saveFiles(
          COLLECTIONS.SOCIAL_ACTIVITY,
          docRef.id,
          FOLDERS.SOCIAL_ACTIVITY,
          files,
          "files"
        );
      }
    });
  } else {
    update(COLLECTIONS.SOCIAL_ACTIVITY, data.socialActivityInfo, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
    if (files != null && files.length > 0) {
      saveFiles(
        COLLECTIONS.SOCIAL_ACTIVITY,
        data.id,
        FOLDERS.SOCIAL_ACTIVITY,
        files,
        "files"
      );
    }
    if (removedFiles != null && removedFiles.length > 0) {
      removedFiles.forEach((fileUrl) => {
        removeItemFromArrayField(
          COLLECTIONS.SOCIAL_ACTIVITY,
          data.socialActivityInfo.id,
          "files",
          fileUrl
        );
      });
    }
  }
}

export async function getSocialActivityByOriginId(id: string): Promise<SocialActivityInfoDTO[]> {
  const socialActivitysCollectionRef = collection(firestore, COLLECTIONS.SOCIAL_ACTIVITY);

  const q = query(socialActivitysCollectionRef, where("originId", "==", id));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
    .data()
    ["socialActivityTitle"].concat(
      " ",
      doc.data()["socialActivity"]
    ),
  })) as unknown as SocialActivityInfoDTO[];
  return items;
}

export const getSocialActivityById = async (
  id: string
): Promise<SocialActivityDTO | null> => {
  const socialActivityInfo = await getSocialActivity(id);
  const observations = await getSocialActivityObservation(id);

  return {
    id,
    socialActivityInfo,
    observations,
  };
};

export async function getSocialActivity(id: string): Promise<SocialActivityInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.SOCIAL_ACTIVITY, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as SocialActivityInfoDTO;
}

export async function getSocialActivityObservation(
  id: string
): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}
