import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";

import list from "../../../assets/img/list-view.svg";
import card from "../../../assets/img/components.svg";
import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../utils/firebase-config";
import ImportExportCsv from "../../../components/ImportExportCsv/ImportExportCsv";
import EcommerceCard from "../../../components/EcommerceCard/EcommerceCard";
import Slider from "../../../components/Slider/Slider";
import NoData from "../../../components/NoData/NoData";
import { useAuth } from "../../../contexts/auth-context";
import FilterComponent from "../../../components/FIlter/filter";
import { colorsCardStatus } from "../../../assets/enums/colors-card-status";
import { COLLECTIONS } from "../../../assets/enums/firebase-colections";
import Tooltip from '../../../components/tooltip/tooltip';
import { profileENUM } from "../../../assets/enums/profiles-enum";
const ScrapAdminDealerView: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const originId = userDoc?.originId;

  const [slideData, setSliderData] = useState<string>("");
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<any[]>([]);
  const tableHeader = [
    `${t("label-material")}`,
    `${t("label-scrap-generator")}`,
    `${t("label-quantity")}`,
    `${t("label-value")}`,
    `${t("label-status")}`,
  ];
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const handleFilterClick = (filteredData: any[]) => {
    setFilteredData(filteredData);
  };

  const sortByName = (data: any[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.scrapCategory.name?.toString().toLowerCase() || '';
      const nameB = b.scrapCategory.name?.toString().toLowerCase() || '';
      return nameA.localeCompare(nameB);
    });
  };

  const sortByScrapGenerator = (data: any[]) => {
    return [...data].sort((a, b) => {
      const generatorA = a.originName?.toString().toLowerCase() || '';
      const generatorB = b.originName?.toString().toLowerCase() || '';
      return generatorA.localeCompare(generatorB);
    });
  };

  const sortByScrapGeneratorstastus = (data: any[]) => {
    return [...data].sort((a, b) => {
      const generatorA = a.status?.toString().toLowerCase() || '';
      const generatorB = b.status?.toString().toLowerCase() || '';
      return generatorA.localeCompare(generatorB);
    });
  };


  const sortOptions = [
    { label: t("label-material"), sortFunction: sortByName },
    { label: t("label-generator"), sortFunction: sortByScrapGenerator },

    { label: t("label-status"), sortFunction: sortByScrapGeneratorstastus },
  ];

  const handleSortClick = (sortFunction: (data: any[]) => any[]) => {
    const sortedData = sortFunction([...filteredData]);
    setFilteredData(sortedData);
  };

  const fetchTableData = async () => {
    let q;
    if (profile == profileENUM.ADMIN_BOSCH) {
      q = query(
        collection(firestore, COLLECTIONS.SCRAP_BY_GENERATOR),
        where("contractDealer", "==", id)
      );

    } else {
      q = query(
        collection(firestore, COLLECTIONS.SCRAP_BY_GENERATOR),
        where("contractDealer", "==", id),
        where("originId", "==", originId),
      );
    }

    const querySnapshot = await getDocs(q);

    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      searchData: doc
        .data()
      ["scrapCategory"]["name"].concat(
        " ",
        doc.data()["scrapCategory"]["description"],
        " ",
        doc.data()["specialDescription"],
        " ",
        doc.data()["originName"],
        " ",
        doc.data()["operationAddress"]["city"],
        " ",
        doc.data()["operationAddress"]["state"]
      ),
    })) as unknown as any[];
    setTableData(items);
    setFilteredData(items);
  };

  useEffect(() => {
    fetchTableData();
  }, [id]);

  const handleCloseModal = () => {
    fetchTableData();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div style={{ marginBottom: "120px" }}>
        <div className="flex-row" style={{ justifyContent: "space-between" }}>
          <div className="flex-row">
            {profile === profileENUM.ADMIN_BOSCH && (
              <ImportExportCsv
                exportApi="exportscrapbygeneratorscsv"
                importApi=""
                onClose={handleCloseModal}
              />
            )}
          </div>
          <FilterComponent
            data={tableData}
            onFilterClick={handleFilterClick}
            sortOptions={sortOptions}

          />

          <Slider
            options={[
              { display: list, value: "list", viewType: "image" },
              { display: card, value: "card", viewType: "image" },
            ]}
            setState={setSliderData}
          ></Slider>
        </div>

        <br />
        <br />
        {tableData.length === 0 && <NoData />}

        {tableData.length > 0 &&
          (slideData === "list" ? (
            <>
              <div className="info-section">
                <SparkTable>
                  <SparkTableHead>
                    <SparkTableRow>
                      <SparkTableCell className="icon-column"></SparkTableCell>
                      {tableHeader.map((header, index) => (
                        <SparkTableCell key={index}>
                          <strong>{header.toUpperCase()}</strong>
                        </SparkTableCell>
                      ))}
                    </SparkTableRow>
                  </SparkTableHead>

                  <SparkTableBody>
                    {filteredData.map((rowItem: any, rowIndex: any) => (
                      <SparkTableRow
                        key={rowIndex}
                        onClick={() =>
                          navigate(
                            `/scrap-generators-details/${rowItem.originId}/scraps-form/${rowItem.id}`
                          )
                        }
                      >
                        <SparkTableCell>
                          <Tooltip
                            text={
                              rowItem.scrapCategory.isHazardousMaterial
                                ? t("label-hazardous-material")
                                : t("label-non-hazardous-material")
                            }
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {rowItem.scrapCategory.isHazardousMaterial && (
                                <SparkIcon
                                  className="icon-flag"
                                  no-padding={true}
                                  icName={"danger-hazards"}
                                />
                              )}
                              <p style={{ marginLeft: rowItem.scrapCategory.isHazardousMaterial ? "24px" : "0px" }}>
                                {rowItem.scrapCategory.name}
                              </p>
                            </div>
                          </Tooltip>
                        </SparkTableCell>
                        <SparkTableCell>{rowItem.originName}</SparkTableCell>
                        <SparkTableCell>
                          {rowItem.quantity} {t(`unityENUM.${rowItem.unity}`)}/{t(`periodENUM.${rowItem.period}`)}
                        </SparkTableCell>
                        <SparkTableCell>{(rowItem.status == "CONTRACTED" || rowItem.status == "OPERATING") ? rowItem.contractValue : rowItem.targetPrice}/{t(`unityENUM.${rowItem.unity}`)}</SparkTableCell>
                        <SparkTableCell style={{ color: "var(--bosch-accent-blue)" }}>
                          {t(`statusEnum.${rowItem.status}`)}
                        </SparkTableCell>
                      </SparkTableRow>
                    ))}
                  </SparkTableBody>
                </SparkTable>
              </div>

            </>
          ) : (
            <div className="eccomerce-card-wrapper">
              {filteredData.map((rowItem: any, rowIndex: any) => (
                <EcommerceCard
                  onClick={() =>
                    navigate(`/scrap-generators-details/${id}/scraps-form/${rowItem.id}`)
                  }
                  buttonAction={() => { }}
                  buttontext="Visualizar"
                  imageUrl={
                    rowItem.images?.length > 0
                      ? rowItem.images
                      : rowItem.scrapCategory.image || "Image not found!"
                  }
                  price={
                    (rowItem.status === "CONTRACTED" || rowItem.status === "OPERATING")
                      ? rowItem.contractValue
                      : rowItem.targetPrice ?? ""
                  }
                  unitPrice={
                    rowItem.targetPrice ? t(`unityENUM.${rowItem.unity}`) : ""
                  }
                  key={rowIndex}
                  customBottom={
                    <div className="flex-row align-centercard">
                      <Tooltip
                        text={
                          rowItem.scrapCategory.isHazardousMaterial
                            ? t("label-hazardous-material")
                            : t("label-non-hazardous-material")
                        }
                      >
                        <SparkIcon
                          no-padding={true}
                          icName={
                            rowItem.scrapCategory.isHazardousMaterial
                              ? "danger-hazards"
                              : "less-minimize"
                          }
                        />
                      </Tooltip>
                      <span style={{ marginLeft: 15,  whiteSpace: "nowrap",  }}>
                        {rowItem.scrapCategory.isHazardousMaterial
                          ? t("label-hazardous-material")
                          : t("label-non-hazardous-material")}
                      </span>
                      <div
                        style={{
                          backgroundColor: colorsCardStatus[rowItem.status],
                          marginLeft: 20,
                          height: '100%',
                          padding: "0px 10px",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {t(`statusEnum.${rowItem.status}`)}
                      </div>
                    </div>
                  }
                >
                  <div>
                    <h3>{rowItem.scrapCategory.name}</h3>
                    <p style={{ marginTop: 7 }}>
                      {rowItem.quantity} {t(`unityENUM.${rowItem.unity}`)}/
                      {t(`periodENUM.${rowItem.period}`)}
                    </p>
                    <p style={{ marginTop: 7 }}>
                      {t(`withdrawal`)} {t(`periodENUM.${rowItem.pickUpFrequency}`)}
                    </p>
                    <p style={{ marginTop: 7 }}>
                      {rowItem.operationAddress.city} - {rowItem.operationAddress.countryName}
                    </p>
                    <strong>
                      <p style={{ marginTop: 7 }}>{rowItem.contractDealerName}</p>
                    </strong>
                  </div>
                </EcommerceCard>

              ))}
            </div>
          ))}
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapAdminDealerView;
