import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { CRMFunnelinfoDTO, CRMFunnelDTO } from "./crm-dto";
import { saveNewAsync, update } from "../../../../services/persistence";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { firestore } from "../../../../utils/firebase-config";

export async function addNewCRMStep(data: CRMFunnelDTO): Promise<CRMFunnelDTO> {
  if (!data.id) {
  
    const docRef = await saveNewAsync(COLLECTIONS.CRM_STEPS, data.crmFunnelInfoDTO);

    return {
      id: docRef.id,
      crmFunnelInfoDTO: data.crmFunnelInfoDTO,
    };
  } else {
    await update(COLLECTIONS.CRM_STEPS, data.crmFunnelInfoDTO, data.id);
    return data;
  }
}




export async function getCRMSteps(): Promise<CRMFunnelDTO[]> {
  try {
    const collectionRef = collection(firestore, COLLECTIONS.CRM_STEPS);
    const q = query(collectionRef, orderBy("order")); 
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      crmFunnelInfoDTO: doc.data() as CRMFunnelinfoDTO,
    }));
  } catch (error) {
    console.error(`Erro ao buscar funis:`, error);
    throw new Error("Erro ao buscar funis");
  }
}




export const getCRMStepById = async (
  id: string
): Promise<CRMFunnelDTO | null> => {
  const crmFunnelInfoDTO = await getCRMStepInfoById(id);

  return {
    id,
    crmFunnelInfoDTO,
  };
};

export async function getCRMStepInfoById(id: string): Promise<CRMFunnelinfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.CRM_STEPS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as CRMFunnelinfoDTO;
}
