import { Doughnut } from "react-chartjs-2";
import ChartEmptyImg from "../../../../assets/img/chart-pie.svg";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { FinancialDTO } from "../../../../pages/financial/financial-DTO";
import { useEffect, useState } from "react";
import { formatCurrencyByLocale } from "../../../../utils/formatting";

ChartJS.register(ArcElement, Tooltip);

interface Props {
  data: FinancialDTO[] | null;
  idTofilter: any;
}

const FinancialDashboardGraphicfee: React.FC<Props> = ({ data, idTofilter }) => {
  const [filteredData, setFilteredData] = useState<number[]>([]);

  useEffect(() => {
  
    let feeTranslation = { d1: 0, d2: 0, d3: 0 };

    if (data) {
   
      if (idTofilter?.label) {
        data = data.filter((obj) =>
          [idTofilter.label].includes(obj.scrapMaterialName)
        );
      }

    
      data.reduce((acc, obj) => {
        if (obj.status === "TO_COLLECT") {
          acc.d1 += obj.valueFee || 0; 
        } else if (obj.status === "TO_RECEIVE") {
          acc.d2 += obj.valueFee || 0; 
        } else if (obj.status === "RECEIVED") {
          acc.d3 += obj.valueFee || 0;
        }
        return acc;
      }, feeTranslation);

      setFilteredData([feeTranslation.d1, feeTranslation.d2, feeTranslation.d3]);
    }
  }, [data, idTofilter]);

  const chartData = {
   
    datasets: [
      {
        data: filteredData,
        backgroundColor: ["#9E2896", "#007BC0", "#18837E"],
        borderWidth: 1,
        hoverOffset: 0,
      },
    ],
  };

  if (!data) {
    return (
      <div className="chart-mock" style={{ minWidth: "300px", width: "100%" }}>
        <img src={ChartEmptyImg} alt="chart-empty" />
      </div>
    );
  }

  return <Doughnut data={chartData} />;
};

export default FinancialDashboardGraphicfee;
