import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect, useCallback } from "react";
import { useAlert } from "../../../../contexts/alert-context";

import { addNewGroup, getScrapGroupById } from "../../scrap-admin-service";
import { ScrapsCategoryGroupDTO } from "../../scrap-category/models/scrap-category-DTO";
import UploadFileOrImage from "../../../../components/UploadFileAndImagesComponent/UploadFileOrImagesComponent";

interface ModalProps {
  onClose: () => void;
  contactId: string;
}

const ScrapGroupForm: React.FC<ModalProps> = ({ onClose, contactId }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [scrapGroup, setScrapGroup] = useState<ScrapsCategoryGroupDTO | null>(
    null
  );
  const phoneRef = useRef(null);


  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ScrapsCategoryGroupDTO>();

  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [removedFiles, setRemovedFiles] = useState<string[]>([]);
  
  
      const handleImageChange = useCallback(
        (files: File[], removedFiles: string[]) => {
          setSelectedImages(files);
          if (removedFiles) {
            setRemovedFiles(removedFiles);
          }
        },
        []
      );



  useEffect(() => {
    if (contactId) {
      getScrapGroupById(contactId).then((data) => {
        if (data) {
          setScrapGroup(data);
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof ScrapsCategoryGroupDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof ScrapsCategoryGroupDTO, value as any);
            }
          });
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async (contact: ScrapsCategoryGroupDTO) => {
    try {
      await addNewGroup(contact,selectedImages,removedFiles);
      setAlert({ text: t("alert-successfully-registered"), type: "success" });
      onClose();
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
    }
  };

  return (
    <div className="modal-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields flex-column">
          <h2>{t("label-scrap-group")}</h2>

          <div className="flex-column">
            <label htmlFor="legalName">{`${t("label-material")}*`}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="legalName"
              {...register("scrapGroup", { required: true })}
            />
            {errors.scrapGroup && <span>{t("error-input-required")}</span>}
          </div>
        </div>
        <br />
       

            <div className="flex-column">
              <UploadFileOrImage
                onlyImages={true}
                onSave={(itens, removedFiles) =>
                  handleImageChange(itens, removedFiles)
                }
                initialSavedFiles={scrapGroup?.images || []}
                fieldLabel={t("label-material-pic")}
              />
            </div>
        <br />

        <div className="flex-row buttons-section">
          <button className="neutral-white" onClick={onClose} type="button">
            {t("label-back")}
          </button>
          <button className="blue" type="submit">
            {t("label-save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ScrapGroupForm;
