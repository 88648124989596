import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { SparkTabNavigation } from "@bosch-web-dds/spark-ui-react";
import { useAuth } from "../../../contexts/auth-context";

const FinancialToCollectTab: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [selectedTab, setSelectedTab] = useState("financial-dashboard");
  const { loading } = useAuth()

  const tabs = [
    { value: "financial-dashboard", label: t("financial-dashboard.title") },
    { value: "financial-to-collect", label: t("financial-dashboard.title-one") },
    { value: "financial-to-receive", label: t("financial-dashboard.title-two") },
    { value: "financial-received", label: t("financial-dashboard.title-three") },
  ];


  const handleTabChange = (event: Event, data: { value: string }) => {
    const newTab = data.value;

    setSelectedTab(newTab);

    navigate(newTab);
  };

  if (loading) return null;

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page">
        <SparkTabNavigation
          items={JSON.stringify(tabs)}
          selectedTab={selectedTab}
          whenChange={handleTabChange}
        />
        <Outlet />

      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default FinancialToCollectTab;
