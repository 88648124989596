import { FinancialDTO } from "../../../../pages/financial/financial-DTO";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SparkTable,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import { formatCurrencyByLocale } from "../../../../utils/formatting";

interface Props {
  data: FinancialDTO[] | null;
  idTofilter: any;
}

const TableDetailsfee: React.FC<Props> = ({ data, idTofilter }) => {
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    let translation = {
      d1: {
        value: 0,
        display: "one",
        color: "collect",
        label: idTofilter?.label || t("all"),
        status: t("financial-dashboard.title-one"),
      },
      d2: {
        value: 0,
        display: "two",
        color: "incoming",
        label: idTofilter?.label || t("all"),
        status: t("financial-dashboard.title-two"),
      },
      d3: {
        value: 0,
        display: "three",
        color: "received",
        label: idTofilter?.label || t("all"),
        status: t("financial-dashboard.title-three"),
      },
    };

    if (data) {
      if (idTofilter?.label) {
        data = data.filter((obj) =>
          [idTofilter.label].includes(obj.scrapMaterialName)
        );
      }

      data.reduce((acc, obj) => {
        if (obj.status === "TO_COLLECT") {
          acc.d1.value += obj.valueFee || 0; // Usa valueFee
        } else if (obj.status === "TO_RECEIVE") {
          acc.d2.value += obj.valueFee || 0; // Usa valueFee
        } else if (obj.status === "RECEIVED") {
          acc.d3.value += obj.valueFee || 0; // Usa valueFee
        }
        return acc;
      }, translation);

      setFilteredData([translation.d1, translation.d2, translation.d3]);
    }
  }, [data, idTofilter]);

  return (
    <SparkTable>
      <SparkTableHead>
        <SparkTableRow>
          <SparkTableCell>{t("financial-dashboard.name")}</SparkTableCell>
          <SparkTableCell>{t("financial-dashboard.value")}</SparkTableCell>
          <SparkTableCell>{t("financial-dashboard.status")}</SparkTableCell>
        </SparkTableRow>
      </SparkTableHead>

      {filteredData.map((obj, index) => (
        <SparkTableRow key={`${obj.label}-${index}`}>
          <SparkTableCell>{obj.label}</SparkTableCell>
          <SparkTableCell>
            {formatCurrencyByLocale(obj.value, "pt-BR", "BRL")}
          </SparkTableCell>
          <SparkTableCell>{obj.status}</SparkTableCell>
        </SparkTableRow>
      ))}
    </SparkTable>
  );
};

export default TableDetailsfee;
