import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import ModalMultBid from "../scrap-admin-generator-view/components/modal-mult-bid/ModalMultBid";
import list from "../../../assets/img/list-view.svg";
import card from "../../../assets/img/components.svg";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../utils/firebase-config";
import ImportExportCsv from "../../../components/ImportExportCsv/ImportExportCsv";
import EcommerceCard from "../../../components/EcommerceCard/EcommerceCard";
import Slider from "../../../components/Slider/Slider";
import NoData from "../../../components/NoData/NoData";
import { useAuth } from "../../../contexts/auth-context";
import FilterComponent from "../../../components/FIlter/filter";
import { colorsCardStatus } from "../../../assets/enums/colors-card-status";
import { DealersInvitedTableDTO } from "../scrap-generator-scraps-view/components/scrap-form/models/dealers-invited-DTO";
import ScrapDealersInvitedModalForm from "../scrap-admin-generator-view/components/scrap-form/components/scrap-dealers-invited-modal";

import ScrapDealersSendMailModal from "../scrap-admin-generator-view/components/scrap-form/components/scraps-dealers-send-mail-modal";
import { getScrapAdminById } from "../scrap-admin-service";
import { receiveNumberFormatWithMilhar } from "../../../services/number-format";
import { transformDateFormat } from "../../../services/date-format";
import Tooltip from '../../../components/tooltip/tooltip';
import { CountryConfigDTO } from "../../settings/components/Country-config/country-config-DTO";

const ScrapAdminScrapsCategoryToDealView: React.FC<{
  enabledUsers: string[];
}> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc, loading, currentUser } = useAuth();
  const profile = userDoc?.profile;
  const [slideData, setSliderData] = useState<string>("");
  const { id, scrapId } = useParams<{ id: string; scrapId: string }>();
  const data = { generatorId: id };
  const [scrapsIDS, setScrapsIDS] = useState<any[]>([]);
  const [showMultEditModal, setShowMultEditModal] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [sortField, setSortField] = useState<string | null>(null);
  const [editTableModalOpen, setEditTableModalOpen] = useState(false);
  const [editTableItem, setEditTableItem] =
    useState<DealersInvitedTableDTO | null>();
  const [idsToSend, setIdsToSend] = useState<any[]>([]);
  const [sendMailModal, setSendMailModal] = useState(false);
  const [countryConfigEntity, setCountryConfigEntity] =
    useState<CountryConfigDTO>();
  const location = useLocation();

  const tableHeader = [
    `${t("label-material")}`,
    `${t("label-scrap-generator")}`,
    `${t("label-quantity")}`,
    `${t("label-value")}`,
    `${t("label-region")}`,
    `${t("material-sales-date")}`,
  ];

  function handlerIDS(e: any, newItem: any) {
    e.stopPropagation();
    setScrapsIDS((prev) =>
      prev.filter((obj) => obj.id == newItem.id).length > 0
        ? prev.filter((obj) => obj.id != newItem.id)
        : [...prev, newItem]
    );
  }

  const fetchTableData = async () => {
    const q = query(
      collection(firestore, "ScrapByGenerator"),
      where("status", "==", "TO_DEAL"),
      where(
        location.pathname.includes("scrap-groups-details")
          ? "scrapCategory.group.id"
          : "scrapCategory.id",
        "==",
        id
      )
    );

    const querySnapshot = await getDocs(q);

    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      searchData: doc
        .data()
      ["scrapCategory"]["name"].concat(
        " ",
        doc.data()["scrapCategory"]["description"],
        " ",
        doc.data()["specialDescription"],
        " ",
        doc.data()["originName"],
        " ",
        doc.data()["operationAddress"]["city"],
        " ",
        doc.data()["operationAddress"]["state"]
      ),
    })) as any[];

    setTableData(items);
    setFilteredData(items);
  };

  useEffect(() => {
    if (scrapId) {
      getScrapAdminById(scrapId).then((data) => {
        if (data) {
          if (data.scrapInfo) {
            if (data.dealersInvitedTable) {
              setTableData(data.dealersInvitedTable.dealersInvitedList);
            }
          }
        }
      });
    }
  }, [scrapId]);

  useEffect(() => {
    fetchTableData();
  }, [id, showMultEditModal]);

  useEffect(() => {
    if (sortField) {
      handleSortClick(sortField);
    }
  }, [sortField, filteredData]);

  const handleFilterClick = (filteredData: any[]) => {
    setFilteredData(filteredData);
  };

  const handleSortClick = (sortField: string) => {
    const sortOption = sortOptions.find((option) => option.label === sortField);
    if (sortOption) {
      const sortedData = sortOption.sortFunction(filteredData);
      setFilteredData(sortedData);
    }
  };

  const handleCloseTableModal = async (
    updatedData?: DealersInvitedTableDTO
  ) => {
    if (updatedData) {
      await setTableData((prevDealers) =>
        prevDealers.map((dealer) =>
          dealer.id === updatedData.id ? updatedData : dealer
        )
      );
    }
    setSendMailModal(false);
    setIdsToSend([]);
    setEditTableItem(null);
    setEditTableModalOpen(false);
  };

  const sortByName = (data: any[]) => {
    return [...data].sort((a, b) => {
      const nameA = (a.scrapCategory?.name || "").toLowerCase();
      const nameB = (b.scrapCategory?.name || "").toLowerCase();
      return nameA.localeCompare(nameB);
    });
  };
  const sortBygenerator = (data: any[]) => {
    return [...data].sort((a, b) => {
      const regionA = (a.originName || "").toLowerCase();
      const regionB = (b.originName || "").toLowerCase();
      return regionA.localeCompare(regionB);
    });
  };

  const sortByRegion = (data: any[]) => {
    return [...data].sort((a, b) => {
      const regionA = (a.operationAddress?.city || "").toLowerCase();
      const regionB = (b.operationAddress?.city || "").toLowerCase();
      return regionA.localeCompare(regionB);
    });
  };

  const sortOptions = [
    { label: t("label-material"), sortFunction: sortByName },
    { label: t("label-scrap-generator"), sortFunction: sortBygenerator },
    { label: t("label-region"), sortFunction: sortByRegion },
  ];

  const handleCloseModal = () => {
    fetchTableData();
  };
  const cellStyle = {
    padding: "5px",

    maxWidth: "180px",

    whiteSpace: "normal",
  };


  if (loading) return null;

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div style={{ marginBottom: "120px" }}>
        {showMultEditModal && (
          <div className="modal-background">
            <ModalMultBid
              id={id || ""}
              scrapsID={scrapsIDS}
              onClose={() => setShowMultEditModal(false)}
            ></ModalMultBid>
          </div>
        )}

        {editTableModalOpen && editTableItem && (
          <div className="modal-background">
            <ScrapDealersInvitedModalForm
              countryConfigEntity={countryConfigEntity}
              tableData={editTableItem}
              onClose={handleCloseTableModal}
              id={scrapId || ""}
            />
          </div>
        )}

        {sendMailModal && (
          <div className="modal-background">
            <ScrapDealersSendMailModal
              onClose={() => {
                setSendMailModal(false);
                setIdsToSend([]);
              }}
              multIDS={idsToSend}
              id={scrapId || ""}
            />
          </div>
        )}

        <div className="flex-row" style={{ justifyContent: "space-between" }}>
          <div className="flex-row">
            <ImportExportCsv
              exportApi="exportscrapbygeneratorscsv"
              importApi=""
              onClose={handleCloseModal}
            />
          </div>
          <FilterComponent
            data={tableData}
            onFilterClick={handleFilterClick}
            sortOptions={sortOptions}
          />

          <Slider
            options={[
              { display: list, value: "list", viewType: "image" },
              { display: card, value: "card", viewType: "image" },
            ]}
            setState={setSliderData}
          />
        </div>

        <br />
        <br />
        {tableData.length === 0 && <NoData />}

        {tableData.length > 0 &&
          (slideData === "list" ? (
            <>
              <div className="info-section">
                <SparkTable>
                  <SparkTableHead>
                    <SparkTableRow>
                      <SparkTableCell className="icon-column"></SparkTableCell>
                      {tableHeader.map((header, index) => (
                        <SparkTableCell key={index}>
                          <strong>{header.toUpperCase()}</strong>
                        </SparkTableCell>
                      ))}
                    </SparkTableRow>
                  </SparkTableHead>

                  <SparkTableBody>
                    {filteredData.map((rowItem: any, rowIndex: any) => (
                      <SparkTableRow
                        key={rowIndex}
                        onClick={() =>
                          navigate(
                            `/scrap-generators-details/${rowItem.originId}/scraps-form/${rowItem.id}`
                          )
                        }
                      >
                        <SparkTableCell className="icon-column-4rem">
                          <input
                            type="checkbox"
                            onChange={(e) => { }}
                            onClick={(e: any) =>
                              handlerIDS(e, {
                                id: rowItem.id,
                                content: rowItem,
                              })
                            }
                          />
                        </SparkTableCell>
                        <SparkTableCell style={{ ...cellStyle, }}>
                          <Tooltip
                            text={
                              rowItem.scrapCategory.isHazardousMaterial
                                ? t("label-hazardous-material")
                                : t("label-non-hazardous-material")
                            }
                          >
                            <div>
                              {rowItem.scrapCategory.isHazardousMaterial && (
                                <div >

                                  <SparkIcon

                                    className="icon-flag"
                                    no-padding={true}
                                    icName={"danger-hazards"}
                                  />
                                </div>
                              )}
                              <p style={{ marginLeft: "25px" }}>
                                {rowItem.scrapCategory.name}
                              </p>
                            </div>
                          </Tooltip>
                        </SparkTableCell>


                        <SparkTableCell style={{ ...cellStyle, }}>{rowItem.originName}</SparkTableCell>
                        <SparkTableCell style={{ ...cellStyle, }}>
                          {receiveNumberFormatWithMilhar(
                            rowItem.contractTotalQuantityPreview
                          )}{" "}
                          {t(`unityENUM.${rowItem.unity}`)}
                        </SparkTableCell>
                        <SparkTableCell style={{ ...cellStyle, }}>
                          {rowItem.targetPrice ?? ""}/
                          {rowItem.targetPrice ? t(`unityENUM.${rowItem.unity}`) : ""}
                        </SparkTableCell>
                        <SparkTableCell style={{ ...cellStyle, }}>
                          {rowItem.operationAddress.city} -{" "}
                          {rowItem.operationAddress.countryName}
                        </SparkTableCell>
                        <SparkTableCell style={{ ...cellStyle, }}>
                          {transformDateFormat(rowItem.targetDate)}
                        </SparkTableCell>

                      </SparkTableRow>
                    ))}
                    <div
                      className="flex-row"
                      style={{ marginTop: "1rem", justifyContent: "flex-end" }}
                    >
                      {scrapsIDS.length > 0 && (
                        <button
                          className="blue"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowMultEditModal(true);
                          }}
                        >
                          {t("add-new-bid")}
                        </button>
                      )}
                    </div>
                  </SparkTableBody>
                </SparkTable>
              </div>


            </>
          ) : (
            <div className="eccomerce-card-wrapper">
              {filteredData.map((rowItem: any, rowIndex: any) => (
                <EcommerceCard
                  onClick={() =>
                    navigate(`/scrap-generators-details/${id}/scraps-form/${rowItem.id}`)
                  }
                  buttonAction={() => { }}
                  buttontext="Visualizar"
                  imageUrl={
                    rowItem.images?.length > 0
                      ? rowItem.images
                      : rowItem.scrapCategory.image || "Image not found!"
                  }
                  price={
                    (rowItem.status === "CONTRACTED" || rowItem.status === "OPERATING")
                      ? rowItem.contractValue
                      : rowItem.targetPrice ?? ""
                  }
                  unitPrice={
                    rowItem.targetPrice ? t(`unityENUM.${rowItem.unity}`) : ""
                  }
                  key={rowIndex}
                  customBottom={
                    <div className="flex-row align-centercard">
                      <Tooltip
                        text={
                          rowItem.scrapCategory.isHazardousMaterial
                            ? t("label-hazardous-material")
                            : t("label-non-hazardous-material")
                        }
                      >
                        <SparkIcon
                          no-padding={true}
                          icName={
                            rowItem.scrapCategory.isHazardousMaterial
                              ? "danger-hazards"
                              : "less-minimize"
                          }
                        />
                      </Tooltip>
                        <span style={{ marginLeft: 15,  whiteSpace: "nowrap",  }}>
                        {rowItem.scrapCategory.isHazardousMaterial
                          ? t("label-hazardous-material")
                          : t("label-non-hazardous-material")}
                      </span>
                      <div
                        style={{
                          backgroundColor: colorsCardStatus[rowItem.status],
                          padding: "0px 10px",
                          height: '100%',
                          marginLeft: 20,
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {t(`statusEnum.${rowItem.status}`)}
                      </div>
                    </div>
                  }
                >
                  <div>
                    <h3>{rowItem.scrapCategory.name}</h3>
                    <p style={{ marginTop: 7 }}>
                      {rowItem.quantity} {t(`unityENUM.${rowItem.unity}`)}/
                      {t(`periodENUM.${rowItem.period}`)}
                    </p>
                    <p style={{ marginTop: 7 }}>
                      {t(`withdrawal`)} {t(`periodENUM.${rowItem.pickUpFrequency}`)}
                    </p>
                    <p style={{ marginTop: 7 }}>
                      {rowItem.operationAddress.city} - {rowItem.operationAddress.countryName}
                    </p>
                    <strong>
                      <p style={{ marginTop: 7 }}>{rowItem.contractDealerName}</p>
                    </strong>
                  </div>
                </EcommerceCard>
              ))}
            </div>
          ))}
      </div>
    );
  } else {
    navigate("/page-not-found");
    return null;
  }
};

export default ScrapAdminScrapsCategoryToDealView;
