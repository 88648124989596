export const colorsCardStatus: { [key: string]: string } = {
    TO_DEAL: "#9e2896",
    AWAITING_BID: "#9e2896",
    IN_BID: "#007bc0",
    BID_COMPLETED: "#00884a",
    CANCELED: "#ed0007",
    FINISHED: "#cd8d00",
    CONTRACTED: "#007bc0",  
    OPERATING: "#00884a",
}
