import React, { useEffect, useState } from "react";
import "../../../scrap.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import genericImage from "../../../../../assets/svgs/material-list.svg";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import ChartMinimalCardView from "../../../../../components/ChartMinimalCardView/ChartMinimalCardView";
import ChartEmptyImg from "./../../../../../assets/img/chart-pie.svg";
import {
  getScrapByGenerator,
  getScrapCategoryTotalbyId,
  sumScrapCategoryFee,
} from "../../../scraps-service";
import { CategoryTotalDTO } from "../../models/scrap-category-total-dto";
import MultiCountryAverageChart from "../../../../../components/ChartLineCountryByYear/ChartLineCountryByYear";
import { ScrapsCategoryInfoDTO } from "../../models/scrap-category-DTO";
import { receiveNumberFormatWithMilhar } from "../../../../../services/number-format";
import { getResizedImage } from "../../../../../services/image-treatment";

interface Props {
  item: ScrapsCategoryInfoDTO;
}

const CardScrapCategory: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [totalDetails, setTotalDetails] = useState<CategoryTotalDTO | null>(
    null
  );
  const [scrapByGeneratorDetails, setScrapByGeneratorDetails] = useState<
    any[] | null
  >(null);
  const [generatorQuantity, setGeneratorQuantity] = useState("0");
  const [dealerQuantity, setDealerQuantity] = useState("0");
  const [fee, setFee] = useState(0);
  const [showLegend, setShowLegend] = useState(false);

  useEffect(() => {
    const fetchTotalDetails = async () => {
      try {
        const result = await getScrapCategoryTotalbyId(item.id);
        setTotalDetails(result);
      } catch (err) {
        console.error("Failed to fetch data");
      }
    };

    const fetchScrapByGenerator = async () => {
      try {
        const result = await getScrapByGenerator(item.id);
        setScrapByGeneratorDetails(result);
      } catch (err) {
        console.error("Failed to fetch data");
      }
    };

    const fetchSumFee = async () => {
      const fee = await sumScrapCategoryFee(item.name);
      const formattedFee = Number(fee.toFixed(2));
      setFee(formattedFee);
    };

    fetchTotalDetails();
    fetchSumFee();
    fetchScrapByGenerator();
  }, [item.id]);

  useEffect(() => {
    if (scrapByGeneratorDetails && scrapByGeneratorDetails?.length > 0) {
      const uniqueScrapByGeneratorDetails = scrapByGeneratorDetails.filter(
        (detail, index, self) =>
          index === self.findIndex((t) => t.originId === detail.originId)
      );

      const uniqueScrapDealer = scrapByGeneratorDetails
        .filter((detail) => detail.contractDealer)
        .filter(
          (detail, index, self) =>
            index ===
            self.findIndex((t) => t.contractDealer === detail.contractDealer)
        );

      setGeneratorQuantity(uniqueScrapByGeneratorDetails.length.toString());
      setDealerQuantity(uniqueScrapDealer.length.toString());
    }
  }, [scrapByGeneratorDetails]);

  const [showMoreCardDetails, setShowMoreCardDetails] =
    useState<boolean>(false);

  const handleShowMoreCardDetails = (event: any) => {
    event.stopPropagation();
    setShowMoreCardDetails(!showMoreCardDetails);
  };

  return (
    <div className="flex-column" style={{ marginBottom: "1rem" }}>
      <div
        className="flex-row card-scrap"
        onClick={() => {
          navigate(`/scrap-categories-details/${item.id}/pannel`);
        }}
      >
        <div
          className="card-scrap-img"
          style={{
            height: "110px",
            width: "130px",
            overflow: "hidden",
            borderRadius: "5px",
          }}
        >
          <img
            src={(item.images && item.images.length > 0 ) ? getResizedImage(item.images[item.images.length-1]) : genericImage}
            alt="material image"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>

        <div
          className="flex-column"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <div className="flex-row align-center justify-between">
            <h3 style={{ margin: 0 }}>{item.name || "-"}</h3>
            <button type="button" onClick={handleShowMoreCardDetails}>
              <SparkIcon
                noPadding
                icName={showMoreCardDetails ? "up" : "down"}
              ></SparkIcon>
            </button>
          </div>
          <div style={{ margin: "5px 0px" }} className="custom-line-hr" />
          <div className="flex-row justify-between card-scrap-basic-info">
            <div
              className="flex-column"
              style={{ gap: "10px", maxWidth: "80%" }}
            >
              <p style={{ wordWrap: "break-word" }}>
                {t("label-material-class")}:{" "}
                <strong>{item.materialClass.name || "-"}</strong>
              </p>
            </div>
            <div
              className="flex-column"
              style={{ gap: "10px", maxWidth: "80%" }}
            >
              <p style={{ wordWrap: "break-word" }}>
                {t("label-physical-state")}:{" "}
                <strong>
                  {" "}
                  {t(`physicalStateENUM.${item.phisycalState.name}`)}
                </strong>
              </p>
            </div>{" "}
            <div
              className="flex-column"
              style={{ gap: "10px", maxWidth: "80%" }}
            >
              <p style={{ wordWrap: "break-word" }}>
                {t("label-ibama-code")}:{" "}
                <strong>{item.ibamaCode || "-"}</strong>
              </p>
            </div>
            <div className="flex-column align-end justify-end">
              <div className="tag tag-green flex-column align-center justify-center">
                <div className="flex-column tag-fee" style={{ gap: "2px" }}>
                  <span style={{ fontSize: "10px" }}>
                    <strong>FEE</strong>
                  </span>
                  <h4>
                    <strong>R$ {fee}</strong>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showMoreCardDetails && (
        <div
          className="flex-row expanded-section-card-scrap justify-between"
          style={{ gap: "10px" }}
        >
          <div
            style={{
              backgroundColor: "#FFF",
              borderRadius: "5px",
              padding: "10px",
              width: "98%",
            }}
            className="flex-column justify-center align-center"
          >
            {totalDetails && (
              <div
                style={{ width: "100%" }}
                className="flex-row justify-between"
              >
                <p>{t("label-average-annual-price")}</p>
                <a onClick={() => setShowLegend((prev) => !prev)}>
                  <SparkIcon icName="list-view" noPadding />
                </a>
              </div>
            )}
            <MultiCountryAverageChart
              data={totalDetails}
              showLegend={showLegend}
            />
          </div>
          <div
            className="flex-row chart-cards"
            style={{ gap: "10px", width: "100%" }}
          >
            <div className="flex-column" style={{ gap: "10px", width: "100%" }}>
              <ChartMinimalCardView
                data={
                  receiveNumberFormatWithMilhar(
                    totalDetails?.years[0].quantityDealed
                  ) || "0"
                }
                title={t("label-quantity-traded")}
              />
              <ChartMinimalCardView
                data={
                  receiveNumberFormatWithMilhar(
                    totalDetails?.years[0].quantityToDeal
                  ) || "0"
                }
                title={t("label-quantity-to-negotiate")}
              />
            </div>
            <div className="flex-column" style={{ gap: "10px", width: "100%" }}>
              <ChartMinimalCardView
                data={generatorQuantity}
                title={t("label-scrap-generators")}
              />
              <ChartMinimalCardView
                data={dealerQuantity}
                title={t("label-scrap-dealers")}
              />
            </div>
          </div>
          <div className="chart-mock">
            <img src={ChartEmptyImg} alt="chat-empty" />
          </div>
        </div>
      )}
    </div>
  );
};

export default CardScrapCategory;
