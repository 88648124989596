import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";

import { useAlert } from "../../../../contexts/alert-context";
import {
  getScrapCategoryById,
  addNewScrapCategory,
} from "../../scraps-service";
import "../../scrap.css";
import { auth, storage } from "../../../../utils/firebase-config";
import { useAuth } from "../../../../contexts/auth-context";
import { onAuthStateChanged } from "firebase/auth";
import { ScrapsCategoryDTO } from "../models/scrap-category-DTO";
import MTRCategoryInputAutocomplete from "../../../../components/MTRCategoryInputAutocomplete/MTRCategoryInputAutocomplete";
import MTRCategoryClassInputAutocomplete from "../../../../components/MTRCategoryClassInputAutocomplete/MTRCategoryClassInputAutocomplete";
import PhisicalStateEnumInputAutocomplete from "../../../../components/PhisicalStateEnumInputAutocomplete/PhisicalStateEnumInputAutocomplete";
import UnitEnumInputAutocomplete from "../../../../components/UnitEnumInputAutocomplete/UnitEnumInputAutocomplete";
import ScrapGroupAutocomplete from "../../../../components/ScrapGroupAutocomplete/ScrapGroupAutocomplete";
import { profileENUM } from "../../../../assets/enums/profiles-enum";
import UploadFileOrImage from "../../../../components/UploadFileAndImagesComponent/UploadFileOrImagesComponent";

const ScrapCategoryForm: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const { generatorId } = useParams<{ generatorId: string }>();
  const { scrapCategoryName } = useParams<{ scrapCategoryName: string }>();
  const { setAlert } = useAlert();
  const [scraps, setScrapCategory] = useState<ScrapsCategoryDTO | null>(null);
  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId("");
      }
    });
    return () => unsubscribe();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    getValues,
  } = useForm<ScrapsCategoryDTO>();

  const [isHazardousMaterial, setIsHazardousMaterial] = useState(
    scraps?.scrapsCategoryInfo?.isHazardousMaterial ?? false
  );


  useEffect(() => {
    console.log(scraps?.scrapsCategoryInfo)
    if (scrapCategoryName) {
      setValue("scrapsCategoryInfo.name", scrapCategoryName);
    }
  }, [scrapCategoryName]);

  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [removedFiles, setRemovedFiles] = useState<string[]>([]);


    const handleImageChange = useCallback(
      (files: File[], removedFiles: string[]) => {
        setSelectedImages(files);
        if (removedFiles) {
          setRemovedFiles(removedFiles);
        }
      },
      []
    );

  useEffect(() => {
    if (id) {
      getScrapCategoryById(id).then((data) => {
        if (data) {
          setScrapCategory(data);
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof ScrapsCategoryDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof ScrapsCategoryDTO, value as any);
            }
          });
          const isHazardousMaterial =
            data.scrapsCategoryInfo.isHazardousMaterial;
          setIsHazardousMaterial(isHazardousMaterial);
          setValue(
            "scrapsCategoryInfo.isHazardousMaterial",
            isHazardousMaterial,
            {
              shouldValidate: true,
            }
          );
         
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async (scraps: ScrapsCategoryDTO) => {

    setValue("scrapsCategoryInfo.isHazardousMaterial", isHazardousMaterial, {
      shouldValidate: true,
    });

    scraps.scrapsCategoryInfo.isHazardousMaterial =
      isHazardousMaterial === true;

    try {
      await addNewScrapCategory(scraps, profile, userId, selectedImages,removedFiles);
      setAlert({ text: t("alert-successfully-registered"), type: "success" });
      if (generatorId) {
        if (profile == profileENUM.ADMIN_BOSCH) {
          navigate(`/scrap-generators-details/${generatorId}/scraps-form`);
        } else {
          navigate(-1);
        }
      } else {
        navigate("/scraps/scraps-category");
      }
    } catch (e: any) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({
        text: `${t("alert-error-registered")}: ${e.message}`,
        type: "error",
      });
    }
  };

  const handleSelect = (value: boolean) => {
    setIsHazardousMaterial(value);
  };

  const handleRadioChange = (event: any) => {
    setIsHazardousMaterial(JSON.parse(event.target.value));
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page-centered">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-fields flex-column">
            <div className="flex-column">
              <label htmlFor="legalName">{`${t("label-scrap-name")}*`}</label>
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="text"
                id="legalName"
                {...register("scrapsCategoryInfo.name", { required: true })}
              />
              {errors.scrapsCategoryInfo?.name && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="label-scrap-group">
                {t("label-scrap-group")}
              </label>
              <ScrapGroupAutocomplete
                initialValue={getValues("scrapsCategoryInfo.group")}
                label=""
                onChange={(data) => setValue("scrapsCategoryInfo.group", data)}
                placeholder=""
              />
            </div>

            <div className="flex-column">
              <label htmlFor="legalName">{t("label-description")}</label>
              <MTRCategoryInputAutocomplete
                initialValue={getValues("scrapsCategoryInfo.description")}
                label=""
                onChange={(data) => {
                  setValue("scrapsCategoryInfo.description", data);
                  setValue("scrapsCategoryInfo.ibamaCode", data.code);
                }}
                placeholder=""
              ></MTRCategoryInputAutocomplete>
            </div>

            <div className="double-fields">
              <div className="flex-column">
                <label htmlFor="legalName">{t("label-material-class")}</label>
                <MTRCategoryClassInputAutocomplete
                  initialValue={getValues("scrapsCategoryInfo.materialClass")}
                  label=""
                  onChange={(data) =>
                    setValue("scrapsCategoryInfo.materialClass", data)
                  }
                  placeholder=""
                ></MTRCategoryClassInputAutocomplete>
              </div>

              <div className="flex-column">
                <label htmlFor="legalName">{t("label-ibama-code")}</label>
                <input
                  disabled
                  placeholder={t("placeholder.generalEmpty")}
                  type="text"
                  id="legalName"
                  {...register("scrapsCategoryInfo.ibamaCode", {
                    required: false,
                  })}
                />
              </div>
            </div>

            <div className="double-fields">
              <div className="flex-column">
                <label htmlFor="legalName">{t("label-physical-state")}</label>
                <PhisicalStateEnumInputAutocomplete
                  initialValue={getValues("scrapsCategoryInfo.phisycalState")}
                  label=""
                  onChange={(data) => {
                    setValue("scrapsCategoryInfo.phisycalState", data);
                  }}
                  placeholder=""
                ></PhisicalStateEnumInputAutocomplete>
              </div>
              <div className="flex-column">
                <label htmlFor="legalName">
                  {t("material-quantity-unity")}
                </label>
                <UnitEnumInputAutocomplete
                  label=""
                  initialValue={getValues("scrapsCategoryInfo.unity")}
                  onChange={(data) =>
                    setValue("scrapsCategoryInfo.unity", data)
                  }
                  placeholder=""
                ></UnitEnumInputAutocomplete>
              </div>
            </div>

            <div className="flex-column">
              <label>{`${t("label-type")}*`}</label>
              <div className="flex-row radio-types">
                <div className="flex-row">
                  <label htmlFor="isHazardousMaterial">
                    <input
                      type="radio"
                      id="isHazardousMaterial"
                      value="false"
                      {...register("scrapsCategoryInfo.isHazardousMaterial", {
                        required: true,
                      })}
                      onChange={handleRadioChange}
                      checked={isHazardousMaterial === false}
                      defaultChecked={
                        scraps?.scrapsCategoryInfo.isHazardousMaterial === false
                      }
                    />
                    {t("label-non-hazardous-material")}
                  </label>
                </div>
                <div className="flex-row">
                  <label htmlFor="isBranch">
                    <input
                      type="radio"
                      id="isBranch"
                      value="true"
                      {...register("scrapsCategoryInfo.isHazardousMaterial", {
                        required: true,
                      })}
                      onChange={handleRadioChange}
                      checked={isHazardousMaterial === true}
                      defaultChecked={
                        scraps?.scrapsCategoryInfo.isHazardousMaterial === true
                      }
                    />
                    {t("label-hazardous-material")}
                  </label>
                </div>
                {errors.scrapsCategoryInfo?.isHazardousMaterial === null &&
                  scraps?.scrapsCategoryInfo.isHazardousMaterial && (
                    <span>{t("error-input-required")}</span>
                  )}
              </div>
            </div>
          </div>

          {isHazardousMaterial && (
            <div className="flex-column">
              <label htmlFor="legalName">{t("label-onu-code")}</label>
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="text"
                id="legalName"
                {...register("scrapsCategoryInfo.onuNumber", {
                  required: false,
                })}
              />
            </div>
          )}
          <br />
          <br />
          
            <div className="flex-column">
              <UploadFileOrImage
                onlyImages={true}
                onSave={(itens, removedFiles) =>
                  handleImageChange(itens, removedFiles)
                }
                initialSavedFiles={scraps?.scrapsCategoryInfo?.images || []}
                fieldLabel={t("label-material-pic")}
              />
            </div>

          <br />
          <hr />
          <br />

          <div className="form-fields flex-column">
            <div className="flex-column">
              <label htmlFor="observations">
                {t("label-general-observations")}
              </label>
              <textarea
                placeholder={t("placeholder.observations")}
                id="observations"
                {...register("observations.observations")}
              />
            </div>
          </div>

          <div className="flex-row buttons-section">
            <button
              className="neutral-white"
              onClick={() =>
                generatorId
                  ? profile === profileENUM.ADMIN_BOSCH
                    ? navigate(
                        `/scrap-generators-details/${generatorId}/scraps-form`
                      )
                    : navigate(-1)
                  : navigate("/scraps/scraps-category")
              }
              type="button"
            >
              {t("label-back")}
            </button>
            <button className="blue" type="submit">
              {t("label-save")}
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapCategoryForm;
