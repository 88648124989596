import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/auth-context";
import FinancialDashboardAdmin from "./componentes/financial-dashboard-admin";
import FinancialDashboard from "../financial-dashboard";
import FinancialDashboardAdminfee from "./componentes/financial-dashboard-admin-fee";
import { profileENUM } from "../../../assets/enums/profiles-enum";


const FinancialDashboardPage: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const navigate = useNavigate();
  const { userDoc, loading } = useAuth();
  const profile = userDoc?.profile;

  if (loading) return null;

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div >
        {profile === profileENUM.ADMIN_BOSCH && (
        <div className="horizontal-flex-container">
        <div className="fixed-div">
          <FinancialDashboardAdmin enabledUsers={[profileENUM.ADMIN_BOSCH]} />
        </div>
        <div className="fixed-div">
          <FinancialDashboardAdminfee enabledUsers={[profileENUM.ADMIN_BOSCH]} />
        </div>
      </div>
      
       
        )}
        {profile === profileENUM.GENERATOR && (
           <div className="horizontal-flex-container">
             <div className="fixed-div">
          <FinancialDashboard enabledUsers={[profileENUM.GENERATOR]} />
          </div>
          </div>
        )}
      </div>
    );
  } else {
    navigate("/page-not-found");
    return null;
  }
};

export default FinancialDashboardPage;
