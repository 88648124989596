import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect, useCallback } from "react";
import { useAlert } from "../../../contexts/alert-context";

import { VehiclesDTO } from "../vehicles-DTO";
import { addNewVehicle, getVehicleById } from "../vehicles-service";
import { SparkIcon, SparkTableBody, SparkTableCell, SparkTableRow } from "@bosch-web-dds/spark-ui-react";
import { extractSubstringFile } from "../../../services/string-treatment";
import UploadFileOrImage from "../../../components/UploadFileAndImagesComponent/UploadFileOrImagesComponent";

interface ModalProps {
  onClose: () => void;
  vehicleId: string;
}

const VehicleForm: React.FC<ModalProps> = ({ onClose, vehicleId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [vehicle, setVehicle] = useState<VehiclesDTO | null>(null);
  const phoneRef = useRef(null);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<VehiclesDTO>();

  const [removedFiles, setRemovedFiles] = useState<string[]>([]);
  
    const handleImageChange = useCallback(
      (files: File[], removedFiles: string[]) => {
        setSelectedImages(files);
        if (removedFiles.length > 0) {
          setRemovedFiles(removedFiles);
        }
      },
      []
    );


  useEffect(() => {
    if (vehicleId) {
      getVehicleById(vehicleId).then((data) => {
        if (data) {
          setVehicle(data);
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof VehiclesDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof VehiclesDTO, value as any);
            }
          });
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async (vehicle: VehiclesDTO) => {
    try {
      if (id) {
        await addNewVehicle(vehicle, id, selectedImages,removedFiles);
        setAlert({ text: t("alert-successfully-registered"), type: "success" });
        onClose();
      }
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
    }
  };

  return (
    <div className="modal-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields flex-column">
          <h2>{t("label-vehicles")}</h2>

          <div className="flex-column">
            <label htmlFor="legalName">{`${t("label-license-plate")}*`}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="legalName"
              {...register("vehiclesInfoDTO.licensePlate", { required: true })}
            />
            {errors.vehiclesInfoDTO?.licensePlate && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

     

          <div className="flex-column">
            <label htmlFor="legalName">{`${t("label-type")}*`}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="legalName"
              {...register("vehiclesInfoDTO.type", {
                required: true,
              })}
            />
            {errors.vehiclesInfoDTO?.type && (
              <span>{t("error-input-required")}</span>
            )}
          </div>
        </div>
        <br />
        <hr />
        <br />
        
        <div className="flex-column">
            
            <UploadFileOrImage
              onlyImages={false}
              onSave={(itens, removedFiles) =>
                handleImageChange(itens, removedFiles)
              }
              initialSavedFiles={vehicle?.vehiclesInfoDTO.files || []}
              
            />
           
          </div>
      

       

        <div className="form-fields flex-column">
          <div className="flex-column">
            <label htmlFor="observations">
              {t("label-general-observations")}
            </label>
            <textarea
              placeholder={t("placeholder.observations")}
              id="observations"
              {...register("observations.observations")}
            />
          </div>
        </div>

        <br />
        <hr />

        <div className="flex-row buttons-section">
          <button className="neutral-white" onClick={onClose} type="button">
            {t("label-back")}
          </button>
          <button className="blue" type="submit">
            {t("label-save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default VehicleForm;
