import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { t } from "i18next";

import {
  SparkIcon,
  SparkTableBody,
  SparkTableCell,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import { useParams } from "react-router-dom";
import { CADRItemDTO } from "../../../models/cadri-item-DTO";
import { useAuth } from "../../../../../contexts/auth-context";
import { ScrapInfoDTO } from "../../../../scraps/scrap-admin-generator-view/components/scrap-form/models/scrap-DTO";
import {
  getScrapAdminContract,
  getscrapInfo,
} from "../../../../scraps/scrap-admin-service";
import { getScrapDealerById } from "../../../../scrap-dealers/scrap-dealers-service";
import ScrapMaterialInputAutocomplete from "../../../../../components/ScrapMaterialInputAutocomplete/ScrapMaterialInputAutocomplete";
import { useAlert } from "../../../../../contexts/alert-context";
import {
  getAdrressByEntityId,
  getCountryConfigById,
} from "../../../../../services/address-service";
import { extractFileNameFromUrl } from "../../../../../services/string-treatment";
import { applyDecimalMask } from "../../../../../utils/formatting";
import { addNewCadri } from "../../../cadri-service";
import Expiration from "../../../../../components/Expiration/Expiration";
import { ExpirationDTO } from "../../../../../components/Expiration/models/ExpirationDTO";
import { CountryConfigDTO } from "../../../../settings/components/Country-config/country-config-DTO";
import { profileENUM } from "../../../../../assets/enums/profiles-enum";
import UploadFileOrImage from "../../../../../components/UploadFileAndImagesComponent/UploadFileOrImagesComponent";
interface NewCollectModalProps {
  originEntity: string;
  item?: CADRItemDTO;
  profile: string;
  onSave: (item: CADRItemDTO) => void;
  onClose: () => void;
}

const NewCollectModalCadri: React.FC<NewCollectModalProps> = ({
  item,
  onSave,
  onClose,
  profile,
  originEntity,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CADRItemDTO>({
    defaultValues: item as CADRItemDTO,
  });
  const { userDoc } = useAuth();
  const originId = userDoc?.originId;
  const { id } = useParams<{ id: string }>();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [autocompleteError, setAutocompleteError] = useState(false);
  const watchedAttachment = watch("attachmentCadri");

  const [scrapMaterialPreview, setScrapMaterialPreview] = useState<any>();
  const [scrapMaterialContract, setScrapMaterialContract] = useState<any>();
  const [scrapDealerDetails, setScrapDealerDetails] = useState<any>();
  const [isDisabled, setIsDisabled] = useState(false);

  const [countryConfigEntity, setCountryConfigEntity] =
    useState<CountryConfigDTO>();

  const { setAlert } = useAlert();
  const [quantityMaskedValue, setQuantityMaskedValue] = useState("");
  const fetchEntityCountry = async () => {
    try {
      const entityId = id || originId;
      if (entityId) {
        const data = await getAdrressByEntityId(entityId);
        if (data?.country) {
          const countryConfig = await getCountryConfigById(data.country);
          setCountryConfigEntity(countryConfig);
        }
      }
    } catch (error) {
      console.error("Failed to fetch country configuration:", error);
    }
  };
  const [expiration, setExpiration] = useState<ExpirationDTO>({
    originId: "",
    docName: "Cadri",
    expirationDate: "",
    warningPeriod: 0,
    solved: false,
    hasExpirationDate: true,
    origin: "",
});

const handleDataChange = (key: string, value: any) => {
    setExpiration((prevData: ExpirationDTO) => ({
        ...prevData,
        [key]: value,
    }));
 
};



  const handleInputChangeQuantity = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const masked = applyDecimalMask(value);
    setQuantityMaskedValue(masked);
  };

  useEffect(() => {
    setValue(
      "quantityApproved",
      quantityMaskedValue
    );
  }, []);

  useEffect(() => {
    fetchEntityCountry();
  }, []);

  useEffect(() => {

    if (item) {
      getscrapInfo(item.scrapMaterial).then((scrapMaterial) => {
        if (scrapMaterial) {
          setValue("scrapMaterial", scrapMaterial);
          setScrapMaterialPreview(scrapMaterial);
          getScrapDealerById(scrapMaterial.contractDealer).then((dealer) => {
            setScrapDealerDetails(dealer);
          });
        }
      });
      getScrapAdminContract(item.scrapMaterial).then((scrapContract) => {
        if (scrapContract) {
          setScrapMaterialContract(scrapContract);
        }
      });
      if (item.expirationDocument) {
        setExpiration({
            originId: item.expirationDocument.originId || "",
            docName: item.expirationDocument.docName || "Cadri",
            expirationDate: item.expirationDocument.expirationDate || "",
            warningPeriod: item.expirationDocument.warningPeriod || 60,
            solved: item.expirationDocument.solved || false,
            hasExpirationDate: item.expirationDocument.hasExpirationDate || false,
            origin: item.expirationDocument.origin || "",
        });
     
    }

      setQuantityMaskedValue(item.quantityApproved || "");
      setValue("quantityApproved", item.quantityApproved);
    }
  }, [item, setScrapMaterialPreview, setValue]);

  const [removedFiles, setRemovedFiles] = useState<string[]>([]);

  const handleImageChange = useCallback(
    (files: File[], removedFiles: string[]) => {
      setSelectedFiles(files);
      if (removedFiles.length > 0) {
        setRemovedFiles(removedFiles);
      }
    },
    []
  );

  const handleSetScrapMaterial = async (scrapMaterial: ScrapInfoDTO) => {
    setValue("scrapMaterial", scrapMaterial);
    setScrapMaterialPreview(scrapMaterial);
    setAutocompleteError(false);

    const contractDetails = await getScrapAdminContract(scrapMaterial.id);
    setScrapMaterialContract(contractDetails);

    const scrapDealerDetails = await getScrapDealerById(
      scrapMaterial.contractDealer
    );
    setScrapDealerDetails(scrapDealerDetails);
  };

  const onSubmit = async (data: CADRItemDTO) => {
    setIsDisabled(true); 
    if (!data.scrapMaterial) {
      setAutocompleteError(true);
    } else {
      data.quantityApproved = data.quantityApproved;
      data.date = new Date();
      data.unity = scrapMaterialPreview.unity;
      data.isHazardousMaterial = data.scrapMaterial.scrapCategory.isHazardousMaterial
      data.generatorId = data.scrapMaterial.originId;

      data.contractedDealerId = data.scrapMaterial.contractDealer;
      data.scrapMaterialName = data.scrapMaterial.scrapCategory.name;
      data.scrapGeneratorName = data.scrapMaterial.originName;
      data.scrapDealerName = data.scrapMaterial.contractDealerName;
      data.scrapMaterial = data.scrapMaterial.id;
      if (countryConfigEntity) {
        data.locale = countryConfigEntity.locale;
      }
      if (expiration.hasExpirationDate) {
        if (expiration.expirationDate.length === 0) {
          return setAlert({
            text: `${t("alert-error-date-empty")}`,
            type: "error",
          });
        } else {
          data.expirationDocument = expiration;
        }
      }
      await addNewCadri(data, selectedFiles,removedFiles)
        .then(() => {
          setAlert({
            text: t("alert-successfully-registered"),
            type: "success",
          });

          onClose();
          onSave(data);
        })
        .catch((error) => {
          console.error("Failed to add item to CADRI:", error);
          setAlert({
            text: t("alert-error-occurred"),
            type: "error",
          });
        });
    }
    setIsDisabled(false); 
  };

  return (
    <div
      className="modal-content"
      style={{ gap: "20px", padding: "2rem 1rem" }}
    >
      <div onClick={onClose} style={{display:"flex",justifyContent:"flex-end"}}>
          <SparkIcon
            
            className="icon-flag"
            no-padding={true}
            icName={"close"}
          />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>{t("CADRI")}</h3>
        <div className="form-fields">
          <div className="flex-column">
            <ScrapMaterialInputAutocomplete
              originEntity={originEntity}
              initialValue={item?.scrapMaterial}
              isDisabled={item ? true : false}
              label={`${t("label-material")}*`}
              onChange={(scrapMaterial) =>
                handleSetScrapMaterial(scrapMaterial)
              }
              placeholder={t("label-material")}
            />
            <div className="flex-row justify-between">
              {autocompleteError && <span>{t("error-input-required")}</span>}
              <div></div>
              <p
                style={{
                  color: "var(--extra-grey-medium)",
                  fontSize: 12,
                }}
              >
                {t("label-material-contracted")}
              </p>
            </div>
          </div>

          {scrapMaterialPreview && (
            <div
              className="resume-material-collect flex-row margin-topbottom-1"
              style={{ gap: 10 }}
            >
              <div className="flex-column">
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: 5,
                  }}
                  src={
                    scrapMaterialPreview?.images &&
                    scrapMaterialPreview?.images?.length > 0
                      ? scrapMaterialPreview.images[scrapMaterialPreview.images.length-1]
                      : scrapMaterialPreview.scrapCategory.image
                  }
                />
              </div>
              <div
                className="flex-column justify-between"
                style={{ width: "100%" }}
              >
                <div className="flex-column" style={{ gap: 5 }}>
                  <div className="justify-between flex-row">
                    <h3 style={{ margin: 0, marginBottom: 8 }}>
                      {scrapMaterialPreview.scrapCategory.name}
                    </h3>
                    <a
                      href={
                        profile === profileENUM.ADMIN_BOSCH
                          ? `/scrap-generators-details/${scrapMaterialPreview.originId}/scraps-form/${scrapMaterialPreview.id}`
                          : `/generator-scraps/${scrapMaterialPreview.originId}/generator-scraps-form/${scrapMaterialPreview.id}`
                      }
                      className="a-link-style"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("label-details")}
                    </a>
                  </div>
                </div>
                <div className="flex-column" style={{ gap: 2 }}>
                  <h5 style={{ margin: 0 }}>{t("material-contract-date")}:</h5>
                  <p>{scrapMaterialContract?.contractDate || "xx-xx-xxxx"}</p>
                </div>
                <div className="flex-row justify-between align-end">
                  <div className="flex-column" style={{ gap: 2 }}>
                    <h5 style={{ margin: 0 }}>
                      {t("label-responsible-shipping")}:
                    </h5>
                    <p>
                      {t(`label-${scrapMaterialPreview.responsibleShipping}`)}
                    </p>
                  </div>
                  <div className="flex-column align-end">
                    <div className="flex-row" style={{ gap: 5 }}>
                      <h3 style={{ margin: 0 }}>
                        {scrapMaterialContract?.contractValue || "xx"}
                      </h3>
                      <p>{`/${t(
                        `unityENUM.${scrapMaterialPreview.unity}`
                      )}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="flex-column">
            <label htmlFor="label-quantity">
              {`${t("label-approved-quantity")}*`}
            </label>
            <input
              placeholder={t("label-approved-quantity")}
              type="text"
              value={quantityMaskedValue}
              id="label-quantity"
              {...register("quantityApproved", {
                required: true,
              })}
              onChange={handleInputChangeQuantity}
            />
          </div>
          {scrapDealerDetails && (
            <div className="flex-column legal-informations margin-topbottom-1">
              <p>
                {t("label-scrap-dealer")}:{" "}
                {scrapDealerDetails?.scrapDealerInfo?.legalName}
              </p>
              <p>
                {scrapDealerDetails?.scrapDealerInfo?.nationalRegisterLabel}:{" "}
                {scrapDealerDetails?.scrapDealerInfo?.nationalRegister}
              </p>
              <p>
                {scrapDealerDetails?.addressInfo?.postalCodeLabel}:{" "}
                {scrapDealerDetails?.addressInfo?.postalCode}
              </p>
              <p>{t("label-address")}:</p>
              <p>
                {scrapDealerDetails?.addressInfo?.address},{" "}
                {scrapDealerDetails?.addressInfo?.addressNumber}
              </p>
              <p>
                {scrapDealerDetails?.addressInfo?.city},{" "}
                {scrapDealerDetails?.addressInfo?.state} -{" "}
                {scrapDealerDetails?.addressInfo?.countryName}
              </p>
            </div>
          )}
          <div className="flex-column margin-topbottom-1">
         
          <div className="flex-column">
            
            <UploadFileOrImage
              onlyImages={false}
              onSave={(itens, removedFiles) =>
                handleImageChange(itens, removedFiles)
              }
              initialSavedFiles={watchedAttachment}
              
            />
           
          </div>
 
       
          </div>
          <Expiration
                        dto={expiration}
                        dataHandler={handleDataChange}
                        expirationDate={"expirationDate"}
                        warningPeriod={"warningPeriod"}
                        hasExpirationDate={"hasExpirationDate"}
                        hasExpirationDateInit={expiration.hasExpirationDate || false}
                        expirationDateInit={expiration.expirationDate || ""}
                        warningPeriodInit={expiration.warningPeriod || item?.expirationDocument?.warningPeriod || 60}
                        disableExpirationCheckbox
                    />

          <div
            className="flex-row justify-end"
            style={{ gap: "10px", marginTop: "1.5rem" }}
          >
            <button onClick={onClose} style={{ margin: 0 }}>
              {t("label-cancel")}
            </button>
            <button className="blue" type="submit" style={{ margin: 0 }} disabled={isDisabled}>
              {t("label-save")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewCollectModalCadri;
