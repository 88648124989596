import {
    collection,

    getDocs,
    query,
    where,
 
} from "firebase/firestore";
import { firestore } from "../../../../../../utils/firebase-config"; 
import { COLLECTIONS } from "../../../../../../assets/enums/firebase-colections";
import { ApproveRollDTO } from "./scrap-generator-approve-rollDTO";
import { removeItemFromArrayField, saveFiles, saveNewAsync, update } from "../../../../../../services/persistence";
import { FOLDERS } from "../../../../../../assets/enums/storage-folders";





export async function addNewApprover(data: ApproveRollDTO, files: File[],removedFiles: string[]): Promise<boolean> {

    try {

      const newApproverDoc = await saveNewAsync(COLLECTIONS.APPROVE_ROLL, { ...data, tenantId: data.originId });
      saveFiles(COLLECTIONS.APPROVE_ROLL, newApproverDoc.id, FOLDERS.APPROVE_ROLL, files, "fileUrls");
        

        return true;
    } catch (error) {
        console.error("Error adding new approver: ", error);
        return false;
    }
}

export async function updateApprover(data: ApproveRollDTO, id: string, files: File[],removedFiles: string[]): Promise<boolean> {
    try {
       
        await update(COLLECTIONS.APPROVE_ROLL, data, id);
        if (files && files.length > 0) {
        saveFiles(COLLECTIONS.APPROVE_ROLL, id, FOLDERS.APPROVE_ROLL, files, "fileUrls");
        }
        if (removedFiles != null && removedFiles.length > 0) {
                removedFiles.forEach((files) => {
                  removeItemFromArrayField(
                    COLLECTIONS.APPROVE_ROLL,
                    id,
                    "fileUrls",
                    files
                  );
                });
              }

        return true;
    } catch (error) {
        console.error("Error updating approver: ", error);
        return false;
    }
}

export async function getAllApprovers(id?: string): Promise<ApproveRollDTO[]> {
    try {
        const colRef = collection(firestore, COLLECTIONS.APPROVE_ROLL);
        let q;
        if (id) {
            q = query(colRef, where("user.originId", "==", id));
        } else {
            q = colRef;
        }
        const querySnapshot = await getDocs(q);
        const approvers: ApproveRollDTO[] = [];
        querySnapshot.forEach((doc) => {
            approvers.push({ ...doc.data(), id: doc.id } as ApproveRollDTO);
        });

        return approvers;
    } catch (error) {
        console.error("Error fetching approvers: ", error);
        return [];
    }
}
