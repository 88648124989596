import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import addIcon from "../../../../assets/svgs/add.svg";
import CRMFunnelForm from "./crm-form/crm-form";
import { addNewCRMStep, getCRMSteps } from "./crm-service";
import { CRMFunnelDTO } from "./crm-dto";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
    arrayMove,
} from "@dnd-kit/sortable";
import "./crm.css";
import { update } from "../../../../services/persistence";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";

const CRMFunnel: React.FC<{ enabledUsers?: string[] }> = () => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [crmFunnels, setCrmFunnels] = useState<CRMFunnelDTO[]>([]);
    const [selectedFunnelId, setSelectedFunnelId] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetchCrmFunnels = async () => {
        try {
            const allFunnels: CRMFunnelDTO[] = await getCRMSteps();
            setCrmFunnels(allFunnels);
        } catch (error) {
            setError(t("error-fetching-funnels"));
        }
    };

    useEffect(() => {
        fetchCrmFunnels();
    }, []);

    const handleCloseModal = async (newFunnel?: CRMFunnelDTO) => {
        setIsModalOpen(false);
      
        if (newFunnel) {
          try {
            if (!newFunnel.id) {
            
              const allFunnels = await getCRMSteps();
              const nextOrder = allFunnels.length + 1;
      
              newFunnel.crmFunnelInfoDTO.order = nextOrder; 
            }
      
          
            const savedFunnel = await addNewCRMStep(newFunnel);
      
           
            setCrmFunnels((prevFunnels) => {
              const funnelExists = prevFunnels.some((funnel) => funnel.id === savedFunnel.id);
              return funnelExists
                ? prevFunnels.map((funnel) => (funnel.id === savedFunnel.id ? savedFunnel : funnel))
                : [...prevFunnels, savedFunnel];
            });
          } catch (error) {
            setError(t("error-saving-funnel"));
          }
        }
      
       
        fetchCrmFunnels();
      };
      

    const handleOpenModal = (funnelId: string | null = null) => {
        setSelectedFunnelId(funnelId);
        setIsModalOpen(true);
    };

    const handleDragEnd = ({ active, over }: { active: any; over: any }) => {
        if (!over) return;
      
        const oldIndex = crmFunnels.findIndex((funnel) => funnel.id === active.id);
        const newIndex = crmFunnels.findIndex((funnel) => funnel.id === over.id);
      
        if (oldIndex !== newIndex) {
          const updatedFunnels = arrayMove(crmFunnels, oldIndex, newIndex).map((funnel, index) => ({
            ...funnel,
            crmFunnelInfoDTO: {
              ...funnel.crmFunnelInfoDTO,
              order: index + 1, 
            },
          }));
      
          setCrmFunnels(updatedFunnels);
      
          
          updatedFunnels.forEach(async (funnel) => {
            await update(COLLECTIONS.CRM_STEPS, funnel.crmFunnelInfoDTO, funnel.id);
          });
        }
      };
      

    return (
        <div>
            {isModalOpen && (
                <div className="modal-background">
                    <CRMFunnelForm
                        onClose={handleCloseModal}
                        funnelId={selectedFunnelId || ""}
                    />
                </div>
            )}

            <div className="flex-row">
                <button
                    className="blue-with-icon button"
                    onClick={() => handleOpenModal()}
                    aria-label={t("label-add-new")}
                >
                    <img src={addIcon} alt={t("label-add-icon")} />
                    <p>{t("label-add-new")}</p>
                </button>
            </div>

            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext
                    items={crmFunnels.map((funnel) => funnel.id)}
                    strategy={verticalListSortingStrategy}
                >
                    <div className="card-container">
                        {crmFunnels.map((funnel) => (
                            <SortableItem
                                key={funnel.id}
                                id={funnel.id}
                                funnel={funnel}
                                onOpenModal={handleOpenModal}
                            />
                        ))}
                    </div>
                </SortableContext>
            </DndContext>
        </div>
    );
};
const SortableItem: React.FC<{
    id: string;
    funnel: CRMFunnelDTO;
    onOpenModal: (funnelId: string | null) => void;
}> = ({ id, funnel, onOpenModal }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: `translate3d(${transform?.x || 0}px, ${transform?.y || 0}px, 0)`,
        transition: transition || undefined,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className="card"
            onClick={() => onOpenModal(funnel.id)} 
        > 
            <div
                className="color-indicator"
                style={{
                    backgroundColor: funnel.crmFunnelInfoDTO.color || "#ccc", 
                    width: "5px",
                    height: "100%",
                    position: "absolute",
                    left: "0",
                    top: "0",
                }}
            ></div>

           
            <div className="card-content" style={{ marginLeft: "10px" }}>
                <div className="card-text">
                    <h3>{funnel.crmFunnelInfoDTO.stageName || "No Stage"}</h3>
                </div>
            </div>

            <div
                className="card-icon"
                {...attributes}
                {...listeners}
                onClick={(e) => e.stopPropagation()} 
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "50%",
                    right: "15px",
                    transform: "translateY(-50%)",
                    cursor: "grab",
                    fontSize: "1.5rem",
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z" />
                </svg>
            </div>
        </div>
    );
};



export default CRMFunnel;
