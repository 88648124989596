import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";

import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useAuth } from "../../contexts/auth-context";
import { auth, firestore } from "../../utils/firebase-config";
import FilterComponent from "../../components/FIlter/filter";
import NoData from "../../components/NoData/NoData";
import {
  formatTimestampDate,
  transformDateFormat,
} from "../../services/date-format";
import ExpirationDocumentSolveForm from "./components/expiration-document-solve/expiration-document-solve";
import ApproveGenericCard from "../../components/ApproveGenericCard/ApproveGenericCard";
import { addNewScrapCategory } from "../scraps/scraps-service";
import GenericConfirmationModal from "../../components/GenericConfirmationModal/GenericConfirmationModal";
import { useAlert } from "../../contexts/alert-context";
import {
  getAllPendingItems,
  getItemById,
  saveApprovedItem,
  saveReprovedItem,
} from "../../services/pending-approval-service";
import { onAuthStateChanged } from "firebase/auth";
import ScrapCategoryMinimalViewForm from "./components/pending-scrap-categories-minimal-view-form/pending-scrap-categories-minimal-view-form";
import folderCheckBroom from "./../../assets/img/folder-check-broom.svg";
import FinancialDashboard from "../financial/financial-dashboard";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import { StatusEnum } from "../../assets/enums/status-enum";
import { receiveNumberFormatWithMilhar } from "../../services/number-format";
import FinancialDashboardAdmin from "../financial/financial-dashboard-admin/componentes/financial-dashboard-admin";
import { profileENUM } from "../../assets/enums/profiles-enum";

const DashBoardAdmin: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [scrapCategoriesToApprove, setScrapCategoriesToApprove] = useState<any>(
    []
  );
  const [tableData, setTableData] = useState<any>([]);
  const [tableDataBid, setTableDataBid] = useState<any>([]);
  const [tableDataWaitingOperation, setTableDataWaitingOperation] =
    useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isItem, setIsItem] = useState<any>();
  const [isApprove, setIsApprove] = useState(false);
  const tableHeader = [
    `${t("label-documents")}`,
    `${t("referent-to")}`,
    `${t("label-expiration")}`,
    `${t("start-warning")}`,
  ];
  const tableHeaderBid = [
    `${t("label-material")}`,
    `${t("label-scrap-generator")}`,
    `${t("label-quantity")}`,
    `${t("label-value")}`,
    `${t("label-region")}`,
  ];
  const tableHeaderWaitingOperation = [
    `${t("label-material")}`,
    `${t("label-scrap-generator")}`,
    `${t("label-scrap-dealer")}`,
    `${t("label-quantity")}`,
    `${t("label-region")}`,
  ];
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [filteredDataBid, setFilteredDataBid] = useState<any[]>([]);
  const [filteredDataWaitingOperation, setFilteredDataWaitingOperation] = useState<any[]>([]);

  const handleFilterClick = (filteredData: any[]) => {
    setFilteredData(filteredData);
  };
  const handleFilterClickBid = (filteredDataBid: any[]) => {
    setFilteredDataBid(filteredDataBid);
  };

  const handleFilterClickWaitingOperation = (
    filteredDataWaitingOperation: any[]
  ) => {
    setFilteredDataWaitingOperation(filteredDataWaitingOperation);
  };

  const sortByDocumentName = (data: any[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.docName?.toString().toLowerCase() || "";
      const nameB = b.docName?.toString().toLowerCase() || "";
      return nameA.localeCompare(nameB);
    });
  };

  const sortByOrigin = (data: any[]) => {
    return [...data].sort((a, b) => {
      const originA = a.startWarningDate?.toString().toLowerCase() || "";
      const originB = b.startWarningDate?.toString().toLowerCase() || "";
      return originA.localeCompare(originB);
    });
  };

  const sortByExpirationDate = (
    data: { expirationDate: Date | string | null | undefined }[]
  ): { expirationDate: Date | string | null | undefined }[] =>
    [...data].sort((a, b) => {
      const dateA =
        a.expirationDate instanceof Date
          ? a.expirationDate
          : new Date(a.expirationDate || 0);
      const dateB =
        b.expirationDate instanceof Date
          ? b.expirationDate
          : new Date(b.expirationDate || 0);

      return dateA.getTime() - dateB.getTime();
    });

  const sortOptions = [
    { label: t("label-documents"), sortFunction: sortByDocumentName },
    { label: t("referent-to"), sortFunction: sortByOrigin },
    { label: t("label-expiration"), sortFunction: sortByExpirationDate },
  ];

  const sortByname = (data: any[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.scrapCategory.name?.toString().toLowerCase() || "";
      const nameB = b.scrapCategory.name?.toString().toLowerCase() || "";
      return nameA.localeCompare(nameB);
    });
  };

  const sortBycity = (data: any[]) => {
    return [...data].sort((a, b) => {
      const cityA = a.operationAddress.city?.toString().toLowerCase() || "";
      const cityB = b.operationAddress.city?.toString().toLowerCase() || "";
      return cityA.localeCompare(cityB);
    });
  };
  const sortByorigin = (data: any[]) => {
    return [...data].sort((a, b) => {
      const originA = a.originName?.toString().toLowerCase() || "";
      const originB = b.originName?.toString().toLowerCase() || "";
      return originA.localeCompare(originB);
    });
  };

  const sortOptionsbid = [
    { label: t("label-material"), sortFunction: sortByname },
    { label: t("label-city"), sortFunction: sortBycity },
    { label: t("label-scrap-generator"), sortFunction: sortByorigin },
  ];
  const handleSortClick = (sortFunction: (data: any[]) => any[]) => {
    const sortedData = sortFunction([...filteredData]);
    setFilteredData(sortedData);
  };

  const fetchTableData = async () => {
    const q = query(
      collection(firestore, "ExpirationDocuments"),
      where("solved", "==", false),
      where("startWarningDate", "<", new Date())
    );

    const querySnapshot = await getDocs(q);

    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      searchData: doc.data()["docName"].concat(" ", doc.data()["origin"]),
    })) as unknown as any[];
    setTableData(items);
    setFilteredData(items);
  };

  const fetchTableDataNewBid = async () => {
    const q = query(
      collection(firestore, COLLECTIONS.SCRAP_BY_GENERATOR),
      where("creation.createdBy", "==", profileENUM.GENERATOR),
      where("status", "==", StatusEnum.TO_DEAL)
    );

    const querySnapshot = await getDocs(q);

    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      searchData: doc
        .data()
        ["originName"].concat(" ", doc.data()["scrapCategory"]["name"]),
    })) as unknown as any[];
    setTableDataBid(items);
    setFilteredDataBid(items);
  };

  const fetchTableDataWaitingOperation = async () => {
    const q = query(
      collection(firestore, COLLECTIONS.SCRAP_BY_GENERATOR),
      where("status", "==", StatusEnum.CONTRACTED)
    );

    const querySnapshot = await getDocs(q);

    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      searchData: doc
        .data()
        ["originName"].concat(" ", doc.data()["scrapCategory"]["name"])
        .concat(" ", doc.data()["contractDealerName"]),
    })) as unknown as any[];
    setTableDataWaitingOperation(items);
    setFilteredDataWaitingOperation(items);
  };

  const fetchScrapCategoriesToApprove = async () => {
    const categories = await getAllPendingItems();

    setScrapCategoriesToApprove(categories);
  };

  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId("");
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchTableData();
    fetchTableDataNewBid();
    fetchTableDataWaitingOperation();
    fetchScrapCategoriesToApprove();
  }, [id]);

  useEffect(() => {
    fetchScrapCategoriesToApprove();
  }, [isConfirmationModalOpen]);

  const handleApproveOrReproveItem = (item: any, approved: boolean) => {
    if (approved) {
      saveApprovedItem(item.id, item, userId || "").then(() => {
        addNewScrapCategory(item.data, profileENUM.ADMIN_BOSCH);
      });
    } else {
      saveReprovedItem(item.id, item, userId || "");
    }
    fetchScrapCategoriesToApprove().then(() => {
      setIsConfirmationModalOpen(false);
      setAlert({
        text: approved ? t("label-approved") : t("label-reproved"),
        type: "success",
      });
    });
  };

  const handleOpenModal = (item: any) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
    fetchTableData();
  };

  const handleConfirmation = (item: any, isApprove: boolean) => {
    setIsItem(item);
    setIsApprove(isApprove);
    setIsConfirmationModalOpen(true);
    setIsPreviewModalOpen(false);
  };

  const handleClickCard = async (item: any) => {
    setIsItem(item);
    setIsApprove(isApprove);
    setIsPreviewModalOpen(true);
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isPreviewModalOpen && (
          <div className="modal-background">
            <ScrapCategoryMinimalViewForm
              item={isItem}
              onClose={() => setIsPreviewModalOpen(false)}
              onApprove={(formUpdated) => {
                handleConfirmation(formUpdated, true);
              }}
              onReprove={(formUpdated) => {
                handleConfirmation(formUpdated, false);
              }}
            />
          </div>
        )}
        {isConfirmationModalOpen && (
          <div className="modal-background">
            <GenericConfirmationModal
              onClose={() => setIsConfirmationModalOpen(false)}
              onConfirm={() => handleApproveOrReproveItem(isItem, isApprove)}
            >
              <p>
                - {isApprove ? t("label-approve") : t("label-reprove")}{" "}
                <strong>{isItem?.data.scrapsCategoryInfo.name}</strong>
              </p>
            </GenericConfirmationModal>
          </div>
        )}
        {tableDataBid.length > 0 && (
          <>
            <div className="info-section">
              <div className="double-fields" style={{ marginBottom: 15 }}>
                <div
                  className="flex-row align-center"
                  style={{ width: "100%" }}
                >
                  <SparkIcon icName="window-new" />
                  <h3 style={{ width: "100%", margin: 0 }}>{`${t(
                    "label-new-bid-request"
                  )}`}</h3>
                </div>
                <FilterComponent
                  data={tableDataBid}
                  onFilterClick={handleFilterClickBid}
                  sortOptions={sortOptionsbid}
                />
              </div>
              <SparkTable>
                <SparkTableHead>
                  <SparkTableRow>
                    <SparkTableCell className="icon-column"></SparkTableCell>
                    {tableHeaderBid.map((header, index) => (
                      <SparkTableCell key={index}>
                        <strong>{header.toUpperCase()}</strong>
                      </SparkTableCell>
                    ))}
                  </SparkTableRow>
                </SparkTableHead>

                <SparkTableBody>
                  {filteredDataBid.map((rowItem: any, rowIndex: any) => (
                    <SparkTableRow
                      key={rowIndex}
                      onClick={() =>
                        navigate(
                          `/scrap-generators-details/${rowItem.originId}/scraps-form/${rowItem.id}`
                        )
                      }
                    >
                      <SparkTableCell className="icon-column">
                        <SparkIcon
                          className="icon-flag"
                          no-padding={true}
                          icName={
                            rowItem.scrapCategory.isHazardousMaterial
                              ? "danger-hazards"
                              : "less-minimize"
                          }
                        />
                      </SparkTableCell>
                      <SparkTableCell>
                        {rowItem.scrapCategory.name}
                      </SparkTableCell>
                      <SparkTableCell>{rowItem.originName}</SparkTableCell>
                      <SparkTableCell>
                        {receiveNumberFormatWithMilhar(
                          rowItem.contractTotalQuantityPreview
                        )}{" "}
                        {t(`unityENUM.${rowItem.unity}`)}
                      </SparkTableCell>
                      <SparkTableCell>
                        {rowItem.targetPrice != "" ? rowItem.targetPrice : ""}/
                        {rowItem.targetPrice != ""
                          ? t(`unityENUM.${rowItem.unity}`)
                          : ""}
                      </SparkTableCell>
                      <SparkTableCell>
                        {rowItem.operationAddress.city} -{" "}
                        {rowItem.operationAddress.countryName}
                      </SparkTableCell>
                    </SparkTableRow>
                  ))}
                </SparkTableBody>
              </SparkTable>
            </div>
          </>
        )}
        <br />

        {tableDataWaitingOperation.length > 0 && (
          <>
            <div className="info-section">
              <div className="double-fields" style={{ marginBottom: 15 }}>
                <div
                  className="flex-row align-center"
                  style={{ width: "100%" }}
                >
                  <SparkIcon icName="calendar-clock" />
                  <h3 style={{ width: "100%", margin: 0 }}>{`${t(
                    "label-waiting-operation"
                  )}`}</h3>
                </div>
                <FilterComponent
                  data={tableDataWaitingOperation}
                  onFilterClick={handleFilterClickWaitingOperation}
                  sortOptions={sortOptionsbid}
                />
              </div>
              <SparkTable>
                <SparkTableHead>
                  <SparkTableRow>
                    <SparkTableCell className="icon-column"></SparkTableCell>
                    {tableHeaderWaitingOperation.map((header, index) => (
                      <SparkTableCell key={index}>
                        <strong>{header.toUpperCase()}</strong>
                      </SparkTableCell>
                    ))}
                  </SparkTableRow>
                </SparkTableHead>

                <SparkTableBody>
                  {filteredDataWaitingOperation.map(
                    (rowItem: any, rowIndex: any) => (
                      <SparkTableRow
                        key={rowIndex}
                        onClick={() =>
                          navigate(
                            `/scrap-generators-details/${rowItem.originId}/scraps-form/${rowItem.id}`
                          )
                        }
                      >
                        <SparkTableCell className="icon-column">
                          <SparkIcon
                            className="icon-flag"
                            no-padding={true}
                            icName={
                              rowItem.scrapCategory.isHazardousMaterial
                                ? "danger-hazards"
                                : "less-minimize"
                            }
                          />
                        </SparkTableCell>
                        <SparkTableCell>
                          {rowItem.scrapCategory.name}
                        </SparkTableCell>
                        <SparkTableCell>{rowItem.originName}</SparkTableCell>
                        <SparkTableCell>
                          {rowItem.contractDealerName}
                        </SparkTableCell>
                        <SparkTableCell>
                          {receiveNumberFormatWithMilhar(
                            rowItem.contractTotalQuantityPreview
                          )}{" "}
                          {t(`unityENUM.${rowItem.unity}`)}
                        </SparkTableCell>

                        <SparkTableCell>
                          {rowItem.operationAddress.city} -{" "}
                          {rowItem.operationAddress.countryName}
                        </SparkTableCell>
                      </SparkTableRow>
                    )
                  )}
                </SparkTableBody>
              </SparkTable>
            </div>
          </>
        )}
        <br />

        <div className="multiple-horizontal-slots" style={{ gap: 5 }}>
          <div className="horizontal-flex-responsible">
            <div className="flex-row align-center">
              <SparkIcon icName="survey-checkmark" />
              <h3 style={{ margin: "0" }}>{t("label-approval required")}</h3>
            </div>
            {scrapCategoriesToApprove && scrapCategoriesToApprove.length > 0 ? (
              <div className="flex-column" style={{ gap: "10px" }}>
                {scrapCategoriesToApprove.map((item: any, index: any) => (
                  <ApproveGenericCard
                    key={index}
                    onApprove={() => handleConfirmation(item, true)}
                    onReprove={() => handleConfirmation(item, false)}
                    onClick={() => handleClickCard(item)}
                    itemName={`${item.data.scrapsCategoryInfo.name}`}
                    itemTag={`${t(`origin-types.${item.origin}`)}`}
                    item={item.data.scrapsCategoryInfo}
                  >
                    <div className="flex-column" style={{ gap: "5px" }}>
                      <span
                        style={{ width: "100%", height: "20px" }}
                        className="hide-word-container"
                      >
                        <strong>{t("label-material-class")}: </strong>
                        {item.data.scrapsCategoryInfo.materialClass.name || "-"}
                      </span>
                    </div>
                  </ApproveGenericCard>
                ))}
              </div>
            ) : (
              <div className="empty-slot">
                <img
                  style={{ marginBottom: 10, maxHeight: "8rem" }}
                  src={folderCheckBroom}
                  alt="no data ilustration"
                />
                <h4>{t("no-data.to-approve")}</h4>
              </div>
            )}
          </div>
          <div className="horizontal-flex-responsible">
            <FinancialDashboardAdmin enabledUsers={[]} />
          </div>
        </div>
        {isModalOpen && (
          <div className="modal-background">
            <ExpirationDocumentSolveForm
              contactId={selectedItem.id}
              onClose={handleCloseModal}
              item={selectedItem}
            />
          </div>
        )}
        <div style={{ marginBottom: "120px" }}>
          <div className="flex-row" style={{ justifyContent: "space-between" }}>
            <div className="flex-row"></div>
          </div>

          <br />
          <br />

          {tableData.length > 0 && (
            <>
              <div className="info-section">
                <div className="double-fields" style={{ marginBottom: 15 }}>
                  <div
                    className="flex-row align-center"
                    style={{ width: "100%" }}
                  >
                    <SparkIcon icName="alert-error" />
                    <h3 style={{ margin: 0 }}>{`${t("label-expiration")}`}</h3>
                  </div>
                  <FilterComponent
                    data={tableData}
                    onFilterClick={handleFilterClick}
                    sortOptions={sortOptions}
                  />
                </div>
                <SparkTable>
                  <SparkTableHead>
                    <SparkTableRow>
                      <SparkTableCell className="icon-column"></SparkTableCell>
                      {tableHeader.map((header, index) => (
                        <SparkTableCell key={index}>
                          <strong>{header.toUpperCase()}</strong>
                        </SparkTableCell>
                      ))}
                    </SparkTableRow>
                  </SparkTableHead>

                  <SparkTableBody>
                    {filteredData.map((rowItem: any, rowIndex: any) => (
                      <SparkTableRow
                        key={rowIndex}
                        onClick={() => handleOpenModal(rowItem)}
                      >
                        <SparkTableCell className="icon-column">
                          <SparkIcon
                            className="icon-flag"
                            no-padding={true}
                            icName={
                              rowItem.solved
                                ? "danger-hazards"
                                : "less-minimize"
                            }
                          />
                        </SparkTableCell>
                        <SparkTableCell>{rowItem.docName}</SparkTableCell>
                        <SparkTableCell>{rowItem.origin}</SparkTableCell>
                        <SparkTableCell>
                          {transformDateFormat(rowItem.expirationDate)}
                        </SparkTableCell>
                        <SparkTableCell>
                          {formatTimestampDate(rowItem.startWarningDate)}
                        </SparkTableCell>
                      </SparkTableRow>
                    ))}
                  </SparkTableBody>
                </SparkTable>
              </div>
            </>
          )}
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default DashBoardAdmin;
