import { ExpirationDTO } from "../../components/Expiration/models/ExpirationDTO";
import { CRMFunnelinfoDTO } from "../settings/components/CRM/crm-dto";

export interface ScrapProspectingGeneratorsDTO {
  CRMSteps: CRMStepsDTO[];
  id: string;
  name: string;
  description: string;
  segment: string;
  revenues: string;
  originRevenues: number;
  currency: string;
  locale: string;
  fee: string;
  originFee: number;
  sale: string;
  originSale: number;
  percentualFee: number;
  lead: string;
  contacts: ContactsProspecting[];
  status: ProspectingStatusENUM;
  customerInteractions: CustomerInteractionDTO[];
  nextSteps: NextStepsDTO[];
}

export interface CRMStepsDTO {
  crmFunnelInfoDTO?: CRMFunnelinfoDTO;
  originId?: string;
  conclusionDate?: any;
}

export enum ProspectingStatusENUM {
  CHECKED = "CHECKED",
  PENDING = "PENDING",
}

export interface ContactsProspecting {
  name: string;
  email: string;
  role: string;
}

export interface CustomerInteractionDTO {
  date: any;
  interaction: string;
  person: string;
}

export interface NextStepsDTO {
  step: string;
  checked: boolean;
  expirationDocument?: ExpirationDTO;
}
