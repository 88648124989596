import {
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    where,
  } from "firebase/firestore";
  import { firestore } from "../../utils/firebase-config";
  import { COLLECTIONS } from "../../assets/enums/firebase-colections";
  import { DocumentsInfoDTO, DocumentsDTO, ObservationsDTO } from "./documents-DTO";
  import {
    saveFiles,
    removeItemFromArrayField,
    saveNewAsync,
    saveNewWithId,
    update,
  } from "../../services/persistence";
  import { setExpirationDocument } from "../../components/Expiration/ExpirationService";
  import { ScrapDealerInfoDTO } from "../scrap-dealers/scrap-dealer-DTO";
import { ScrapGeneratorInfoDTO } from "../scrap-generators/scrap-generators-DTO";
import { FOLDERS } from "../../assets/enums/storage-folders";
  
  // add new
  export async function addNewDocument(
    data: DocumentsDTO,
    id: string,
    files: File[], 
    dealer: boolean,
    removedFiles: string[]

  ) {
    if (
      data.documentsInfoDTO &&
      Array.isArray(data.documentsInfoDTO.files)
    ) {
      data.documentsInfoDTO.files =
        data.documentsInfoDTO.files.filter(
          (image) => !removedFiles.includes(image)
        );
    }
    if (data.documentsInfoDTO.expirationDocument != null) {
      data.documentsInfoDTO.expirationDocument.originId = id;
      const originName = dealer ?  await getDealerName(id) : await getGeneratorName(id)
      
      data.documentsInfoDTO.expirationDocument.origin = originName;
    }
    if (data.id == undefined) {
      data.documentsInfoDTO.originId = id;
      saveNewAsync(COLLECTIONS.DOCUMENTS, {...data.documentsInfoDTO, tenantId: data.documentsInfoDTO.originId}).then((docRef) => {
        saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
        if (files.length > 0) {
            saveFiles(
              COLLECTIONS.DOCUMENTS,
              docRef.id,
              FOLDERS.DOCUMENTS,
              files,
              "files"
            );
        }
        if (data.documentsInfoDTO.expirationDocument != null) {
          setExpirationDocument(
            data.documentsInfoDTO.expirationDocument,
            docRef.id
          );
        }
        
        
      });
    } else {
      update(COLLECTIONS.DOCUMENTS, data.documentsInfoDTO, data.id);
      update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
      if (files != null && files.length > 0) {
        saveFiles(
          COLLECTIONS.DOCUMENTS,
          data.id,
          FOLDERS.DOCUMENTS,
          files,
          "files"
        );
      }
      if (removedFiles != null && removedFiles.length > 0) {
        removedFiles.forEach((fileUrl) => {
          removeItemFromArrayField(
            COLLECTIONS.DOCUMENTS,
            data.id,
            "files",
            fileUrl
          );
        });
      }
 
      
      if (data.documentsInfoDTO.expirationDocument != null) {
        setExpirationDocument(data.documentsInfoDTO.expirationDocument, data.id);
      }
      
    }
  }
  
  async function getDealerName(id: string): Promise<string> {
    const docRef = doc(firestore, COLLECTIONS.SCRAP_DEALERS, id);
    const docSnap = await getDoc(docRef);
  
    const dealer = docSnap.data() as ScrapDealerInfoDTO;
    return dealer.tradingName;
  }
  async function getGeneratorName(id: string): Promise<string> {
    const docRef = doc(firestore, COLLECTIONS.SCRAP_GENERATORS, id);
    const docSnap = await getDoc(docRef);
  
    const dealer = docSnap.data() as ScrapGeneratorInfoDTO;
    return dealer.tradingName;
  }
  
  export async function getDocumentsById(id: string): Promise<DocumentsInfoDTO[]> {
    const documentsCollectionRef = collection(firestore, COLLECTIONS.DOCUMENTS);
  
    const q = query(documentsCollectionRef, where("originId", "==", id));
  
    const querySnapshot = await getDocs(q);
  
    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      searchData: doc
        .data()
        ["name"].concat(
          " ",
          
          doc.data()["description"],
          doc.data()["expirationDocument"]
        ),
    })) as unknown as DocumentsInfoDTO[];
    return items;
  }
  
  export const getDocumentById = async (id: string): Promise<DocumentsDTO | null> => {
    const documentsInfoDTO = await getDocument(id);
    const observations = await getDocumentationObservation(id);
  
    return {
      id,
      documentsInfoDTO,
      observations,
    };
  };
  
  export async function getDocumentsByGeneratorAndDealer(
    Id: string,
  ): Promise<DocumentsInfoDTO[]> {
    const documentsCollectionRef = collection(firestore, COLLECTIONS.DOCUMENTS);
  
    const q = query(
      documentsCollectionRef,
      where("originId", "==", Id),
    );
  
    const querySnapshot = await getDocs(q);
  
    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as DocumentsInfoDTO[];
  
    return items;
  }
  
  export async function getDocument(id: string): Promise<DocumentsInfoDTO> {
    const docRef = doc(firestore, COLLECTIONS.DOCUMENTS, id);
    const docSnap = await getDoc(docRef);
  
    return docSnap.data() as DocumentsInfoDTO;
  }
  
  export async function getDocumentationObservation(
    id: string
  ): Promise<ObservationsDTO> {
    const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
    const docSnap = await getDoc(docRef);
  
    return docSnap.data() as ObservationsDTO;
  }
  