import { collection, getDocs, query, where } from "firebase/firestore";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import {
  QueryOptions,
  removeItemFromArrayField,
  saveFiles,
  saveNewAsync,
  update,
} from "../../services/persistence";

import { firestore } from "../../utils/firebase-config";
import { CADRItemDTO } from "./models/cadri-item-DTO";
import { FOLDERS } from "../../assets/enums/storage-folders";

export async function addNewCadri(
  data: CADRItemDTO,
  NFFiles: File[],
  removedFiles: string[]
  
) {

  if (data.id === undefined) {
    const newDocRef = await saveNewAsync(COLLECTIONS.CADRI, {...data, tenantId: data.generatorId});
    saveFilesCadri(NFFiles, newDocRef.id,removedFiles);
  } else {
    await update(COLLECTIONS.CADRI, data, data.id);
    saveFilesCadri(NFFiles,  data.id, removedFiles);
  }
}

function saveFilesCadri(NFFiles: File[], id: string, removedFiles:string[]) {
  if (NFFiles != null && NFFiles.length > 0) {
    saveFiles(
      COLLECTIONS.CADRI,
      id,
      FOLDERS.CADRI,
      NFFiles,
      "attachmentCadri"
    );
  }
  if (removedFiles != null && removedFiles.length > 0) {
          removedFiles.forEach((fileUrl) => {
            removeItemFromArrayField(
              COLLECTIONS.CADRI,
              id,
              "attachmentCadri",
              fileUrl
            );
          });
}
}

export async function getAllCadris(
  options?: QueryOptions
): Promise<CADRItemDTO[]> {
  const cadriCollection = collection(firestore, COLLECTIONS.CADRI);

  let cadriQuery;
  if (options) {
    const { fieldName, operator, value } = options;
    cadriQuery = query(
      cadriCollection,
      where(fieldName, operator, value)
    );
  } else {
    cadriQuery = cadriCollection;
  }

  const cadriSnapshot = await getDocs(cadriQuery);
  const cadriList = cadriSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
      .data()
      ["scrapMaterialName"].concat(
        " ",
        doc.data()["scrapGeneratorName"],
        " ",
        doc.data()["scrapDealerName"]
      ),
  })) as unknown as CADRItemDTO[];

  return cadriList;
}

