import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  addNewScrapGenerator,
  getScrapGeneratorById,
} from "../../scrap-generator-service";
import { ScrapGeneratorDTO } from "../../scrap-generators-DTO";
import { maxLengthForNumbers } from "../../../../utils/formatting";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../../contexts/alert-context";
import { accountTypesENUM } from "../../../../assets/enums/account-type-enum";
import { useState, useRef, useEffect } from "react";
import InputMask from "react-input-mask";

import { HeadquarterDTO } from "../../../../components/HeadquarterInputAutocomplete/models/headquarter-DTO";
import CountryInputAutocomplete from "../../../../components/CountryInputAutocomplete/CountryInputAutocomplete";
import HeadquarterInputAutocomplete from "../../../../components/HeadquarterInputAutocomplete/HeadquarterInputAutocomplete";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { useAuth } from "../../../../contexts/auth-context";
import BankAutocomplete from "../../../../components/BankScrapAutoComplete/BankScrapAutoComplete";
import { BankDTO } from "../../../../components/BankScrapAutoComplete/bankDTO";
import {
  getAdrressByEntityId,
  getCountryConfigById,
} from "../../../../services/address-service";
import { CountryConfigDTO } from "../../../settings/components/Country-config/country-config-DTO";

const NewScrapGeneratorForm: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const [selectedbanks, setSelectedBanks] = useState<BankDTO>();
  const [countryConfigEntity, setCountryConfigEntity] = useState<CountryConfigDTO>();
  const [isDisabled, setIsDisabled] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const { headquarterParamId } = useParams<{ headquarterParamId: string }>();
  const { setAlert } = useAlert();
  const [scrapGenerator, setScrapGenerator] =
    useState<ScrapGeneratorDTO | null>(null);
  const [countryError, setCountryError] = useState("");
  const [headquarterError, setHeadquarterError] = useState("");
  const [selectedCountryDetails, setSelectedCountryDetails] =
    useState<CountryConfigDTO | null>(null);
  const [nationalRegisterLabel, setNationalRegisterLabel] = useState<string>(
    "label-national-register"
  );
  const [nationalRegisterFormat, setNationalRegisterFormat] =
    useState<string>("99.999.999/9999-99");
  const [postalCodeLabel, setPostalCodeLabel] =
    useState<string>("label-postal-code");
  const [postalCodeFormat, setPostalCodeFormat] =
    useState<string>("99.999-999");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ScrapGeneratorDTO>();

  const nationalRegisterRef = useRef(null);
  const postalCodeRef = useRef(null);
  const phoneRef = useRef(null);
  const [isHeadquarter, setIsHeadquarter] = useState(
    scrapGenerator?.scrapGeneratorInfo?.isHeadquarter ?? true
  );

  const fetchEntityCountry = async () => {
    if (id) {
      const data = await getAdrressByEntityId(id);
      const countryConfig = await getCountryConfigById(data.country);
      setCountryConfigEntity(countryConfig);
    }
  };

  useEffect(() => {
    fetchEntityCountry();
  }, []);

  useEffect(() => {
    if (headquarterParamId) {
      getScrapGeneratorById(headquarterParamId).then((data) => {
        if (data) {
          setScrapGenerator(data);
          setIsHeadquarter(false);
          setValue("scrapGeneratorInfo.isHeadquarter", false, {
            shouldValidate: true,
          });
          setValue("scrapGeneratorInfo.headquarterId", headquarterParamId, {
            shouldValidate: true,
          });
          setValue(
            "scrapGeneratorInfo.headquarterName",
            data.scrapGeneratorInfo?.tradingName,
            {
              shouldValidate: true,
            }
          );
          setValue("bankAccountInfo.bank", data.bankAccountInfo?.bank);
        }
      });
    } else if (id) {
      getScrapGeneratorById(id).then((data) => {
        if (data) {
          setScrapGenerator(data);
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof ScrapGeneratorDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof ScrapGeneratorDTO, value as any);
            }
          });
          const isHeadquarterValue = data.scrapGeneratorInfo?.isHeadquarter;
          setIsHeadquarter(isHeadquarterValue);
          setValue("scrapGeneratorInfo.isHeadquarter", isHeadquarterValue, {
            shouldValidate: true,
          });
        }
      });
    }
  }, [id, setValue]);

  const handleCountryChange = (country: CountryConfigDTO) => {
    setCountryConfigEntity(country);
    setSelectedCountryDetails(country);
    setNationalRegisterLabel(
      country.nationalRegister || "label-national-register"
    );
    setNationalRegisterFormat(
      country.nationalRegisterFormatting || "99.999.999/9999-99"
    );
    setPostalCodeLabel(country.postalCode || "label-postal-code");
    setPostalCodeFormat(country.postalCodeFormatting || "99.999-999");
    setValue("addressInfo.country", country.id, { shouldValidate: true });
    setValue("addressInfo.countryName", country.country[0], {
      shouldValidate: true,
    });
    setValue("addressInfo.postalCodeLabel", country.postalCode, {
      shouldValidate: true,
    });
    setValue(
      "scrapGeneratorInfo.nationalRegisterLabel",
      country.nationalRegister,
      { shouldValidate: true }
    );
  };

  const handleHeadquarterChange = (headquarter: HeadquarterDTO) => {
    handleRadioChange(false);
    setValue("scrapGeneratorInfo.headquarterId", headquarter.id, {
      shouldValidate: true,
    });
    setValue("scrapGeneratorInfo.headquarterName", headquarter.tradingName, {
      shouldValidate: true,
    });
  };

  const onSubmit = async (scrapGenerator: ScrapGeneratorDTO) => {
    setIsDisabled(true);
    if (!selectedCountryDetails) {
      setCountryError("true");
    } else {
      if (
        !isHeadquarter &&
        !scrapGenerator.scrapGeneratorInfo?.headquarterName
      ) {
        setHeadquarterError("true");
      } else {
        setCountryError("");
        setHeadquarterError("");
        setValue("scrapGeneratorInfo.isHeadquarter", isHeadquarter, {
          shouldValidate: true,
        });

        scrapGenerator.scrapGeneratorInfo.isHeadquarter =
          isHeadquarter === true;
        try {
          await addNewScrapGenerator(scrapGenerator);
          setAlert({
            text: t("alert-successfully-registered"),
            type: "success",
          });
          navigate("/scrap-generators");
        } catch (e) {
          console.error("Erro ao cadastrar: ", e);
          setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
        }
      }
    }
    setIsDisabled(false);
  };

  const handleSelect = (value: boolean) => {
    setIsHeadquarter(value);
  };

  const handleRadioChange = (targetValue: boolean) => {
    setIsHeadquarter(targetValue);
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page-centered">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-fields flex-column">
            <h2>{t("label-business-information")}</h2>

            <div className="flex-column">
              <CountryInputAutocomplete
                label={`${t("label-country")}*`}
                placeholder={t("placeholder.country")}
                register={register}
                errors={errors}
                onChange={handleCountryChange}
                initialValue={scrapGenerator?.addressInfo?.countryName}
                isDisabled={!!scrapGenerator && !headquarterParamId}
              />
              {!selectedCountryDetails && countryError && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label>{t("label-type")}</label>
              <div className="flex-row radio-types">
                <div className="flex-row">
                  <label htmlFor="isHeadquarter">
                    <input
                      type="radio"
                      id="isHeadquarter"
                      value="true"
                      onChange={(e) => handleRadioChange(true)}
                      checked={isHeadquarter === true}
                      defaultChecked={
                        scrapGenerator?.scrapGeneratorInfo?.isHeadquarter ===
                        true
                      }
                      disabled={!!scrapGenerator}
                    />
                    {t("label-headquarter")}
                  </label>
                </div>
                <div className="flex-row">
                  <label htmlFor="isBranch">
                    <input
                      type="radio"
                      id="isBranch"
                      value="false"
                      onChange={() => handleRadioChange(false)}
                      checked={isHeadquarter === false}
                      defaultChecked={
                        scrapGenerator?.scrapGeneratorInfo?.isHeadquarter ===
                        false
                      }
                      disabled={!!scrapGenerator}
                    />
                    {t("label-branch")}
                  </label>
                </div>
                {errors.scrapGeneratorInfo?.isHeadquarter === null &&
                  scrapGenerator?.scrapGeneratorInfo?.isHeadquarter && (
                    <span>{t("error-input-required")}</span>
                  )}
              </div>
            </div>

            {isHeadquarter === false && (
              <div className="flex-column">
                <HeadquarterInputAutocomplete
                  label={t("label-headquarter-name")}
                  placeholder={t("placeholder.legalName")}
                  register={register}
                  errors={errors}
                  onChange={handleHeadquarterChange}
                  initialValue={scrapGenerator?.scrapGeneratorInfo}
                  headQuarterCollection={COLLECTIONS.SCRAP_GENERATORS}
                  isDisabled={!!scrapGenerator}
                />
                {!isHeadquarter && headquarterError && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>
            )}

            <div className="flex-column">
              <label htmlFor="legalName">{`${t("label-legal-name")}*`}</label>
              <input
                placeholder={t("placeholder.legalName")}
                type="text"
                id="legalName"
                {...register("scrapGeneratorInfo.legalName", {
                  required: true,
                })}
              />
              {errors.scrapGeneratorInfo?.legalName && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="tradingName">{`${t(
                "label-trading-name"
              )}*`}</label>
              <input
                placeholder={t("placeholder.tradingName")}
                type="text"
                id="tradingName"
                {...register("scrapGeneratorInfo.tradingName", {
                  required: true,
                })}
              />
              {errors.scrapGeneratorInfo?.tradingName && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="nationalRegister">
                {t(`${nationalRegisterLabel}`)}*
              </label>
              <InputMask
                mask={nationalRegisterFormat}
                placeholder={nationalRegisterFormat}
                type="text"
                id="nationalRegister"
                {...register("scrapGeneratorInfo.nationalRegister", {
                  required: true,
                })}
                inputRef={nationalRegisterRef}
              />
              {errors.scrapGeneratorInfo?.nationalRegister && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            {(scrapGenerator?.addressInfo?.countryName === "Brasil" ||
              selectedCountryDetails?.country[0] === "Brasil") && (
              <div className="flex-column">
                <label htmlFor="mtrCode">{`${t(
                  "label-business-mtr-code"
                )}*`}</label>
                <input
                  placeholder={t("placeholder.mtrCode")}
                  type="text"
                  id="mtrCode"
                  {...register("scrapGeneratorInfo.mtrCode", {
                    required: true,
                  })}
                />
                {errors.scrapGeneratorInfo?.mtrCode && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>
            )}
          </div>
          <br />
          <hr />
          <br />
          <div className="form-fields flex-column">
            <h2>{t("label-location")}</h2>
            <div className="flex-column">
              <label htmlFor="postalCode">{t(`${postalCodeLabel}`)}*</label>
              <InputMask
                mask={postalCodeFormat}
                placeholder={postalCodeFormat}
                type="text"
                id="postalCode"
                {...register("addressInfo.postalCode", { required: true })}
                inputRef={postalCodeRef}
              />
              {errors.addressInfo?.postalCode && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="address">{`${t("label-address")}*`}</label>
              <input
                placeholder={t("placeholder.address")}
                type="text"
                id="address"
                {...register("addressInfo.address", { required: true })}
              />
              {errors.addressInfo?.address && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="double-fields">
              <div className="flex-column">
                <label htmlFor="city">{`${t("label-city")}*`}</label>
                <input
                  placeholder={t("placeholder.city")}
                  type="text"
                  id="city"
                  {...register("addressInfo.city", { required: true })}
                />
                {errors.addressInfo?.city && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="state">{`${t("label-state")}*`}</label>
                <input
                  placeholder={t("placeholder.state")}
                  type="text"
                  id="state"
                  {...register("addressInfo.state", { required: true })}
                />
                {errors.addressInfo?.state && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div className="form-fields flex-column">
            <h2>{t("label-contact")}</h2>
            <div className="flex-column">
              <label htmlFor="contactName">{`${t(
                "label-contact-name"
              )}*`}</label>
              <input
                placeholder={t("placeholder.contactName")}
                type="text"
                id="contactName"
                {...register("contactInfo.name", { required: true })}
              />
              {errors.contactInfo?.name && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="double-fields">
              <div className="flex-column">
                <label htmlFor="email">{`${t("label-email")}*`}</label>
                <input
                  placeholder={t("placeholder.email")}
                  type="text"
                  id="email"
                  {...register("contactInfo.email", { required: true })}
                />
                {errors.contactInfo?.email && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="phone">{`${t("label-phone")}*`}</label>
                <InputMask
                  mask="(99) 99999-9999"
                  placeholder={t("placeholder.phone")}
                  type="text"
                  id="phone"
                  {...register("contactInfo.phone", { required: true })}
                  inputRef={phoneRef}
                />
                {errors.contactInfo?.phone && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="function">{`${t("label-function")}*`}</label>
                <input
                  placeholder={t("placeholder.generalEmpty")}
                  type="text"
                  id="function"
                  {...register("contactInfo.function", { required: true })}
                />
                {errors.contactInfo?.function && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div className="form-fields flex-column">
            <h2>{t("label-bank-details")}</h2>
            <div className="double-fields">
              {countryConfigEntity && (
                <div className="flex-column">
                  <BankAutocomplete
                    countryConfigEntity={countryConfigEntity}
                    label={`${t("label-bank")}*`}
                    onChange={(items) => {
                      setSelectedBanks(items);
                      setValue("bankAccountInfo.bank", items);
                    }}
                    placeholder={t("label-bank")}
                    initialValue={scrapGenerator?.bankAccountInfo?.bank || ""}
                  />
                  {errors.bankAccountInfo?.bank && (
                    <span>{t("error-input-required")}</span>
                  )}
                </div>
              )}
              <div className="flex-column">
                <label htmlFor="accountType">{`${t(
                  "label-account-type"
                )}*`}</label>
                <select
                  id="accountType"
                  {...register("bankAccountInfo.accountType", {
                    required: true,
                  })}
                >
                  <option value=""></option>
                  {Object.entries(accountTypesENUM).map(([key, value]) => (
                    <option key={key} value={key}>
                      {t(`accountTypesENUM.${value}`)}
                    </option>
                  ))}
                </select>
                {errors.bankAccountInfo?.accountType && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>
            </div>

            <div className="double-fields">
              <div className="flex-column">
                <label htmlFor="accountNumber">
                  {`${t("label-account-number")}*`}
                </label>
                <input
                  placeholder={t("placeholder.accountNumber")}
                  type="number"
                  onInput={(e) => maxLengthForNumbers(e, 20)}
                  id="accountNumber"
                  {...register("bankAccountInfo.accountNumber", {
                    required: true,
                  })}
                />
                {errors.bankAccountInfo?.accountNumber && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="digit">{`${t("label-digit")}*`}</label>
                <input
                  placeholder={t("placeholder.digit")}
                  type="number"
                  onInput={(e) => maxLengthForNumbers(e, 20)}
                  id="digit"
                  {...register("bankAccountInfo.digit", { required: true })}
                />
                {errors.bankAccountInfo?.digit && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="agency">{`${t("label-agency")}*`}</label>
                <input
                  placeholder={t("placeholder.agency")}
                  type="number"
                  onInput={(e) => maxLengthForNumbers(e, 20)}
                  id="agency"
                  {...register("bankAccountInfo.agency", { required: true })}
                />
                {errors.bankAccountInfo?.agency && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div className="form-fields flex-column">
            <div className="flex-column">
              <label htmlFor="observations">
                {t("label-general-observations")}
              </label>
              <textarea
                placeholder={t("placeholder.observations")}
                id="observations"
                {...register("observations.observations")}
              />
            </div>
          </div>

          <div className="flex-row buttons-section">
            <button
              className="neutral-white"
              onClick={() => navigate("/scrap-generators")}
              type="button"
            >
              {t("label-back")}
            </button>
            <button className="blue" type="submit" disabled={isDisabled}>
              {t("label-save")}
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default NewScrapGeneratorForm;
