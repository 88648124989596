export const FOLDERS = {
    ACCEPTANCE_TERMS: "AcceptanceTerms",
    APPROVE_ROLL: "ApproveRoll",
    CADRI: "Cadri",
    CDF: "CollectsCDF",
    COLLECTS_INVOICE:"CollectsInvoice",
    COLLECTS_MTR:"CollectsMTR",
    COLLECTS_TICKETS:"CollectsTickets",
    DEALERS_INVITED_BID_FILES: "DealersInvitedBidFiles",
    DOCUMENTS: "Documents",
    FSDR:"Fsdr",
    PARTNER_REQUIREMENTS_DEALER: "PartnerRequirementsDealer",
    SCRAP:"ScrapByGenerator",    
    SCRAP_CATEGORY: "ScrapCategory",   
    SCRAP_GROUPS: "ScrapGroups",
    SOCIAL_ACTIVITY: "SocialActivity",
    SPECIAL_TECHNOLOGY: "SpecialTechnology",
    VEHICLES: "Vehicles",
}