import React from "react";
import NoData from "../../../../components/NoData/NoData";
import { ScrapProspectingGeneratorsDTO } from "../../scrap-generator-prospecting-DTO";
import CardScrapGeneratorProspecting from "./card-prospecting/card-prospecting";

interface CardListProps {
  scrapProspectingGenerators: ScrapProspectingGeneratorsDTO[];
}

const CardListScrapProspectingGenerator: React.FC<CardListProps> = ({
  scrapProspectingGenerators,
}) => {
  return (
    <div className="card-list">
      {scrapProspectingGenerators.length === 0 ? (
        <NoData />
      ) : (
        scrapProspectingGenerators.map((item, index) => (
          <CardScrapGeneratorProspecting key={index} item={item} />
        ))
      )}
    </div>
  );
};

export default CardListScrapProspectingGenerator;
