import React from "react";

interface CTAInfoComponentProps {
  children: React.ReactNode;
  color?: string;
}

function CTAInfoComponent({ children, color }: CTAInfoComponentProps) {
  const style = {
    backgroundColor: color || "defaultColor",
  };

  return (
    <div className="cta-info-component" style={style}>
      <div>{children}</div>
    </div>
  );
}

export default CTAInfoComponent;
