import React, { useEffect, useState } from "react";
import "./modal-cookies.css";
import { useTranslation } from "react-i18next";
import { getFromLocalStorage, saveToLocalStorage } from "../../services/persistence";

interface Props {
  description: string;
  textLink: string;
  accept: string;
  reject: string;
  accepted: () => void;
}

const ModalCookies: React.FC<Props> = ({
  description,
  textLink,
  accept,
  reject,
  accepted,
}) => {
  const { t } = useTranslation();
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const acceptCookies = () => {
    document.cookie = "cookieBy=cookie; max-age=" + 60 * 60 * 24 * 30;
    saveToLocalStorage("cookiesAccepted", true);
    accepted();
    setShowCookieBanner(false);
  };

  useEffect(() => {
    const cookiesAccepted = getFromLocalStorage("cookiesAccepted");
    if (!cookiesAccepted) {
      setShowCookieBanner(true);
    }
  }, []);

  if (!showCookieBanner) {
    return null;
  }

  return (
    <div className="wrapper">
      <div className="text-cookies">
        <p>
          {description}
          <a href="https://firebasestorage.googleapis.com/v0/b/rbprj-100132.appspot.com/o/public%2fTermos%20de%20uso%20S4B.pdf?alt=media&token=6d888fd5-31bf-457c-8ef5-01f5dda41d5f" target="_blank">
            {textLink}
          </a>
        </p>
      </div>
      <div className="buttons">
        <button className="button-yes" onClick={acceptCookies}>
          {accept}
        </button>
        <button
          className="button-no"
          onClick={() => setShowCookieBanner(false)}
        >
          {reject}
        </button>
      </div>
    </div>
  );
};

export default ModalCookies;
