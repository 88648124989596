import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { getUserById } from "../users-service";
import { UserDTO } from "../models/users-DTO";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useAlert } from "../../../../../contexts/alert-context";
import { firestore } from "../../../../../utils/firebase-config";
import { COLLECTIONS } from "../../../../../assets/enums/firebase-colections";
import { callFunction } from "../../../../../services/persistence";
import { profileENUM } from "../../../../../assets/enums/profiles-enum";

interface ModalProps {
  onClose: () => void;
  userId: string;
}

const UserForm: React.FC<ModalProps> = ({ onClose, userId }) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);

  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [user, setUser] = useState<UserDTO | null>(null);
  const currentURL = window.location.href;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UserDTO>();

  const phoneRef = useRef(null);

  useEffect(() => {
    if (userId) {
      getUserById(userId).then((data) => {
        if (data) {
          setUser(data);
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof UserDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof UserDTO, value as any);
            }
          });
        }
      });
    }
  }, [userId, setValue]);

  const onSubmit = async (user: UserDTO) => {
    setIsDisabled(true);
    if (id) {
      const contactsCollectionRef = collection(firestore, COLLECTIONS.USERS);

      const q = query(
        contactsCollectionRef,
        where("email", "==", user.userInfo.email)
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length > 0) {
        setAlert({
          text: `${t("alert-error-registered-user")}:`,
          type: "error",
        });
      } else {
        try {
          const profile = currentURL.includes("scrap-dealer")
            ? "scrap-dealer"
            : currentURL.includes("scrap-generator")
              ? profileENUM.GENERATOR
              : profileENUM.ADMIN_BOSCH;
          user.userInfo.profile = profile;

          callFunction("createnewuser", {
            email: user.userInfo.email,
            name: user.userInfo.name,
            profile: user.userInfo.profile,
            observations: user.observations.observations,
            originId: id
          })
            .then((result) => {
              setAlert({
                text: t("alert-successfully-registered"),
                type: "success",
              });
              onClose();
            })
            .catch((error) => {
              // Getting the Error details.

              const message = error.message;
              setAlert({
                text: `${t("alert-error-registered")}:`,
                type: "error",
              });
              console.error("error", message);
              // ...
            });
        } catch (e) {
          console.error("Erro ao cadastrar: ", e);
          setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
        }
      }
    }
    setIsDisabled(false);
  };

  return (
    <div className="modal-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields flex-column">
          <h2>{t("label-user")}</h2>

          <div className="flex-column">
            <label htmlFor="legalName">{`${t("label-name")}*`}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="legalName"
              {...register("userInfo.name", { required: true })}
            />
            {errors.userInfo?.name && <span>{t("error-input-required")}</span>}
          </div>

          <div className="flex-column">
            <label htmlFor="tradingName">{`${t("label-email")}*`}</label>
            <input
              placeholder={t("placeholder.email")}
              type="text"
              id="tradingName"
              {...register("userInfo.email", { required: true })}
            />
            {errors.userInfo?.email && <span>{t("error-input-required")}</span>}
          </div>
        </div>
        <br />
        <hr />
        <br />

        <div className="form-fields flex-column">
          <div className="flex-column">
            <label htmlFor="observations">
              {t("label-general-observations")}
            </label>
            <textarea
              placeholder={t("placeholder.observations")}
              id="observations"
              {...register("observations.observations")}
            />
          </div>
        </div>

        <div className="flex-row buttons-section">
          <button className="neutral-white" onClick={onClose} type="button">
            {t("label-back")}
          </button>
          <button className="blue" type="submit" disabled={isDisabled}>
            {t("label-save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
