import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { CRMFunnelDTO } from "../crm-dto";
import { useAlert } from "../../../../../contexts/alert-context";
import { addNewCRMStep, getCRMStepById, getCRMSteps } from "../crm-service";

interface ModalProps {
  onClose: (newFunnel?: CRMFunnelDTO) => void;
  funnelId: string;
}

const CRMFunnelForm: React.FC<ModalProps> = ({ onClose, funnelId }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [color, setColor] = useState<string>("#000000"); 

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<CRMFunnelDTO>();

  useEffect(() => {
    if (funnelId) {
      getCRMStepById(funnelId).then((data) => {
        if (data) {
          setValue("id", data.id);
          Object.entries(data.crmFunnelInfoDTO).forEach(([key, value]) => {
            setValue(`crmFunnelInfoDTO.${key}` as keyof CRMFunnelDTO, value as any);
            if (key === "color" && typeof value === "string") {
              setColor(value); 
            }
          });
        }
      });
    } else {
      reset();
    }
  }, [funnelId, setValue, reset]);

  const onSubmit = async (document: CRMFunnelDTO) => {
    try {
      if (document.id) {
       
        const updatedFunnel = await addNewCRMStep(document);
        onClose(updatedFunnel);
      } else {
      
        const allFunnels = await getCRMSteps();
        const nextOrder = allFunnels.length + 1;
  
       
        document.crmFunnelInfoDTO.order = nextOrder;
  
      
        const newFunnel = await addNewCRMStep(document);
        onClose(newFunnel);
      }
  
      setAlert({ text: t("alert-successfully-registered"), type: "success" });
    } catch (e) {
      console.error("Erro ao salvar o funil:", e);
      setAlert({ text: t("alert-error-registered"), type: "error" });
    }
  };
  

  return (
    <div className="modal-content">
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-fields flex-column">
        <h2>{t("Label-crm-funnel")}</h2>
        <div className="flex-column">
          <label htmlFor="crm-stage">{`${t("Label-crm-stage")}`}*</label>
          <input
            placeholder={t("placeholder.generalEmpty")}
            type="text"
            id="crm-stage"
            {...register("crmFunnelInfoDTO.stageName", { required: true })}
          />
          {errors.crmFunnelInfoDTO?.stageName && (
            <span>{t("error-input-required")}</span>
          )}
        </div>
  
        
        <div className="flex-column">
          <label htmlFor="crm-color">{t("Label-crm-color")}</label>
          <input
            type="color"
            id="crm-color"
            style={{ width: "490px", height: "40px" }}
            {...register("crmFunnelInfoDTO.color")}
            onChange={(e) => {
              setColor(e.target.value);
            }}
          />
        </div>
  
      
        <div className="flex-column">
          <label htmlFor="crm-visibility" style={{ fontSize: "22px" }}>
            <input
              type="checkbox"
              id="crm-visibility"
              style={{ width: "25px", height: "30px", marginRight: "8px" }}
              {...register("crmFunnelInfoDTO.isVisible")}
            />
            {t("Label-crm-visibility")}
          </label>
        </div>
      </div>
  
      <div className="flex-row buttons-section">
        <button
          className="neutral-white"
          onClick={() => onClose()}
          type="button"
        >
          {t("label-back")}
        </button>
        <button className="blue" type="submit">
          {t("label-save")}
        </button>
      </div>
    </form>
  </div>
  
  );
};

export default CRMFunnelForm;
