import React, { useEffect, useState } from "react";
import "../../../scrap.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import genericImage from "../../../../../assets/svgs/material-list.svg";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import ChartMinimalCardView from "../../../../../components/ChartMinimalCardView/ChartMinimalCardView";
import {
  getScrapByGenerator,
  getScrapCategoryTotalbyId,
} from "../../../scraps-service";

import MultiCountryAverageChart from "../../../../../components/ChartLineCountryByYear/ChartLineCountryByYear";

import { receiveNumberFormatWithMilhar } from "../../../../../services/number-format";
import { getResizedImage } from "../../../../../services/image-treatment";
import { CategoryTotalDTO } from "../../../scrap-category/models/scrap-category-total-dto";
import { ScrapsCategoryGroupDTO } from "../../../scrap-category/models/scrap-category-DTO";

interface Props {
  item: ScrapsCategoryGroupDTO;
}

const CardScrapGroup: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [totalDetails, setTotalDetails] = useState<CategoryTotalDTO | null>(
    null
  );
  const [scrapByGeneratorDetails, setScrapByGeneratorDetails] = useState<
    any[] | null
  >(null);
  const [generatorQuantity, setGeneratorQuantity] = useState("0");
  const [dealerQuantity, setDealerQuantity] = useState("0");
  const [showLegend, setShowLegend] = useState(false);

  useEffect(() => {
    const fetchTotalDetails = async () => {
      try {
        const result = await getScrapCategoryTotalbyId(item.id);
        setTotalDetails(result);
      } catch (err) {
        console.error("Failed to fetch data");
      }
    };

    const fetchScrapByGenerator = async () => {
      try {
        const result = await getScrapByGenerator(item.id);
        setScrapByGeneratorDetails(result);
      } catch (err) {
        console.error("Failed to fetch data");
      }
    };

    fetchTotalDetails();
    fetchScrapByGenerator();
  }, [item.id]);

  useEffect(() => {
    if (scrapByGeneratorDetails && scrapByGeneratorDetails?.length > 0) {
      const uniqueScrapByGeneratorDetails = scrapByGeneratorDetails.filter(
        (detail, index, self) =>
          index === self.findIndex((t) => t.originId === detail.originId)
      );

      const uniqueScrapDealer = scrapByGeneratorDetails
        .filter((detail) => detail.contractDealer)
        .filter(
          (detail, index, self) =>
            index ===
            self.findIndex((t) => t.contractDealer === detail.contractDealer)
        );

      setGeneratorQuantity(uniqueScrapByGeneratorDetails.length.toString());
      setDealerQuantity(uniqueScrapDealer.length.toString());
    }
  }, [scrapByGeneratorDetails]);

  const [showMoreCardDetails, setShowMoreCardDetails] =
    useState<boolean>(false);

  const handleShowMoreCardDetails = (event: any) => {
    event.stopPropagation();
    setShowMoreCardDetails(!showMoreCardDetails);
  };

  return (
    <div className="flex-column" style={{ marginBottom: "1rem" }}>
      <div
        className="flex-column card-scrap"
        onClick={() => {
          navigate(`/scrap-groups-details/${item.id}/pannel`);
        }}
      >
        <div className="flex-column">
          <h3 style={{ margin: 0 }}>{item.scrapGroup || "-"}</h3>
          <div style={{ margin: "5px 0px" }} className="custom-line-hr" />
          <div className="flex-row">
            <div
              className="card-scrap-img"
              style={{
                height: "110px",
                width: "130px",
                overflow: "hidden",
                borderRadius: "5px",
              }}
            >
              <img
                src={
                  item.images ? getResizedImage(item.images[item.images.length-1]) : genericImage
                }
                alt="material image"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default CardScrapGroup;
