import FinancialDashboardGraphic from "../../components/financialDashboard/componentes/FinancialDashboardGraphic/FinancialDashboardGraphic";
import { CardDetails } from "../../components/financialDashboard/componentes/Card-details/Card-details";
import { FilterGraphic } from "../../components/financialDashboard/componentes/FilterGraphic/FilterGraphic";
import "./FinancialDashboard.css";
import { useTranslation, Trans } from "react-i18next";
import { useEffect, useState } from "react";
import { FinancialDTO } from "./financial-DTO";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import { getFinancial } from "./financial-service";
import TableDetails from "../../components/financialDashboard/componentes/table-details/table-details";
import { useAuth } from "../../contexts/auth-context";
import stonks from "../../assets/img/stonks.svg";

const FinancialDashboard: React.FC<{ enabledUsers: string[] }> = ({}) => {
  const { t } = useTranslation();
  const { userDoc, currentUser, loading } = useAuth();

  const profile = userDoc?.profile;
  const originId = userDoc?.originId;
  const [showChart, setShowChart] = useState<Boolean>(true);
  const [idTofilter, setIdTofilter] = useState<string>();
  const [totalDetails, setTotalDetails] = useState<FinancialDTO[] | null>(null);

  useEffect(() => {
    if (!loading && userDoc?.originId && userDoc?.profile) {
      const fetchTotalDetails = async () => {
        try {
          const result = await getFinancial(
            userDoc?.profile,
            userDoc?.originId
          );
          const data = result.map((obj: any) =>
            Number.isNaN(Number(obj.totalValue))
              ? { ...obj, totalValue: 0 }
              : obj
          );
          if (data.filter((obj: any) => obj.totalValue > 0).length > 0) {
            setTotalDetails(data);
          } else {
            setTotalDetails([]);
          }
        } catch (err) {}
      };

      fetchTotalDetails();
    }
  }, [userDoc]);

  return (
    <div>
      {showChart == true ? (
        <div className="flex-column">
          <div className="flex-row justify-between align-center">
            <div className="flex-row align-center">
              <SparkIcon icName="chart-bar" />
              <h3 style={{ margin: "0" }}>{t("financial-dashboard.titles")}</h3>
            </div>

            <div className="filter">
              <FilterGraphic
                data={totalDetails}
                setIdTofilter={setIdTofilter}
              />
              <div>
                <SparkIcon
                  onClick={() => setShowChart(false)}
                  icName="list-view"
                  noPadding
                />
              </div>
            </div>
          </div>

          {totalDetails && totalDetails?.length > 0 ? (
            <div className="flex-row">
              <>
                <div className="card">
                  <CardDetails
                    data={totalDetails}
                    idTofilter={idTofilter || ""}
                  />
                </div>
                <div className="chart">
                  <FinancialDashboardGraphic
                    data={totalDetails}
                    idTofilter={idTofilter || ""}
                  />
                </div>
              </>
            </div>
          ) : (
            <div className="empty-slot">
              <img
                style={{ marginBottom: 10, maxHeight: "8rem", fill: "#5a5a5a" }}
                src={stonks}
                alt="no data ilustration"
              />
              <h4>{t("financial-dashboard.no-data")}</h4>
            </div>
          )}
        </div>
      ) : (
        <div className="first-financial">
          <div
            className="flex-row justify-between align-center"
            style={{ padding: "10px" }}
          >
            <h3>{t("financial-dashboard.titles")}</h3>
            <div className="filter">
              <FilterGraphic
                data={totalDetails}
                setIdTofilter={setIdTofilter}
              />
              <div>
                <SparkIcon
                  onClick={() => setShowChart(true)}
                  icName="chart-bar"
                  noPadding
                />
              </div>
            </div>
          </div>
          {totalDetails && (
            <TableDetails data={totalDetails} idTofilter={idTofilter || ""} />
          )}
        </div>
      )}
    </div>
  );
};

export default FinancialDashboard;
